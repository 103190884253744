import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { H2, H4, P } from "../../atoms/Texts";
import { authContext } from "../../../shared/store/contexts";
import { Layout } from "../../organisms/Layouts";

import emergencyIcon from "../../../assets/images/telecommunication.svg";
import foodIcon from "../../../assets/images/food.svg";
import housingIcon from "../../../assets/images/housing.svg";
import goodsIcon from "../../../assets/images/goods-services.svg";
import transitIcon from "../../../assets/images/transportation.svg";
import healthIcon from "../../../assets/images/health.svg";
import moneyIcon from "../../../assets/images/commodities.svg";
import careIcon from "../../../assets/images/institutions.svg";
import educationIcon from "../../../assets/images/education.svg";
import workIcon from "../../../assets/images/poverty-equity.svg";
import legalIcon from "../../../assets/images/crime.svg";

const OrganizationCategoriesPage = () => {
  const { auth } = useContext(authContext);

  useEffect(() => {
    if (auth && auth.token) {
      // setIsValid(decodeToken(auth.token));
    }
  }, [auth]);

  return (
    <Layout>
      <div className="container mt-10">
        <H2
          // color="#442ED0"
          className="mb-16"
          mediumSize="50px"
          smallSize="28px"
          data-testid="select category text"
        >
          Select a category
        </H2>

        <div className="container-flexible w-full mb-20 mt-10">
          <div className="md:px-0 grid md:grid-cols-3 lg:grid-cols-4 gap-6 lg:gap-10 grid-flow-row">
            <div>
              <Link to="/organization/category/Emergency">
                <img
                  src={emergencyIcon}
                  alt="Emergency icon"
                  className="mb-4 w-10 h-10"
                />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                  data-testid="emergency"
                >
                  Emergency
                </H4>
              </Link>
              <Link to="/organization/category/Emergency/Disaster Response">
                <P color="#575B68" className="mb-2">
                  Disaster Response
                </P>
              </Link>

              <Link to="/organization/category/Emergency/Emergency Payments">
                <P color="#575B68" className="mb-2">
                  Emergency Payments
                </P>
              </Link>
              <Link to="/organization/category/Emergency/Emergency Food">
                <P color="#575B68" className="mb-2">
                  Emergency Food
                </P>
              </Link>
              <Link to="/organization/category/Emergency/Emergency Shelter">
                <P color="#575B68" className="mb-2">
                  Emergency Shelter
                </P>
              </Link>
              <Link to="/organization/category/Emergency/Help Find Missing Persons">
                <P color="#575B68" className="mb-2">
                  Help Find Missing Persons
                </P>
              </Link>
              <Link to="/organization/category/Emergency/Immediate Safety">
                <P color="#575B68" className="mb-2">
                  Immediate Safety
                </P>
              </Link>
              <Link to="/organization/category/Emergency/Psychiatric Emergency Services">
                <P color="#575B68" className="mb-2">
                  Psychiatric Emergency Services
                </P>
              </Link>
            </div>
            <div>
              <Link to="/organization/category/Food">
                <img src={foodIcon} alt="Food icon" className="mb-4 w-10 h-10" />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Food
                </H4>
              </Link>
              <Link to="/organization/category/Community Gardens">
                <P color="#575B68" className="mb-2">
                  Community Gardens
                </P>
              </Link>
              <Link to="/organization/category/Food/Emergency Food">
                <P color="#575B68" className="mb-2">
                  Emergency Food
                </P>
              </Link>
              <Link to="/organization/category/Food/Food Delivery">
                <P color="#575B68" className="mb-2">
                  Food Delivery
                </P>
              </Link>
              <Link to="/organization/category/Food/Food Pantry">
                <P color="#575B68" className="mb-2">
                  Food Pantry
                </P>
              </Link>
              <Link to="/organization/category/Food/Free Meals">
                <P color="#575B68" className="mb-2">
                  Free Meals
                </P>
              </Link>
              <Link to="/organization/category/Food/Help Pay For Food">
                <P color="#575B68" className="mb-2">
                  Help Pay for Food
                </P>
              </Link>
              <Link to="/organization/category/Food/Nutrition">
                <P color="#575B68" className="mb-2">
                  Nutrition
                </P>
              </Link>
            </div>
            <div>
              <Link to="/organization/category/Housing">
                <img
                  src={housingIcon}
                  alt="Housing icon"
                  className="mb-4 w-10 h-10"
                />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Housing
                </H4>
              </Link>
              <Link to="/organization/category/Housing/Emergency Shelter">
                <P color="#575B68" className="mb-2">
                  Emergency Shelter
                </P>
              </Link>
              <Link to="/organization/category/Housing/Help Find Housing">
                <P color="#575B68" className="mb-2">
                  Help Find Housing
                </P>
              </Link>
              <Link to="/organization/category/Housing/Help Pay for Housing">
                <P color="#575B68" className="mb-2">
                  Help Pay for Housing
                </P>
              </Link>
              <Link to="/organization/category/Housing/Housing Advice">
                <P color="#575B68" className="mb-2">
                  Housing Advice
                </P>
              </Link>
              <Link to="/organization/category/Housing/Residential Housing">
                <P color="#575B68" className="mb-2">
                  Residential Housing
                </P>
              </Link>
            </div>
            <div>
              <Link to="/organization/category/Goods">
                <img src={goodsIcon} alt="Goods icon" className="mb-4 w-10 h-10" />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Goods
                </H4>
              </Link>
              <Link to="/organization/category/Goods/Baby Supplies">
                <P color="#575B68" className="mb-2">
                  Baby Supplies
                </P>
              </Link>
              <Link to="/organization/category/Goods/Clothing">
                <P color="#575B68" className="mb-2">
                  Clothing
                </P>
              </Link>
              <Link to="/organization/category/Goods/Home Goods">
                <P color="#575B68" className="mb-2">
                  Home Goods
                </P>
              </Link>
              <Link to="/organization/category/Goods/Medical Supplies">
                <P color="#575B68" className="mb-2">
                  Medical Supplies
                </P>
              </Link>
              <Link to="/organization/category/Goods/Toys and Gifts">
                <P color="#575B68" className="mb-2">
                  Toys and Gifts
                </P>
              </Link>
            </div>

            <div>
              <Link to="/organization/category/Transit">
                <img
                  src={transitIcon}
                  alt="Transportation icon"
                  className="mb-4 w-10 h-10"
                />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Transit
                </H4>
              </Link>
              <Link to="/organization/category/Transit/Help Pay for Transit">
                <P color="#575B68" className="mb-2">
                  Help Pay for Transit
                </P>
              </Link>
              <Link to="/organization/category/Transit/Transportation">
                <P color="#575B68" className="mb-2">
                  Transportation
                </P>
              </Link>
            </div>

            <div>
              <Link to="/organization/category/Health">
                <img src={healthIcon} alt="Health icon" className="mb-4 w-10 h-10" />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Health
                </H4>
              </Link>
              <Link to="/organization/category/Health/Addiction &amp; Recovery">
                <P color="#575B68" className="mb-2">
                  Addiction &amp; Recovery
                </P>
              </Link>
              <Link to="/organization/category/Health/Dental Care">
                <P color="#575B68" className="mb-2">
                  Dental Care
                </P>
              </Link>
              <Link to="/organization/category/Health/End of Life Care">
                <P color="#575B68" className="mb-2">
                  End-of-Life Care
                </P>
              </Link>
              <Link to="/organization/category/Health/Health Education">
                <P color="#575B68" className="mb-2">
                  Health Education
                </P>
              </Link>
              <Link to="/organization/category/Health/Help Pay for Healthcare">
                <P color="#575B68" className="mb-2">
                  Help Pay for Healthcare
                </P>
              </Link>
              <Link to="/organization/category/Health/Medical Care">
                <P color="#575B68" className="mb-2">
                  Medical Care
                </P>
              </Link>
            </div>

            <div>
              <Link to="/organization/category/Money">
                <img
                  src={moneyIcon}
                  alt="Commodities icon"
                  className="mb-4 w-10 h-10"
                />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Money
                </H4>
              </Link>
              <Link to="/organization/category/Money/Financial Assistance">
                <P color="#575B68" className="mb-2">
                  Financial Assistance
                </P>
              </Link>
              <Link to="/organization/category/Money/Government Benefits">
                <P color="#575B68" className="mb-2">
                  Government Benefits
                </P>
              </Link>
              <Link to="/organization/category/Money/Financial Education">
                <P color="#575B68" className="mb-2">
                  Financial Education
                </P>
              </Link>
              <Link to="/organization/category/Money/Insurance">
                <P color="#575B68" className="mb-2">
                  Insurance
                </P>
              </Link>
              <Link to="/organization/category/Money/Tax Preparation">
                <P color="#575B68" className="mb-2">
                  Tax Preparation
                </P>
              </Link>
            </div>

            <div>
              <Link to="/organization/category/Care">
                <img
                  src={careIcon}
                  alt="Institutions icon"
                  className="mb-4 w-10 h-10"
                />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Care
                </H4>
              </Link>
              <Link to="/organization/category/Care/Adoption &amp; Foster Care">
                <P color="#575B68" className="mb-2">
                  Adoption &amp; Foster Care
                </P>
              </Link>
              <Link to="/organization/category/Care/Animal Welfare">
                <P color="#575B68" className="mb-2">
                  Animal Welfare
                </P>
              </Link>
              <Link to="/organization/category/Care/Daytime Care">
                <P color="#575B68" className="mb-2">
                  Daytime Care
                </P>
              </Link>
              <Link to="/organization/category/Care/End of Life Care">
                <P color="#575B68" className="mb-2">
                  End-of-Life Care
                </P>
              </Link>
              <Link to="/organization/category/Care/Navigating the system">
                <P color="#575B68" className="mb-2">
                  Navigating the System
                </P>
              </Link>
              <Link to="/organization/category/Care/Residential Care">
                <P color="#575B68" className="mb-2">
                  Residential Care
                </P>
              </Link>
              <Link to="/organization/category/Care/Support Network">
                <P color="#575B68" className="mb-2">
                  Support Network
                </P>
              </Link>
            </div>

            <div>
              <Link to="/organization/category/Education">
                <img
                  src={educationIcon}
                  alt="Education icon"
                  className="mb-4 w-10 h-10"
                />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Education
                </H4>
              </Link>
              <Link to="/organization/category/Education/Help Find School">
                <P color="#575B68" className="mb-2">
                  Help Find School
                </P>
              </Link>
              <Link to="/organization/category/Education/Help Pay for School">
                <P color="#575B68" className="mb-2">
                  Help Pay for School
                </P>
              </Link>
              <Link to="/organization/category/Education/More Education">
                <P color="#575B68" className="mb-2">
                  More Education
                </P>
              </Link>
              <Link to="/organization/category/Education/Preschool">
                <P color="#575B68" className="mb-2">
                  Preschool
                </P>
              </Link>
              <Link to="/organization/category/Education/Screening &amp; Exams">
                <P color="#575B68" className="mb-2">
                  Screening &amp; Exams
                </P>
              </Link>
              <Link to="/organization/category/Education/Skills &amp; Training">
                <P color="#575B68" className="mb-2">
                  Skills &amp; Training
                </P>
              </Link>
            </div>

            <div>
              <Link to="/organization/category/Work">
                <img src={workIcon} alt="Work icon" className="mb-4 w-10 h-10" />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Work
                </H4>
              </Link>
              <Link to="/organization/category/Work/Help Find Work">
                <P color="#575B68" className="mb-2">
                  Help Find Work
                </P>
              </Link>
              <Link to="/organization/category/Work/Help Pay for Work Expenses">
                <P color="#575B68" className="mb-2">
                  Help Pay for Work Expenses
                </P>
              </Link>
              <Link to="/organization/category/Work/Skills &amp; Training">
                <P color="#575B68" className="mb-2">
                  Skills &amp; Training
                </P>
              </Link>
              <Link to="/organization/category/Work/Supported Employment">
                <P color="#575B68" className="mb-2">
                  Supported Employment
                </P>
              </Link>
              <Link to="/organization/category/Work/Workplace Rights">
                <P color="#575B68" className="mb-2">
                  Workplace Rights
                </P>
              </Link>
            </div>

            <div>
              <Link to="/organization/category/Legal">
                <img src={legalIcon} alt="Legal icon" className="mb-4 w-10 h-10" />
                <H4
                  // color="#442ED0"
                  className="mb-2 font-bold"
                  mediumSize="50px"
                  smallSize="20px"
                >
                  Legal
                </H4>
              </Link>
              <Link to="/organization/category/Legal/Advocacy &amp; Legal Aid">
                <P color="#575B68" className="mb-2">
                  Advocacy &amp; Legal Aid
                </P>
              </Link>
              <Link to="/organization/category/Legal/Mediation">
                <P color="#575B68" className="mb-2">
                  Mediation
                </P>
              </Link>
              <Link to="/organization/category/Legal/Notary">
                <P color="#575B68" className="mb-2">
                  Notary
                </P>
              </Link>
              <Link to="/organization/category/Legal/Representation">
                <P color="#575B68" className="mb-2">
                  Representation
                </P>
              </Link>
              <Link to="/organization/category/Legal/Translation &amp; Interpretation">
                <P color="#575B68" className="mb-2">
                  Translation &amp; Interpretation
                </P>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrganizationCategoriesPage;
