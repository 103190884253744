import { gql } from "@apollo/client";

export const ORGANIZATION_SUGGEST_EDIT_MUTATION = gql`
  mutation SuggestEdit($data: editData!) {
    suggestEdit(data: $data) {
      id
      edits {
        id
        name
        email
        OrgId
        message
        accepted
      }
    }
  }
`;
