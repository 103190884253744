import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import queryString from "query-string";

import { themeContext } from "../../../shared/store/contexts/context.theme";
import { P, H2, H3, TextLink } from "../../atoms/Texts";
import noResultImg from "../../../assets/images/no-result-img.svg";

import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_ORGANIZATIONS } from "../../../shared/queries";
import OrganizationResultCard from "../../organisms/OrganizationResultCard";
import { LinkButton } from "../../atoms/Buttons";
import { calculatePageButtons } from "../../../shared/helpers";
import { MapSection, ToggleHolder } from "../../pages/SearchResult/styles";
import { SelectDropDown } from "../../atoms/Inputs";
import Map from "../../organisms/Map/map";
import { locationContext } from "../../../shared/store/contexts/context.location";

const NearbyOrganizations = () => {
  let history = useHistory();
  const { location, isFetchingUserLocation } = useContext(locationContext);

  const query = queryString.parse(window.location.search ?? "");
  const pageQuery = query?.page ?? 0;

  const { need, zipCode } = useParams();
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(pageQuery ? pageQuery * 30 : 0);
  const [canLoadNextPage, setCanLoadNextPage] = useState(false);
  const [pageRange, setPageRange] = useState([]);
  const [view, setView] = useState("Text");
  const [selected] = useState("");

  const { theme, isDark } = useContext(themeContext);

  const changeView = (option) => setView(option);

  const { data, loading } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      text: need,
      zipCode,
      from: Number(page),
      size: 30,
      latitude: zipCode ? null : location?.latitude,
      longitude: zipCode ? null : location?.longitude,
    },
  });

  const [preLoadNextPage, { data: preloadedData }] = useLazyQuery(GET_ORGANIZATIONS);

  const [pageSearch, { data: newData }] = useLazyQuery(GET_ORGANIZATIONS);

  useEffect(() => {
    if (preloadedData?.getNearbyOrganizations?.length > 0) {
      setCanLoadNextPage(true);
      setPageRange(calculatePageButtons(page / 30, 2));
    }
    return () => {
      setCanLoadNextPage(false);
      setPageRange([]);
    };
  }, [page, preloadedData]);

  // useEffect(() => {
  //   console.warn(preloadedDataErrors);
  // }, [preloadedDataErrors]);

  const fetchNextPage = () => {
    const _page = page + 30;
    preLoadNextPage({
      variables: {
        text: need,
        zipCode,
        from: _page,
        size: 30,
        latitude: zipCode ? null : location?.latitude,
        longitude: zipCode ? null : location?.longitude,
      },
    });
  };

  useEffect(() => {
    if (newData?.getOrganizations?.length > 0) {
      setResults(newData?.getOrganizations);
    }
    window.scrollTo(0, 0);
    return () => setResults([]);
  }, [newData]);

  // useEffect(() => {
  //   console.warn(newDataErrors);
  // }, [newDataErrors]);

  useEffect(() => {
    fetchNextPage();
    pageSearch({
      variables: {
        text: need,
        zipCode,
        from: Number(page),
        size: 30,
        latitude: zipCode ? null : location?.latitude,
        longitude: zipCode ? null : location?.longitude,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (data?.getOrganizations?.length > 0) {
      setResults(data?.getOrganizations);
    }
    return () => setResults([]);
  }, [data]);

  // useEffect(() => {
  //   console.warn(errors);
  // }, [errors]);

  const changePage = (page) => {
    history.push(`${history.location.pathname}?page=${page}`);
  };

  useMemo(() => {
    setPage(pageQuery * 30);
  }, [pageQuery]);

  return (
    <>
      {loading || isFetchingUserLocation ? (
        <>
          <div className="mt-10 mb-8">
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
          </div>
          <div className="mb-8">
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
          </div>
        </>
      ) : (
        <>
          {results?.length < 1 ? (
            <div className=" pt-20 flex flex-col items-center">
              <img src={noResultImg} alt="" className="" />
              <H3 color={theme.purpleFont} className="mt-10">
                No results found
              </H3>
              <P
                fontSize="16px"
                color={theme.lightFont}
                className="mt-2 mb-0 text-center"
              >
                We cannot locate an entry for your search.
                <br />
                <Link to="/" className="ml-2 underline">
                  Please modify your search and try again
                </Link>
              </P>
            </div>
          ) : (
            <>
              <div className="flex flex-col items-center">
                {/* <img src={noResultImg} alt="" className="h-32" /> */}
                {/* <H3 color={theme.purpleFont} className="mt-10">
                  No results found
                </H3> */}
                {/* <P
                  fontSize="16px"
                  color={theme.lightFont}
                  className="mt-2 mb-0 text-center"
                >
                  <span className="md:mr-1 inline-block">
                    No organization providing
                  </span>
                  <span
                    className="md:mr-1 capitalize inline-block"
                    style={{
                      color: theme.purpleFont,
                      textTransform: "capitalize",
                    }}
                  >
                    {need}
                  </span>
                  <span className="mr-1">in</span>
                  <span
                    className="mr-1"
                    style={{
                      color: theme.purpleFont,
                    }}
                  >
                    {zipCode}
                  </span>
                </P> */}
              </div>
              <div className="mt-4 flex items-center justify-between flex-wrap mb-10">
                <H2 fontSize="32px" color={theme.primaryFont}>
                  <span
                    className="mr-3"
                    style={{
                      fontSize: "24px",
                    }}
                  >
                    Showing results for
                  </span>
                  <span
                    className="md:mr-3 capitalize inline-block"
                    style={{
                      fontSize: "32px",
                      color: theme.purpleFont,
                      borderBottom: "solid 3px #442ED0",
                      textTransform: "capitalize",
                    }}
                  >
                    {need}
                  </span>
                  {zipCode ? (
                    <>
                      <span
                        style={{
                          fontSize: "32px",
                        }}
                        className="mr-3"
                      >
                        around
                      </span>
                      <span
                        className="mr-3"
                        style={{
                          fontSize: "32px",
                          color: theme.purpleFont,
                          borderBottom: "solid 3px #442ED0",
                        }}
                      >
                        {zipCode}
                      </span>
                    </>
                  ) : null}
                </H2>
                {!selected && (
                  <ToggleHolder className="mb-12 md:mb-0">
                    <TextLink color={theme.lightPurple} className="mb-2">
                      Change View Mode
                    </TextLink>
                    <SelectDropDown
                      toggleColor={theme.lightPurple}
                      optionColor={theme.lightPurple}
                      className="cursor-pointer"
                      options={["Text", "Map"]}
                      changeView={(option) => changeView(option)}
                    />
                  </ToggleHolder>
                )}
              </div>
              {view === "Text" && (
                <>
                  {results?.map((organization) => (
                    <OrganizationResultCard
                      key={organization.id}
                      need={need}
                      organization={organization}
                      zipcode={zipCode}
                    />
                  ))}
                </>
              )}
              {view === "Map" && (
                <MapSection
                  bg={theme.background}
                  color={theme.secondaryFont}
                  isDark={isDark}
                >
                  <Map results={results} />
                </MapSection>
              )}
              <div className="flex items-center justify-center mt-8">
                {page > 0 && (
                  <LinkButton
                    onClick={() => changePage((page - 30) / 30)}
                    className="mr-4"
                  >
                    Previous
                  </LinkButton>
                )}
                {pageRange.map((_page, index) => (
                  <LinkButton
                    key={index}
                    className="mr-4"
                    disabled={page === _page * 30}
                    onClick={() => changePage(_page)}
                  >
                    {_page + 1}
                  </LinkButton>
                ))}
                {page >= 0 && canLoadNextPage && (
                  <LinkButton onClick={() => changePage((page + 30) / 30)}>
                    Next
                  </LinkButton>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default NearbyOrganizations;
