import styled from "styled-components";

export const SuggestionCard = styled.div`
  // padding: ${(props) => props.padding || "24px 68px"};
  border: 1px solid #f5f4fa;
  border-radius: 4px;

  min-height: ${(props) => props.h || "135px"};
  display: flex;
  flex-direction: column;
  justify-content: center;

  img.small {
    width: 54px;
    height: 36px;
  }

  &:hover {
    background: #f8f6fe;
  }
`;
