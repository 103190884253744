import React, { useContext, useState, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";

import { decodeToken, decodeTokenWithParam } from "../../../shared/helpers";
import { CustomSwitch } from "../../../shared/routing";
import useOrganization from "../../../shared/hooks/useOrganization";
import { authContext, themeContext } from "../../../shared/store/contexts";
import DashboardNavBar from "../../molecules/DashboardNavBar";
import { Layout } from "../../organisms/Layouts";
import SavedOrganizations from "../../templates/SavedOrganizations";
import SavedServices from "../../templates/SavedServices";

import SettingsPage from "../Settings";
import AllUserReferrals from "../../templates/UserReferrals";

const DashboardHome = () => {
  const { theme } = useContext(themeContext);
  let history = useHistory();

  const { auth } = useContext(authContext);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (auth && auth.token) {
      setIsValid(decodeTokenWithParam(auth.token));
    } else {
      setIsValid(decodeToken());
    }
  }, [auth]);

  // Get saved organizations
  const { getSavedOrganizations } = useOrganization();

  useEffect(() => {
    getSavedOrganizations(auth?.user?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.id]);

  return (
    <Layout>
      {!isValid && (
        <Redirect
          to={{
            pathname: "/",
            state: { from: history.location },
          }}
        />
      )}
      <CustomSwitch>
        <Route path="/dashboard/settings">
          <SettingsPage />
        </Route>
        <Route>
          <main className="container pt-10">
            <DashboardNavBar theme={theme} />
            <CustomSwitch>
              <Route path="/dashboard/my-referrals">
                <AllUserReferrals />
              </Route>
              <Route path="/dashboard/saved-organizations">
                <SavedOrganizations />
              </Route>
              <Route exact path={["/dashboard", "/dashboard/saved-services"]}>
                <SavedServices />
              </Route>
            </CustomSwitch>
          </main>
        </Route>
      </CustomSwitch>
    </Layout>
  );
};

export default DashboardHome;
