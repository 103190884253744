import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { themeContext } from "../../../shared/store/contexts/context.theme";
import starIcon from "../../../assets/images/star.svg";
import { P, H6, TextLink } from "../../atoms/Texts";
import { OutlineButton } from "../../atoms/Buttons";

const ResultStyle = styled.div``;

const VerfiedStyle = styled.div`
  width: fit-content;
  min-width: 112px;
`;

const Result = (props) => {
  const { theme } = useContext(themeContext);

  const [more, setMore] = useState(false);

  return (
    <ResultStyle className="block md:flex flex-wrap md:flex-no-wrap mb-12">
      <VerfiedStyle>
        {props.verified && (
          <div className="flex flex-no-wrap">
            <P color={theme.greenFont} fontSize="16px" className="mr-2">
              Verified
            </P>
            <img src={starIcon} alt="" />
          </div>
        )}
      </VerfiedStyle>
      <div>
        <H6 color={theme.purpleFont} className="mb-4">
          {props.title}
        </H6>

        <P color={theme.secondaryFont} fontSize="16px">
          {props.address}
        </P>

        <P color={theme.secondaryFont} fontSize="16px">
          {props.tel}
        </P>

        <a
          href={
            props.email.includes("mailto") ? props.email : `mailto:${props.email}`
          }
        >
          <P
            fontSize="14px"
            color={theme.secondaryFont}
            style={{
              overflowWrap: "anywhere",
            }}
          >
            {props.email}
          </P>
        </a>

        <TextLink
          color={theme.lightPurple}
          fontFamily="italic"
          className="mt-1 mb-4"
        >
          Last updated {props.lastUpdate}
        </TextLink>

        <div className="flex items-center">
          <TextLink
            color={props.open ? theme.greenFont : theme.redFont}
            fontFamily="medium"
            className="mr-2"
          >
            {props.open === true ? "Open Now" : "Closed now"}
          </TextLink>
          {/* <Link to="">
            <TextLink
              color={theme.purpleFont}
              fontFamily="medium"
            >
              {
                props.open === true ? "" : "See open hours"
              }
            </TextLink>
          </Link> */}
        </div>

        {more && (
          <>
            <TextLink color={theme.primaryFont} fontFamily="semi" className="mt-10">
              Eligibility
            </TextLink>
            <P color={theme.lightFont} fontSize="16px" className="mt-2">
              {props.eligibility}
            </P>

            <TextLink color={theme.primaryFont} fontFamily="semi" className="mt-8">
              Description
            </TextLink>
            <P color={theme.lightFont} fontSize="16px" className="mt-2 md:w-1/2">
              {props.description}
            </P>

            <TextLink color={theme.primaryFont} fontFamily="semi" className="mt-8">
              Languages
            </TextLink>
            <P color={theme.lightFont} fontSize="16px" className="mt-2">
              {props.languages}
            </P>

            <TextLink color={theme.primaryFont} fontFamily="semi" className="mt-8">
              Cost
            </TextLink>
            <P color={theme.lightFont} fontSize="16px" className="mt-2">
              {props.cost}
            </P>

            <TextLink color={theme.primaryFont} fontFamily="semi" className="mt-8">
              Website
            </TextLink>
            <Link to={props.website}>
              <P color={theme.lightFont} fontSize="16px" className="mt-2">
                {props.website}
              </P>
            </Link>

            <TextLink color={theme.primaryFont} fontFamily="semi" className="mt-8">
              Twitter
            </TextLink>
            <Link to={props.twitter}>
              <P color={theme.lightFont} fontSize="16px" className="mt-2">
                {props.twitter}
              </P>
            </Link>

            <OutlineButton
              color={theme.buttonFont}
              bg="transparent"
              className="mt-10 mr-6"
              onClick={() => setMore(false)}
            >
              See Less
            </OutlineButton>

            <Link to={`/suggest/${props.id}`}>
              <OutlineButton
                color={theme.buttonFont}
                bg="transparent"
                className="mt-10"
              >
                Suggest Edit
              </OutlineButton>
            </Link>
          </>
        )}

        {!more && (
          <OutlineButton
            color={theme.buttonFont}
            bg="transparent"
            className="mt-6"
            onClick={() => setMore(true)}
          >
            See More
          </OutlineButton>
        )}
      </div>
    </ResultStyle>
  );
};

export default Result;
