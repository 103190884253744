import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { P, H1, H4, H5 } from "../../atoms/Texts";
import { LandingPageFooter } from "../../organisms/Footers";
import { authContext, themeContext } from "../../../shared/store/contexts";
import { Layout } from "../../organisms/Layouts";

const PrivacyPolicyPage = () => {
  const { auth } = useContext(authContext);
  const { theme } = useContext(themeContext);

  useEffect(() => {
    if (auth && auth.token) {
      // setIsValid(decodeToken(auth.token));
    }
  }, [auth]);

  return (
    <Layout>
      <div className="container mt-10">
        <H1
          color="#442ED0"
          className="mb-10 font-bold"
          mediumSize="56px"
          smallSize="32px"
        >
          Privacy Policy
        </H1>

        <div className="container-flexible lg:w-10/12 mb-20 ">
          <H4 color="#0C024D" fontFamily="semi" className="mb-4" fontSize="18px">
            Our Policy
          </H4>
          <P>
            Social Safety Net{" "}
            <Link to="/" className="underline text-purple-500">
              {" "}
              https://socialsafety.net
            </Link>{" "}
            is operated by Philanthrolab and has been created to provide information
            about our company and platform which provides tools for data hosting,
            sharing and processing services (together with the Site, the “Services”)
            to our Service visitors (“you”, “yours”). This Privacy Policy sets forth
            Philanthrolab’s policy with respect to information including personally
            identifiable data (“Personal Data”) and other information that is
            collected from visitors to the Site and Services.
          </P>
          <H4
            color="#0C024D"
            fontFamily="semi"
            className="mt-8 mb-4"
            fontSize="18px"
          >
            Information we collect
          </H4>
          <P>
            When you interact with us through the Services, we may collect Personal
            Data and other information from you, as further described below:
          </P>
          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-4 mb-2"
            fontSize="16px"
          >
            - Personal Data That You Provide Through the Services
          </H5>
          <P className="pl-4">
            We collect Personal Data from you when you voluntarily provide such
            information, such as when you contact us with inquiries, respond to one
            of our surveys, register for access to the Services or use certain
            Services. Wherever Philanthrolab collects Personal Data we make an effort
            to provide a link to this Privacy Policy.
          </P>
          <P className="pl-4 mt-4">
            By voluntarily providing us with Personal Data, you are consenting to our
            use of it in accordance with this Privacy Policy. If you provide Personal
            Data to the Services, you acknowledge and agree that such Personal Data
            may be transferred from your current location to the offices and servers
            of Philanthrolab and the authorized third parties referred to herein
            located in the United States.
          </P>
          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-4 mb-2"
            fontSize="16px"
          >
            - Other Information
          </H5>
          <H5
            color="#0C024D"
            fontFamily="medium"
            className="pl-4 my-2"
            fontSize="16px"
          >
            i. Non-Identifiable Data
          </H5>
          <P className="pl-8">
            When you interact with Philanthrolab through the Services, we receive and
            store certain personally non-identifiable information. Such information,
            which is collected passively using various technologies, cannot presently
            be used to specifically identify you. Philanthrolab may store such
            information itself or such information may be included in databases owned
            and maintained by Philanthrolab affiliates, agents or service providers.
            The Services may use such information and pool it with other information
            to track, for example, the total number of visitors to our Site, the
            number of visitors to each page of our Site, and the domain names of our
            visitors’ Internet service providers. It is important to note that no
            Personal Data is available or used in this process.
          </P>
          <P className="pl-8 mt-4">
            In operating the Services, we may use a technology called “cookies.” A
            cookie is a piece of information that the computer that hosts our
            Services gives to your browser when you access the Services. Our cookies
            help provide additional functionality to the Services and help us analyze
            Services usage more accurately. For instance, our Site may set a cookie
            on your browser that allows you to access the Services without needing to
            remember and then enter a password more than once during a visit to the
            Site. In all cases in which we use cookies, we will not collect Personal
            Data except with your permission. On most web browsers, you will find a
            “help” section on the toolbar. Please refer to this section for
            information on how to receive notification when you are receiving a new
            cookie and how to turn cookies off. We recommend that you leave cookies
            turned on because they allow you to take advantage of some of the Service
            features.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="pl-4 my-2"
            fontSize="16px"
          >
            ii. Aggregated Personal Data
          </H5>
          <P className="pl-8">
            In an ongoing effort to better understand and serve the users of the
            Services, Philanthrolab may conduct research on its customer
            demographics, interests and behavior based on the Personal Data and other
            information provided to us. This research may be compiled and analyzed on
            an aggregate basis, and Philanthrolab may share this aggregate data with
            its affiliates, agents and business partners. This aggregate information
            does not identify you personally. Philanthrolab may also disclose
            aggregated user statistics in order to describe our services to current
            and prospective business partners, and to other third parties for other
            lawful purposes.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Our Use of Your Personal Data and Other Information
          </H5>
          <P className="pl-4">
            Philanthrolab uses the Personal Data you provide in a manner that is
            consistent with this Privacy Policy. If you provide Personal Data for a
            certain reason, we may use the Personal Data in connection with the
            reason for which it was provided. For instance, if you contact us by
            email, we will use the Personal Data you provide to answer your question
            or resolve your problem. Also, if you provide Personal Data in order to
            obtain access to the Services, we will use your Personal Data to provide
            you with access to such services and to monitor your use of such
            services. Philanthrolab and its subsidiaries and affiliates (the “Related
            Companies”) may also use your Personal Data and other personally
            non-identifiable information collected through the Services to help us
            improve the content and functionality of the Services, to better
            understand our users and to improve the Services. Philanthrolab and its
            affiliates may use this information to contact you in the future to tell
            you about services we believe will be of interest to you. If we do so,
            each marketing communication we send you will contain instructions
            permitting you to “opt-out” of receiving future marketing communications.
            In addition, if at any time you wish not to receive any future marketing
            communications or you wish to have your name deleted from our mailing
            lists, please contact us as indicated below.
          </P>
          <P className="pl-4 mt-4">
            If Philanthrolab intends on using any Personal Data in any manner that is
            not consistent with this Privacy Policy, you will be informed of such
            anticipated use prior to or at the time at which the Personal Data is
            collected.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Our Disclosure of Your Personal Data and Other Information
          </H5>
          <P className="pl-4">
            Philanthrolab is not in the business of selling your information. We
            consider this information to be a vital part of our relationship with you
            and we will not sell information collected via this site, such as your
            email address, to third parties.
          </P>
          <P className="pl-4 mt-4">
            There are, however, certain circumstances in which we may share your
            Personal Data with certain third parties without further notice to you,
            as set forth below:
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="pl-4 my-2"
            fontSize="16px"
          >
            i. Business Transfers
          </H5>
          <P className="pl-8">
            As we develop our business, we might sell or buy businesses or assets. In
            the event of a corporate sale, merger, reorganization, dissolution or
            similar event, Personal Data may be part of the transferred assets.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="pl-4 my-2"
            fontSize="16px"
          >
            ii. Related Companies
          </H5>
          <P className="pl-8">
            We may also share your Personal Data with our Related Companies for
            purposes consistent with this Privacy Policy.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="pl-4 my-2"
            fontSize="16px"
          >
            iii. Agents, Consultants and Related Third Parties
          </H5>
          <P className="pl-8">
            Philanthrolab, like many businesses, sometimes hires other companies to
            perform certain business-related functions. Examples of such functions
            include mailing information, maintaining databases and processing
            payments. When we employ another entity to perform a function of this
            nature, we only provide them with the information that they need to
            perform their specific function.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="pl-4 my-2"
            fontSize="16px"
          >
            iv. Legal Requirements
          </H5>
          <P className="pl-8">
            Philanthrolab may disclose your Personal Data if required to do so by law
            or in the good faith belief that such action is necessary to (i) comply
            with a legal obligation, (ii) protect and defend the rights or property
            of Philanthrolab, (iii) act in urgent circumstances to protect the
            personal safety of users of the Services or the public, or (iv) protect
            against legal liability.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Your Choices
          </H5>
          <P className="pl-4">
            You can visit the Site without providing any Personal Data. If you choose
            not to provide any Personal Data, you may not be able to use certain
            Services.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Exclusions
          </H5>
          <P className="pl-4">
            This Privacy Policy does not apply to any Personal Data collected by
            Philanthrolab other than Personal Data collected through the Services.
            This Privacy Policy shall not apply to any unsolicited information you
            provide to **Philanthrolab ** through the Services or through any other
            means. This includes, but is not limited to, information posted to any
            public areas of the Services, such as forums, any ideas for new products
            or modifications to existing products, and other unsolicited submissions
            (collectively, “Unsolicited Information”). All Unsolicited Information
            shall be deemed to be non-confidential and Philanthrolab shall be free to
            reproduce, use, disclose, and distribute such Unsolicited Information to
            others without limitation or attribution.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Children
          </H5>
          <P className="pl-4">
            Philanthrolab does not knowingly collect Personal Data from children
            under the age of 13. If you are under the age of 13, please do not submit
            any Personal Data through the Services. We encourage parents and legal
            guardians to monitor their children’s Internet usage and to help enforce
            our Privacy Policy by instructing their children never to provide
            Personal Data on the Services without their permission. If you have
            reason to believe that a child under the age of 13 has provided Personal
            Data to Philanthrolab through the Services, please contact us, and we
            will endeavor to delete that information from our databases.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Links to Other Websites
          </H5>
          <P className="pl-4">
            This Privacy Policy applies only to the Services. The Services may
            contain links to other web sites not operated or controlled by
            Philanthrolab (the “Third Party Sites”). The policies and procedures we
            described here do not apply to the Third Party Sites. The links from the
            Services do not imply that Philanthrolab endorses or has reviewed the
            Third Party Sites. We suggest contacting those sites directly for
            information on their privacy policies.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Security
          </H5>
          <P className="pl-4">
            Philanthrolab takes reasonable steps to protect the Personal Data
            provided via the Services from loss, misuse, and unauthorized access,
            disclosure, alteration, or destruction. However, no Internet or email
            transmission is ever fully secure or error free. In particular, email
            sent to or from the Services may not be secure. Therefore, you should
            take special care in deciding what information you send to us via email.
            Please keep this in mind when disclosing any Personal Data to
            Philanthrolab via the Internet.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Other Terms and Conditions
          </H5>
          <P className="pl-4">
            Your access to and use of the Services is subject to the{" "}
            <Link to="/terms" className="text-purple-500">
              Terms of Service
            </Link>
            .
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Changes to the Privacy Policy
          </H5>
          <P className="pl-4">
            The Services and our business may change from time to time. As a result,
            at times it may be necessary for Philanthrolab to make changes to this
            Privacy Policy. Philanthrolab reserves the right to update or modify this
            Privacy Policy at any time and from time to time without prior notice.
            Please review this policy periodically, and especially before you provide
            any Personal Data. This Privacy Policy was last updated on the date
            indicated above. Your continued use of the Services after any changes or
            revisions to this Privacy Policy shall indicate your agreement with the
            terms of such revised Privacy Policy.
          </P>

          <H5
            color="#0C024D"
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - Access to Information; Contacting Us
          </H5>
          <P className="pl-4">
            To keep your Personal Data accurate, current, and complete, please
            contact us as specified below. We will take reasonable steps to update or
            correct Personal Data in our possession that you have previously
            submitted via the Services.
          </P>
          <P className="pl-4 mt-4 mb-40">
            Please also feel free to contact us if you have any questions about
            Philanthrolab’s Privacy Policy or the information practices of the
            Services You may contact us by emailing us at{" "}
            <a href="mailto:contact@philanthrolab.co" className="text-purple-800">
              contact@philanthrolab.co.
            </a>
          </P>
        </div>
      </div>

      <LandingPageFooter theme={theme} />
    </Layout>
  );
};

export default PrivacyPolicyPage;
