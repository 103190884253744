import { gql } from "@apollo/client";

export const REFER_SELF_MUTATION = gql`
  mutation ReferSelf(
    $userId: String!
    $serviceId: String!
    $orgId: String!
    $serviceName: String!
  ) {
    referSelfForService(
      userId: $userId
      serviceId: $serviceId
      orgId: $orgId
      serviceName: $serviceName
    )
  }
`;
