import React, { useState } from "react";
import styled from "styled-components";
import { text_truncate } from "../../../shared/helpers";
import { P } from "../../atoms/Texts";

const ExpandableDescriptionStyle = styled(P)``;

const ExpandableDescription = ({
  hideMoreOption,
  text,
  text_color = "#393648",
  length,
  ending,
  fontSize,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  let truncated_text;
  if (text) {
    truncated_text = text_truncate(text, length, ending);

    if (text.length < length) {
      hideMoreOption = true;
    }
  } else if (text?.length < 1) {
    hideMoreOption = true;
  }

  return (
    <ExpandableDescriptionStyle
      expanded={expanded}
      color={text_color}
      fontSize={fontSize}
    >
      <span>{!expanded ? truncated_text : text}</span>
      {!hideMoreOption && (
        <span
          style={{
            color: "#442ED0",
          }}
          className="ml-1 cursor-pointer"
          onClick={toggleExpanded}
        >
          {!expanded ? "expand text" : "collapse text"}
        </span>
      )}
    </ExpandableDescriptionStyle>
  );
};

export default ExpandableDescription;
