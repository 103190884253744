import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { P } from "../../atoms/Texts";
import useService from "../../../shared/hooks/useService";
import { authContext } from "../../../shared/store/contexts";
import useReferrals from "../../../shared/hooks/useReferrals";

const NavStyle = styled.nav``;

const CustomNavLink = styled(NavLink)`
  padding: 9px;
  font-size: 16px;
  margin-right: 40px;
  border-bottom: solid 2px transparent;

  &.active {
    font-weight: 600;
    // background: #442ed0;
    color: #442ed0;
    border-bottom: solid 2px #442ed0;
  }
`;

const DashboardNavBar = ({ theme }) => {
  const { auth } = useContext(authContext);

  // Get user referrals
  const { getAllUserReferrals, allUserReferrals } = useReferrals();

  // Get saved services
  const { getSavedServices } = useService();

  useEffect(() => {
    if (auth?.user?.id) {
      getSavedServices(auth?.user?.id);
      getAllUserReferrals(auth?.user?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.id]);

  return (
    <NavStyle className="flex flex-no-wrap overflow-x-auto items-center">
      <CustomNavLink
        to={`/dashboard/saved-services`}
        className="flex items-center"
        data-testid="services-link"
        activeClassName="active"
        isActive={(match, location) => {
          if (location.pathname === "/dashboard") {
            return true;
          }
          if (match) {
            return true;
          }
        }}
      >
        <div className="flex flex-grow">
          <P color="inherit" fontFamily="medium" className="mr-2 title">
            Services
          </P>
          <P color={theme.lightFont}>{auth?.user?.savedServices?.length}</P>
        </div>
      </CustomNavLink>
      <CustomNavLink
        to={`/dashboard/saved-organizations`}
        className="flex items-center"
        data-testid="organization-link"
        activeClassName="active"
      >
        <div className="flex flex-grow">
          <P color="inherit" fontFamily="medium" className="mr-2 title">
            Organization
          </P>
          <P color={theme.lightFont}>{auth?.user?.savedOrganizations?.length}</P>
        </div>
      </CustomNavLink>
      <CustomNavLink
        to={`/dashboard/my-referrals`}
        className="flex items-center"
        data-testid="referral-link"
        activeClassName="active"
      >
        <div className="flex flex-grow">
          <P color="inherit" fontFamily="medium" className="mr-1 title">
            Referrals
          </P>
          <P color={theme.lightFont}>{allUserReferrals?.length}</P>
        </div>
      </CustomNavLink>
    </NavStyle>
  );
};

export default DashboardNavBar;
