import React, { useContext, useEffect } from "react";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-widgets/dist/css/react-widgets.css";

import StoreProvider from "./shared/store/store.provider";
import {
  DrawerProvider,
  PopNotificationProvider,
  ThemeProvider,
} from "./shared/store/contexts";

import Home from "./patterns/pages/Home";
import SearchResults from "./patterns/pages/SearchResult";
import LoginPage from "./patterns/pages/Login";
import SignUpPage from "./patterns/pages/SignUp";
import { ForgotPassword } from "./patterns/pages/ForgotPassword";
import { CustomSwitch } from "./shared/routing";
import NotFoundPage from "./patterns/pages/404";
import VerifyEmailPage from "./patterns/pages/VerifyEmail";
import OrganizationsPage from "./patterns/pages/OrganisationHome";
import OrganizationSearchResultsPage from "./patterns/pages/OrganizationSearchResults";
import ContactPage from "./patterns/pages/ContactUs";
import SpecificOrganizationPage from "./patterns/pages/SpecificOrganization";
import OrganizationCategoriesPage from "./patterns/pages/OrganizationCategories";
import SpecificOrganizationCategoryPage from "./patterns/pages/SpecificOrganizationCategoryResult";
import DashboardHome from "./patterns/pages/DashboardHome/DashboardHome";
import SpecificServicePage from "./patterns/pages/SpecificService";
import AboutUsPage from "./patterns/pages/About";
import TermsPage from "./patterns/pages/TermsOfService";
import PrivacyPolicyPage from "./patterns/pages/PrivacyPolicy";
import DocumentationPage from "./patterns/pages/Documentation";
import ReferralAcceptancePage from "./patterns/pages/ReferralAcceptance";
import { locationContext } from "./shared/store/contexts/context.location";
import AllOrganizations from "./patterns/templates/AllOrganizations";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const hasMatchedNotFound = useRouteMatch("/404");

  const { updateLocation, setIsFetchingUserLocation } = useContext(locationContext);

  const getLocation = () => {
    setIsFetchingUserLocation(true);

    fetch("https://ipapi.co/json")
      .then((response) => response.json())
      .then((data) => {
        updateLocation({
          zipCode: data?.postal || "",
          latitude: data?.latitude,
          longitude: data?.longitude,
          // latitude: 32.779167,
          // longitude: -96.808891,
        });
        setIsFetchingUserLocation(false);
      })
      .catch((error) => {
        setIsFetchingUserLocation(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ScrollToTop />
      <DrawerProvider>
        <StoreProvider>
          <ThemeProvider>
            <PopNotificationProvider>
              <Route path="/404" component={NotFoundPage} />

              {!hasMatchedNotFound && (
                <CustomSwitch>
                  <Route component={DashboardHome} path="/dashboard/" />

                  <Route path="/forgotPassword">
                    <ForgotPassword />
                  </Route>

                  <Route path="/register">
                    <SignUpPage />
                  </Route>

                  <Route path="/login">
                    <LoginPage />
                  </Route>

                  <Route path="/verify/:email">
                    <VerifyEmailPage />
                  </Route>

                  <Route path="/referral-acceptance">
                    <ReferralAcceptancePage />
                  </Route>

                  <Route path="/contact">
                    <ContactPage />
                  </Route>

                  <Route path="/about">
                    <AboutUsPage />
                  </Route>

                  <Route path="/terms">
                    <TermsPage />
                  </Route>

                  <Route path="/documentation">
                    <DocumentationPage />
                  </Route>

                  <Route path="/privacy">
                    <PrivacyPolicyPage />
                  </Route>

                  <Route
                    path="/service/details/:id"
                    component={SpecificServicePage}
                  />

                  <Route
                    path="/organization/results/:need/:zipCode?"
                    component={OrganizationSearchResultsPage}
                  />

                  <Route
                    path="/organization/details/:id/:zipcode?"
                    component={SpecificOrganizationPage}
                  />

                  <Route exact path="/organizations/all/:zipcode?">
                    <AllOrganizations />
                  </Route>

                  <Route exact path="/organizations/:zipcode?">
                    <OrganizationsPage />
                  </Route>

                  <Route
                    path="/organization/category/:category/:subCategory?"
                    component={SpecificOrganizationCategoryPage}
                  />

                  <Route exact path="/organization/categories">
                    <OrganizationCategoriesPage />
                  </Route>

                  <Route path="/results/:need/:zipCode" component={SearchResults} />

                  <Route exact path="/">
                    <Home />
                  </Route>
                </CustomSwitch>
              )}
            </PopNotificationProvider>
          </ThemeProvider>
        </StoreProvider>
      </DrawerProvider>
    </>
  );
}

export default App;
