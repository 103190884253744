import { gql } from "@apollo/client";

export const FETCH_API_KEYS = gql`
  query GetApiKeys {
    getApiKeys {
      appId
      keys
    }
  }
`;
