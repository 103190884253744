import React, { useContext, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import LoadingBar from "react-top-loading-bar";

import loadIcon from "../../../assets/images/tail-spin.svg";

import { DELETE_ACCOUNT } from "../../../shared/queries";
import { authContext, themeContext } from "../../../shared/store/contexts";
import { H6, P } from "../../atoms/Texts";
import { toastError, toastSuccess } from "../../../shared/helpers";
import { PrimaryButton } from "../../atoms/Buttons";
import DeleteAccountModal from "../../organisms/DeleteAccountModal";

const DeleteAccount = () => {
  const { theme } = useContext(themeContext);
  const { auth } = useContext(authContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteAccount, { loading }] = useMutation(DELETE_ACCOUNT);

  const toggleModal = () => setShowDeleteModal(!showDeleteModal);

  const loader = useRef(null);

  const onDeleteAccount = async () => {
    if (loader) {
      loader.current.continuousStart();
    }

    deleteAccount()
      .then((data) => {
        if (data?.data?.deleteUser.__typename === "DeletedUserResponse") {
          toastSuccess(data.data.deleteUser.message);
          auth.logOut();
          return loader && loader.current?.complete();
        } else {
          throw new Error(data?.data.deleteUser.message);
        }
      })
      .catch((error) => {
        console.warn(error);
        toastError(error.message);
        return loader && loader.current?.complete();
      });
  };

  return (
    <div>
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <H6 className="mb-2" color={theme.primaryFont}>
        Delete Account
      </H6>
      <P color={theme.primaryFont} className="mb-4">
        This is an irreversible action. All your Social Safety account data across
        all our products will be lost.
      </P>
      <PrimaryButton.Danger smallW="100%" onClick={toggleModal}>
        {!loading ? (
          "Delete Account"
        ) : (
          <img src={loadIcon} alt="Loading" className="mx-auto h-6 w-6" />
        )}
      </PrimaryButton.Danger>
      {showDeleteModal && (
        <DeleteAccountModal
          closeModal={toggleModal}
          confirmDelete={onDeleteAccount}
          deleting={loading}
        />
      )}
    </div>
  );
};

export default DeleteAccount;
