import { toastWarning } from "./utils.toasts";

export const triggerShare = ({ title, text, url }) => {
  const shareData = { title, text, url };

  try {
    navigator.share(shareData);
    return true;
  } catch (error) {
    toastWarning("Your browser doesn't support share feature");
    return false;
  }
};
