import React from "react";
import styled from "styled-components";
import { P } from "../Texts";

const CircleRadioStyle = styled.input`
  background: transparent;

  &,
  &:after,
  &:before {
    border: 1px solid #cecae1;
    border-radius: 5px;
  }

  :checked + label {
    font-weight: 600;
  }

  :checked + label .check-holder > div {
    background: #442ED0;
  }
`;

const CircleStyle = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid #442ed0;
  border-radius: 50%;

  > div {
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
`;

export const CircleRadio = React.forwardRef(
  ({ className, id, name, label, labelColor = "#1E202A", value }, ref) => {
    return (
      <div className="relative flex items-center">
        <CircleRadioStyle
          type="radio"
          ref={ref}
          name={name}
          id={id}
          value={value}
          className="opacity-0 w-0 h-0"
        />
        {label && (
          <label htmlFor={id} className="flex items-center">
            <CircleStyle className="check-holder flex items-center justify-center">
              <div></div>
            </CircleStyle>
            <P color={labelColor} className="ml-4">
              {label}
            </P>
          </label>
        )}
      </div>
    );
  },
);
