import React, { useState, createContext } from "react";
import t from "prop-types";

export const locationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  const [isFetchingUserLocation, setIsFetchingUserLocation] = useState(true);

  const updateLocation = (userLocation) => {
    setLocation(userLocation);
  };

  return (
    <locationContext.Provider
      value={{
        updateLocation,
        location,
        isFetchingUserLocation,
        setIsFetchingUserLocation,
      }}
    >
      {children}
    </locationContext.Provider>
  );
};

LocationProvider.propTypes = {
  children: t.node.isRequired,
};
