import React, { useContext } from "react";
import styled from "styled-components";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";

import { authContext, notificationContext } from "../../../shared/store/contexts";
import { OutlineButton } from "../../atoms/Buttons";
import { P } from "../../atoms/Texts";

const color_variants = {
  success: {
    bg: "#C4F3D9",
    color: "#007E39",
  },
  error: {
    bg: "#FFF4F7",
    color: "#C92159",
  },
  warning: {
    bg: "#F9C900",
    color: "#FF833E",
  },
  default: {
    bg: "#22202D",
    color: "#22202D",
  },
};

const HolderStyle = styled.section`
  position: fixed;
  top: 78px;
  z-index: 990;
  // width: 100vw;

  @media (max-width: 600px) {
    top: 80px;
  }
`;

const Style = styled(motion.div)`
  min-height: 50px;

  background: #ffffff;
  border: 1px solid
    ${(props) =>
      color_variants[props.variant]?.color ?? color_variants.default?.color};
  border-left: 4px solid
    ${(props) =>
      color_variants[props.variant]?.color ?? color_variants.default?.color};

  // background: ${(props) =>
    color_variants[props.variant]?.bg ?? color_variants.default?.bg};
`;

const NotificationBar = () => {
  const { togglePopNotification, isPopNotificationActive, notificationMessage } =
    useContext(notificationContext);

  const { auth } = useContext(authContext);

  const motion_variants = {
    open: { opacity: 1, scale: 1 },
    closed: { opacity: 0, scale: 0.5 },
  };

  const closeBar = () => {
    auth.updateAuth({
      ...auth,
      newLogin: false,
    });
    togglePopNotification(false);
  };

  return (
    <>
      {isPopNotificationActive && (
        <HolderStyle className="w-screen container">
          <Style
            animate={isPopNotificationActive ? "active" : "inactive"}
            variants={motion_variants}
            transition={{ duration: 4 }}
            className="max-w-sm md:max-w-none p-4 md:flex shadow-2xl md:items-center rounded-md"
            variant={notificationMessage?.variant}
          >
            <div className="md:flex ">
              <div className="flex justify-between">
                <Icon.AlertTriangle
                  color="#FFFFFF"
                  fill={color_variants[notificationMessage?.variant]?.color}
                  className="mb-2 md:mb-0 md:mr-4"
                  size={24}
                />
                <Icon.XCircle
                  color="#FFFFFF"
                  fill={color_variants.default?.bg}
                  className="md:hidden mb-4 md:mb-0 cursor-pointer ml-auto"
                  onClick={closeBar}
                />
              </div>
              <div className=" ">
                <P
                  color="#22202D"
                  // color={color_variants[notificationMessage?.variant]?.color}
                  className="mb-2"
                  smallSize="16px"
                >
                  {notificationMessage?.message}
                </P>
                {notificationMessage?.action && (
                  <Link to={notificationMessage?.actionLinkHref}>
                    <OutlineButton
                      // bg="transparent"
                      color="#22202D"
                      p="6px 8px"
                      hoverBg="#e5e5e5"
                      // color={color_variants[notificationMessage?.variant]?.color}
                    >
                      {notificationMessage?.action}
                    </OutlineButton>
                  </Link>
                )}
              </div>
            </div>
            <Icon.XCircle
              color="#FFFFFF"
              fill={color_variants.default?.bg}
              className="hidden md:block mb-4 md:mb-0 cursor-pointer ml-auto"
              onClick={closeBar}
            />
          </Style>
        </HolderStyle>
      )}
    </>
  );
};

export default NotificationBar;
