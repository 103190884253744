import React from "react";
import styled from "styled-components";
import { H1, P } from "../../atoms/Texts";

const PageHeroStyle = styled.div`
  background: #f8f6fe;
`;

const PageHero = ({ title, description, className }) => {
  return (
    <PageHeroStyle className={`py-10 container ${className}`}>
      <H1 fontSize="26px" color="#22202D" className="mb-4">
        {title}
      </H1>
      <P color="#1E202A">{description}</P>
    </PageHeroStyle>
  );
};

export default PageHero;
