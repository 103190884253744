import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { P } from "../../atoms/Texts";

const NavStyle = styled.nav``;

const CustomNavLink = styled(NavLink)`
  padding: 9px;
  color: #646a86;
  font-size: 16px;
  margin-right: 40px;
  border-bottom: solid 2px transparent;

  &.active {
    font-weight: 600;
    // background: #442ed0;
    color: #442ed0;
    border-bottom: solid 2px #442ed0;
  }
`;

const ReferralsNavBar = ({ theme, received, sent }) => {
  return (
    <NavStyle className="flex flex-no-wrap overflow-x-auto items-center">
      <CustomNavLink
        to={`/dashboard/my-referrals/received`}
        className="flex items-center"
        activeClassName="active"
        isActive={(match, location) => {
          if (location.pathname === "/dashboard/my-referrals") {
            return true;
          }
          if (match) {
            return true;
          }
        }}
      >
        <div className="flex flex-grow">
          <P color="inherit" fontFamily="medium" className="mr-1 title">
            Referrals Received
          </P>
          <P color={theme?.lightFont}>{received?.length}</P>
        </div>
      </CustomNavLink>
      <CustomNavLink
        to={`/dashboard/my-referrals/sent`}
        className="flex items-center"
        activeClassName="active"
      >
        <div className="flex flex-grow">
          <P color="inherit" fontFamily="medium" className="mr-1 title">
            Referrals Sent
          </P>
          <P color={theme?.lightFont}>{sent?.length}</P>
        </div>
      </CustomNavLink>
    </NavStyle>
  );
};

export default ReferralsNavBar;
