import { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { USER_REFERRALS_QUERY } from "../queries";
import { authContext } from "../store/contexts";

const useReferrals = () => {
  const { auth } = useContext(authContext);
  const [referrals, setReferrals] = useState(null);
  const [received, setReceived] = useState(null);
  const [sent, setSent] = useState(null);

  // Query for getting user referrals
  const [_getAllUserReferrals, { data, loading: isGettingAllUserReferrals }] =
    useLazyQuery(USER_REFERRALS_QUERY);

  const getAllUserReferrals = (id = auth?.user?.id) => {
    _getAllUserReferrals({
      variables: {
        userId: id,
      },
    });
  };

  useEffect(() => {
    if (data?.getAllUserReferrals) {
      setReferrals(data?.getAllUserReferrals);
      setReceived(receivedReferrals());
      setSent(sentReferrals());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getAllUserReferrals]);

  // useEffect(() => {
  //   if (data?.getAllUserReferrals) {
  //     setReferrals(data?.getAllUserReferrals);
  //     setReceived(
  //       data?.getAllUserReferrals?.filter((r) => r.recipientID === auth?.user?.id),
  //     );
  //     setSent(
  //       data?.getAllUserReferrals?.filter((r) => r.recipientID != auth?.user?.id),
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [referrals]);

  const receivedReferrals = () =>
    data?.getAllUserReferrals?.filter((r) => r.recipientID === auth?.user?.id);

  const sentReferrals = () =>
    data?.getAllUserReferrals?.filter((r) => r.recipientID !== auth?.user?.id);

  // Function to split referrals into received or sent

  // Methods and Properties from hook
  return {
    getAllUserReferrals,
    isGettingAllUserReferrals,
    allUserReferrals: referrals,
    received,
    sent,
  };
};

export default useReferrals;
