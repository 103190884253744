export default {
  SSN: {
    color: "#2220A9",
    bg: "#DCD6FE",
  },
  SDEP: {
    color: "#167C3C",
    bg: "#D8FDEC",
  },
  FORM: {
    color: "#7C033C",
    bg: "#FDD2DC",
  },
  open: {
    color: "#FFFFFF",
    bg: "#3BC97E",
  },
  closed: {
    color: "#E14170",
    bg: "#FFF4F7",
  },
  pending: {
    color: "#663C02",
    bg: "#FFF4E5",
  },
  approved: {
    color: "#167C3C",
    bg: "#D8FDEC",
  },
  rejected: {
    color: "#7C033C",
    bg: "#FDD2DC",
  },
};
