import React, { useRef } from "react";

import LoadingBar from "react-top-loading-bar";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import dashboardLogo from "../../../assets/images/darkLogo.svg";
import loadIcon from "../../../assets/images/tail-spin.svg";

import { P, H6 } from "../../atoms/Texts";
import { DarkFormField } from "../../atoms/Inputs";
import { PrimaryButton } from "../../atoms/Buttons";
import { ThemeLessLayout } from "../../organisms/Layouts";
import { FormCard } from "../../atoms/Cards";
import { Link } from "react-router-dom";
import { toastError, toastSuccess } from "../../../shared/helpers";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_MUTATION } from "../../../shared/queries";

export const ForgotPassword = () => {
  const loader = useRef(null);

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  const [resendEmail, { loading }] = useMutation(RESET_PASSWORD_MUTATION);

  const onSubmit = (data) => {
    if (loader) {
      loader.current.continuousStart();
    }

    resendEmail({
      variables: {
        email: data.email,
      },
    })
      .then((response) => {
        if (response?.data?.resetUserPassword) {
          toastSuccess(response.data.resetUserPassword.message);
          return loader && loader.current?.complete();
        } else {
          throw new Error(response.errors);
        }
      })
      .catch((errors) => {
        toastError(errors?.message);
        return loader && loader.current?.complete();
      });
  };

  return (
    <ThemeLessLayout>
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <section className="flex flex-col items-center">
        <Link to="/" className="flex">
          <img src={dashboardLogo} alt="SocialSafety" className="mb-12 block" />
        </Link>
        <div className="flex items-center">
          <FormCard pVertical="64px" pHorizontal="56px" maxW="476px">
            <H6 color="#22202D" fontSize="18px" className="mb-12">
              Reset your password to continue sharing, accessing, and working with
              your data.
            </H6>

            <form onSubmit={handleSubmit(onSubmit)}>
              <DarkFormField
                type="email"
                id="email"
                name="email"
                w="135px"
                ref={register({
                  required: "Email is required",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Please enter a valid email.",
                  },
                })}
                placeholder="example@mail.com"
                label="Email"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="email"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <PrimaryButton className="w-full">
                {!loading ? (
                  "Reset Password"
                ) : (
                  <img src={loadIcon} alt="Loading" className="mx-auto h-6 w-6" />
                )}
              </PrimaryButton>
            </form>
          </FormCard>
        </div>
      </section>
    </ThemeLessLayout>
  );
};
