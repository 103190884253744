import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import * as Icon from "react-feather";
import { NavLink, Link } from "react-router-dom";

// import { authContext } from "../store/AuthStore";
import logo from "../../../assets/images/logo.svg";
import {
  authContext,
  drawerContext,
  themeContext,
} from "../../../shared/store/contexts";
import { HeaderButton } from "../../atoms/Buttons";
import { decodeToken, decodeTokenWithParam } from "../../../shared/helpers";
import { H5, P, TextLink } from "../../atoms/Texts";

const DrawerStyle = styled.aside`
  position: fixed;
  top: 0;
  right: -110%;
  transition: right 0.3s ease-in-out;
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  background: #ffffff;

  &.active {
    right: 0;
  }
`;

const ExpandedSideNav = styled.ul`
  background: #ffffff;
  width: 100%;
  z-index: 100003;
  position: relative;
`;

const ExpandedNavLink = styled(NavLink)`
  margin-bottom: 8px;
  padding: 9px 16px;
  border-radius: 4px;
  color: #646a86;
  font-family: "medium", sans-serif;
  font-size: 16px;

  &.active {
    font-weight: 600;
    // background: #442ed0;
    color: #442ed0;
  }
`;

const Drawer = () => {
  const { toggleDrawer, isDrawerActive } = useContext(drawerContext);
  const { theme } = useContext(themeContext);
  // let { path } = useRouteMatch();
  // let history = useHistory();
  const { auth } = useContext(authContext);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (auth && auth.token) {
      setIsValid(decodeTokenWithParam(auth.token));
    } else {
      setIsValid(decodeToken());
    }
  }, [auth]);

  return (
    <DrawerStyle className={`${isDrawerActive ? "active" : ""}`}>
      <div
        className="absolute w-full h-full"
        style={{ zIndex: 100001 }}
        onClick={toggleDrawer}
      ></div>
      <ExpandedSideNav className="h-full px-2 pb-8 flex flex-col justify-between ">
        <div className="sticky top-0 bg-white pt-6 flex justify-between items-center mb-4 px-2">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
          <Icon.X
            color="#767E85"
            size={48}
            onClick={toggleDrawer}
            className="cursor-pointer"
          />
        </div>
        <div className="flex flex-col justify-between flex-grow">
          {/* Top Section */}
          {isValid ? (
            <div className="mb-96 -ml-2">
              <div className="px-4 mb-4">
                <div className="flex items-center mb-4">
                  <div
                    className="w-10 h-10 rounded-full flex items-center justify-center object-cover"
                    style={{ background: "#EAE7FE" }}
                  >
                    <H5 color="#12046D" className="capitalize">
                      {`${auth?.user?.firstName?.[0]} ${auth?.user?.lastName?.[0]}`}
                    </H5>
                  </div>
                  <div>
                    <TextLink
                      color={theme.primaryFont}
                      fontSize="16px"
                      className="capitalize px-4"
                      fontFamily="semi"
                    >
                      {auth?.user?.firstName + " " + auth?.user?.lastName}
                    </TextLink>
                    <P color={theme.secondaryFont} fontSize="12px" className="px-4">
                      {auth?.user?.email}
                    </P>
                  </div>
                </div>

                <div className="rounded-full border-indigo-800 border-b-2" />
              </div>

              <ExpandedNavLink
                to={`/dashboard`}
                className="flex items-center"
                onClick={toggleDrawer}
                data-testid="dashboard-link"
                activeClassName="active"
                exact
                isActive={(match, location) => {
                  if (
                    location.pathname === "/dashboard" ||
                    location.pathname === "/dashboard/saved-organizations" ||
                    location.pathname === "/dashboard/saved-services" ||
                    location.pathname === "/dashboard/my-referrals"
                  ) {
                    return true;
                  }
                  if (match) {
                    return true;
                  }
                }}
              >
                <li className="flex-grow">Dashboard</li>
              </ExpandedNavLink>

              <ExpandedNavLink
                to={`/dashboard/settings`}
                className="flex items-center"
                onClick={toggleDrawer}
                data-testid="dashboard-link"
                activeClassName="active"
              >
                <li className="flex-grow">Settings</li>
              </ExpandedNavLink>

              <ExpandedNavLink
                to={`/organizations`}
                className="flex items-center"
                onClick={toggleDrawer}
                data-testid="organization-link"
                activeClassName="active"
              >
                <li className="flex-grow">Organizations</li>
              </ExpandedNavLink>
              {/* 
              <ExpandedNavLink
                to={`/suggest-new-entry`}
                className="flex items-center"
                activeClassName="active"
                data-testid="suggest-link"
                onClick={toggleDrawer}
              >
                <li className="flex-grow">Suggest new entry</li>
              </ExpandedNavLink> */}

              <li
                className="flex-grow px-4"
                onClick={() => {
                  auth.logOut();
                  toggleDrawer();
                }}
              >
                <HeaderButton
                  p="12px 24px"
                  w="100%"
                  className="flex items-center justify-center"
                >
                  Log Out
                  <Icon.Power size={16} className="ml-4" />
                </HeaderButton>
              </li>
            </div>
          ) : (
            <div className="mb-96 -ml-2">
              <ExpandedNavLink
                to={`/organizations`}
                className="flex items-center"
                onClick={toggleDrawer}
                data-testid="organization-link"
                activeClassName="active"
              >
                <li className="flex-grow">Organizations</li>
              </ExpandedNavLink>

              <ExpandedNavLink
                to={`/suggest-new-entry`}
                className="flex items-center"
                activeClassName="active"
                data-testid="suggest-link"
                onClick={toggleDrawer}
              >
                <li className="flex-grow">Suggest new entry</li>
              </ExpandedNavLink>

              <ExpandedNavLink
                to={`/register`}
                className="flex items-center mt-20"
                activeClassName="active"
                onClick={toggleDrawer}
              >
                <li className="flex-grow">
                  <HeaderButton p="12px 24px" w="100%">
                    Get Started
                  </HeaderButton>
                </li>
              </ExpandedNavLink>
            </div>
          )}
          {/* Bottom Section */}
          {/* <div className="-ml-4">
            <LandingPageFooter hideLogo />
          </div> */}
        </div>
      </ExpandedSideNav>
    </DrawerStyle>
  );
};

export default Drawer;
