import React, { useContext } from "react";
import { themeContext } from "../../../shared/store/contexts";
import { P } from "../../atoms/Texts";
import Rating from "../Rating";

const Review = ({ review }) => {
  const { theme } = useContext(themeContext);
  const { author_name, profile_photo_url, rating, text } = review;
  return (
    <div className="mb-6 lg:w-3/5">
      <div className="flex">
        <img
          alt=""
          src={profile_photo_url}
          className="w-10 h-10 rounded-full mr-4"
        />
        <div>
          <P color={theme.primaryFont} fontFamily="medium" className="mb-2">
            {author_name}
          </P>
          <Rating rating={rating} size={16} />
          {/* <P color={theme.lightFont} fontSize="14px" smallSize="14px">
            {new Date(time).toLocaleString()}
          </P> */}
          <P color={theme.primaryFont} className="mt-2">
            {text}
          </P>
        </div>
      </div>
    </div>
  );
};

export default Review;
