import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import t from "prop-types";
import * as Icon from "react-feather";
import styled from "styled-components";

import { H4, P } from "../../atoms/Texts";
import logo from "../../../assets/images/logo.svg";
import darkLogo from "../../../assets/images/darkLogo.svg";
import { themeContext } from "../../../shared/store/contexts";

const FooterHeader = styled.div`
  border-bottom: 1px solid #e6e5f1;
  > svg {
    transition: all ease 0.3s;
    transform: ${(props) => (props.isExpanded ? "rotate(180deg)" : "rotate(0)")};
  }
`;

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const CollapsibleFooterSection = ({ heading, subLinks, theme }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div className="">
      <FooterHeader
        className="flex justify-between pb-4 mb-6 cursor-pointer"
        isExpanded={expanded}
        onClick={toggleExpanded}
      >
        <H4 color={theme.primaryFont} className="">
          {heading}
        </H4>
        <Icon.ChevronDown size={16} color="#9390B0" />
      </FooterHeader>
      {expanded && (
        <>
          {subLinks.map((link, idx) => (
            <React.Fragment key={idx}>
              {link.external ? (
                <a
                  key={idx}
                  href={link.to}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="block mb-4"
                >
                  <P fontSize="16px" color={theme.secondaryFont}>
                    {link.name}
                  </P>
                </a>
              ) : (
                <Link key={idx} to={link.to} className="block mb-4">
                  <P fontSize="16px" color={theme.secondaryFont}>
                    {link.name}
                  </P>
                </Link>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

const FooterSection = ({ heading, subLinks, theme }) => {
  return (
    <div className="mt-10 lg:mt-0 lg:mr-16">
      <H4 className="mb-6" color={theme.primaryFont}>
        {heading}
      </H4>
      {subLinks.map((link, idx) => (
        <React.Fragment key={idx}>
          {link.external ? (
            <a
              key={idx}
              href={link.to}
              target="_blank"
              rel="noreferrer noopener"
              className="block mb-4"
            >
              <P fontSize="16px" color={theme.secondaryFont}>
                {link.name}
              </P>
            </a>
          ) : (
            <Link key={idx} to={link.to} className="block mb-4">
              <P fontSize="16px" color={theme.secondaryFont}>
                {link.name}
              </P>
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

FooterSection.propTypes = {
  heading: t.string.isRequired,
  subLinks: t.array,
};

export const LandingPageFooter = ({ hideLogo }) => {
  const { theme, isDark } = useContext(themeContext);

  return (
    <footer className="container mx-auto">
      <div className="space-y-10 md:space-y-0 hidden lg:flex justify-between">
        {/* LOGO */}
        {!hideLogo && (
          <div>
            {!isDark ? (
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            ) : (
              <Link to="/">
                <img src={darkLogo} alt="" />
              </Link>
            )}
          </div>
        )}
        {/* Product */}
        <div>
          <div className="lg:flex">
            <FooterSection
              heading="Products"
              theme={theme}
              subLinks={[
                {
                  name: "SSN for Organization",
                  to: "https://organizations.socialsafety.net/",
                  external: true,
                },
                {
                  name: "Datalabs",
                  to: "https://datalabs.socialsafety.net",
                  external: true,
                },
                {
                  name: "API Documentation",
                  to: "https://docs.socialsafety.net/ssn-api/getting-started",
                  external: true,
                },
              ]}
            />
            <FooterSection
              heading="Company"
              theme={theme}
              subLinks={[
                {
                  name: "About us",
                  to: "https://about.socialsafety.net",
                  external: true,
                },
                {
                  name: "Contact Us",
                  to: "/contact",
                },
                {
                  name: "Philanthrolab.co",
                  to: "https://philanthrolab.co",
                  external: true,
                },
              ]}
            />
            <FooterSection
              heading="Legal"
              theme={theme}
              subLinks={[
                {
                  name: "Terms of Service",
                  to: "/terms",
                },
                {
                  name: "Privacy Policy",
                  to: "/privacy",
                },
              ]}
            />
            {/* <FooterSection
              heading="Community"
              theme={theme}
              subLinks={[
                {
                  name: "Blog",
                  to: "/blog",
                  external: true,
                },
                {
                  name: "Newsletter",
                  to: "/newsletter",
                  external: true,
                },
                {
                  name: "Twitter",
                  to: "https://twitter.com",
                  external: true,
                },
                {
                  name: "LinkedIn",
                  to: "https://linkedin.com",
                  external: true,
                },
                {
                  name: "GitHub",
                  to: "https://github.com",
                  external: true,
                },
                {
                  name: "YouTube",
                  to: "https://youtube.com",
                  external: true,
                },
              ]}
            /> */}
          </div>
        </div>
      </div>
      <div className="space-y-10 md:space-y-0 lg:hidden">
        {/* LOGO */}
        {!hideLogo && (
          <div>
            {!isDark ? (
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            ) : (
              <Link to="/">
                <img src={darkLogo} alt="" />
              </Link>
            )}
          </div>
        )}
        {/* Product */}
        <div>
          <div className="lg:flex">
            <CollapsibleFooterSection
              heading="Products"
              theme={theme}
              subLinks={[
                {
                  name: "SSN for Organization",
                  to: "/developers",
                },
                {
                  name: "Datalabs",
                  to: "https://datalabs.socialsafety.net",
                  external: true,
                },
                {
                  name: "API Documentation",
                  to: "https://docs.socialsafety.net/ssn-api/getting-started",
                  external: true,
                },
              ]}
            />
            <CollapsibleFooterSection
              heading="Company"
              theme={theme}
              subLinks={[
                {
                  name: "About us",
                  to: "https://about.socialsafety.net",
                  external: true,
                },
                {
                  name: "Contact Us",
                  to: "/contact",
                },
                {
                  name: "Philanthrolab.co",
                  to: "https://philanthrolab.co",
                  external: true,
                },
              ]}
            />
            <CollapsibleFooterSection
              heading="Legal"
              theme={theme}
              subLinks={[
                {
                  name: "Terms of Service",
                  to: "/terms",
                },
                {
                  name: "Privacy Policy",
                  to: "/privacy",
                },
              ]}
            />
            {/* <CollapsibleFooterSection
              heading="Community"
              theme={theme}
              subLinks={[
                {
                  name: "Blog",
                  to: "/blog",
                  external: true,
                },
                {
                  name: "Newsletter",
                  to: "/newsletter",
                  external: true,
                },
                {
                  name: "Twitter",
                  to: "https://twitter.com",
                  external: true,
                },
                {
                  name: "LinkedIn",
                  to: "https://linkedin.com",
                  external: true,
                },
                {
                  name: "GitHub",
                  to: "https://github.com",
                  external: true,
                },
                {
                  name: "YouTube",
                  to: "https://youtube.com",
                  external: true,
                },
              ]}
            /> */}
          </div>
        </div>
      </div>
      <P
        className="text-center mt-10 mb-5"
        color={theme.secondaryFont}
        fontSize="16px"
      >
        Copyright © Philanthrolab {getCurrentYear()}, All rights reserved.
      </P>
    </footer>
  );
};
