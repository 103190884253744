import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useOrganization from "../../../shared/hooks/useOrganization";

import { themeContext } from "../../../shared/store/contexts";
import { LinkButton, OutlineButton } from "../../atoms/Buttons";
import { H3 } from "../../atoms/Texts";
import ExpandableDescription from "../../molecules/ExpandableDescription";

const SavedOrganizationCardStyle = styled.div`
  background: ${(props) => props.bg};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

const SavedOrganizationCard = ({ organization }) => {
  const { theme } = useContext(themeContext);

  // Remove organization from favourites
  const { removeOrganizationFromFavorites } = useOrganization();

  const onRemoveFromFavourites = () => {
    removeOrganizationFromFavorites({
      orgId: organization.orgId,
    });
  };

  return (
    <SavedOrganizationCardStyle
      bg={theme.background}
      className="p-5 h-full flex flex-col justify-between"
    >
      <H3
        fontSize="18px"
        smallSize="18px"
        color={theme.primaryFont}
        className="mb-5"
        fontFamily="medium"
      >
        {organization?.name}
      </H3>
      <ExpandableDescription
        ending="..."
        fontSize="14px"
        hideMoreOption
        length={70}
        text={organization?.description}
        text_color="#716E8B"
      />
      <div className="flex items-center justify-between mt-6">
        <Link to={`/organization/details/${organization?.orgId}`} className="block">
          <OutlineButton color={theme.purpleFont}>View</OutlineButton>
        </Link>
        <LinkButton color={theme.primaryFont} onClick={onRemoveFromFavourites}>
          Remove
        </LinkButton>
      </div>
    </SavedOrganizationCardStyle>
  );
};

export default SavedOrganizationCard;
