import React from "react";
import { PrimaryButton } from "../../atoms/Buttons";

import loadIcon from "../../../assets/images/tail-spin.svg";

import { P } from "../../atoms/Texts";
import ModalHolder from "../../molecules/ModalHolder";

const DeleteAccountModal = ({ closeModal, confirmDelete, deleting }) => {
  return (
    <ModalHolder closeModal={closeModal} header="Delete Account">
      <section className="py-4 md:py-6">
        <P className="mb-4">
          Deleting your account is irreversible, are you certain of this action?
        </P>
        <PrimaryButton.Danger disabled={deleting} onClick={confirmDelete}>
          {!deleting ? (
            "Delete Account"
          ) : (
            <img src={loadIcon} alt="Loading" className="mx-auto h-6 w-6" />
          )}
        </PrimaryButton.Danger>
      </section>
    </ModalHolder>
  );
};

export default DeleteAccountModal;
