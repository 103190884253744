import React, { useContext, useEffect, useState } from "react";

import * as Icon from "react-feather";
import { themeContext } from "../../../shared/store/contexts/context.theme";
import { P } from "../../atoms/Texts";

import useReferrals from "../../../shared/hooks/useReferrals";
import ReferralsNavBar from "../../molecules/ReferralsNavBar";
import { authContext } from "../../../shared/store/contexts";
import { CustomSwitch } from "../../../shared/routing";
import { Route } from "react-router";
import { Pill } from "../../atoms/Badges/badge.ssn";
import pillStylesEnum from "../../../shared/fixtures/pillStylesEnum";
import { Link } from "react-router-dom";
import { Table } from "../../organisms/Table";

const More = ({ serviceId }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleDropDown = () => setShowMore(!showMore);

  const checkIfNotInDropDown = ({ target }) => {
    if (!target.classList.contains("more-dropdown-child")) {
      toggleDropDown();
    }
  };

  useEffect(() => {
    if (showMore) {
      window.addEventListener("click", checkIfNotInDropDown);
    } else {
      window.removeEventListener("click", checkIfNotInDropDown);
    }

    return () => {
      window.removeEventListener("click", checkIfNotInDropDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMore]);

  return (
    <div className="relative">
      <Icon.MoreHorizontal
        size={24}
        color="#C5C7CD"
        className="cursor-pointer"
        onClick={toggleDropDown}
      />

      {showMore && (
        <div className="absolute z-50 top-3 right-0 bg-white shadow py-6 px-4 more-dropdown-child ">
          <Link
            target="_blank"
            to={`/service/details/${serviceId}`}
            // to={`/dashboard/${orgId}/referrals`}
            // to={
            //   // pathname: `https://organizations.socialsafety.net/dashboard/organization/${orgId}/referrals${id}`,
            //   // pathname: `/service/details/${service?.serviceId}`,
            // }
            className="flex items-center whitespace-no-wrap"
          >
            <P className="whitespace-no-wrap flex-shrink-0 more-dropdown-child"></P>
            View Service <Icon.ExternalLink className="ml-2 " size={16} />
          </Link>
        </div>
      )}
    </div>
  );
};

const AllUserReferrals = ({ from, status }) => {
  const { auth } = useContext(authContext);
  const { theme } = useContext(themeContext);
  const { allUserReferrals, isGettingAllUserReferrals, getAllUserReferrals } =
    useReferrals();

  // Get user referrals
  const [received, setReceived] = useState(null);
  const [sent, setSent] = useState(null);

  useEffect(() => {
    setReceived(allUserReferrals?.filter((r) => r.recipientID === auth?.user?.id));
    setSent(allUserReferrals?.filter((r) => r.recipientID !== auth?.user?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserReferrals]);

  // console.log(sent);

  useEffect(() => {
    if (auth?.user?.id) {
      getAllUserReferrals(auth?.user?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.id]);

  return (
    <section className="mt-6 pb-10">
      <ReferralsNavBar theme={theme} received={received} sent={sent} />

      {isGettingAllUserReferrals ? (
        <>
          <div className="mt-10 mb-8">
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
          </div>
          <div className="mb-8">
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
          </div>
        </>
      ) : (
        <>
          <section className="mt-10">
            <CustomSwitch>
              <Route path="/dashboard/my-referrals/sent">
                <Table
                  loading={isGettingAllUserReferrals}
                  data={sent}
                  columns={columns}
                />
              </Route>
              <Route
                exact
                path={[
                  "/dashboard/my-referrals",
                  "/dashboard/my-referrals/received",
                ]}
              >
                <Table
                  loading={isGettingAllUserReferrals}
                  data={received}
                  columns={columns}
                />
              </Route>
            </CustomSwitch>
          </section>
        </>
      )}
    </section>
  );
};

const columns = [
  {
    Header: "No",
    Cell: ({ row }) => (
      <P color="#252733" className=" flex items-center">
        {row.index + 1}
      </P>
    ),
  },
  {
    accessor: "serviceName",
    Header: "Service Name",
    Cell: ({ value }) => (
      <P color="#252733" className="flex items-center">
        {value}
        {/* <Link to={`/dashboard/customer-detail/${value}`}>
          <span className="sm:text-sm ml-1 inline-block">
            <GoLinkExternal />
          </span>
        </Link> */}
      </P>
    ),
  },
  {
    accessor: "from",
    Header: "Referral Source",
    Cell: ({ value }) => (
      <Pill
        color={pillStylesEnum[value?.toUpperCase()]?.color}
        bg={pillStylesEnum[value?.toUpperCase()]?.bg}
        fontSize="12px"
      >
        {value?.toUpperCase()}
      </Pill>
    ),
  },
  {
    accessor: "eligibilityStatus",
    Header: "Eligibility Status",
    Cell: ({ value }) => (
      <Pill
        fontSize="12px"
        className="uppercase"
        style={{
          color: value ? "#47C362" : "#E82424",
          backgroundColor: value ? "#47C362" : "#FFF4F7",
        }}
      >
        {value ? "Yes" : "No"}
      </Pill>
    ),
  },
  {
    accessor: "status",
    Header: "Status",
    Cell: ({ value }) => (
      <Pill
        color={pillStylesEnum[value]?.color}
        bg={pillStylesEnum[value]?.bg}
        fontSize="12px"
        className="uppercase"
        fontFamily="semi"
      >
        {value}
      </Pill>
    ),
  },
  {
    accessor: "serviceId",
    Header: "",
    Cell: ({ value }) => (
      <span className="">
        <More serviceId={value} />
      </span>
    ),
  },
];

export default AllUserReferrals;
