import { gql } from "@apollo/client";

export const PROFILEUPDATE_MUTATION = gql`
  mutation updateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      firstName
      lastName
      country
      avatarUrl
      isDeleted
      email
      gender
      employmentStatus
      disability
      dob
      housingStatus
      incomeLevel
      insurance
      household
      createdAt
      updatedAt
      phone
      ext
      verified
    }
  }
`;
