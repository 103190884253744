import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useMutation } from "@apollo/client";

import loadIcon from "../../../assets/images/tail-spin.svg";

import ModalHolder from "../../molecules/ModalHolder";

import { SUGGEST_EDIT_MUTATION } from "../../../shared/queries";
import { toastError, toastSuccess } from "../../../shared/helpers";
import { authContext } from "../../../shared/store/contexts";
import { DarkFormField, DarkTextArea } from "../../atoms/Inputs";
import { P } from "../../atoms/Texts";
import { PrimaryButton } from "../../atoms/Buttons";

const SuggestServiceEditModal = ({ closeModal, service }) => {
  const { auth } = useContext(authContext);

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  const [loading, setLoading] = useState(false);

  // Save A Service Mutation

  const [suggestEdit] = useMutation(SUGGEST_EDIT_MUTATION);

  const onSuggestEdit = async (data) => {
    setLoading(true);

    suggestEdit({
      variables: {
        data: {
          email: data.email,
          message: data.message,
          name: data.name,
          serviceId: service.id,
        },
      },
    })
      .then((data) => {
        auth.updateAuth({
          user: {
            ...auth.user,
            edits: data.data.suggestEdit.edits,
          },
        });
        toastSuccess("Suggestion submitted successfully.");
        setLoading(false);
        closeModal();
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
      });
  };

  return (
    <ModalHolder closeModal={closeModal} header="Suggest Edit">
      <form onSubmit={handleSubmit(onSuggestEdit)} className="py-10">
        <DarkFormField
          type="text"
          id="name"
          name="name"
          ref={register({
            required: "Please enter your full name.",
            minLength: {
              value: 2,
              message: "Please enter your full name",
            },
          })}
          placeholder="John Doe"
          label="Name"
          className="w-full"
        />

        <ErrorMessage
          errors={errors}
          name="name"
          as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
        >
          {({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <P key={type}>{message}</P>
            ))
          }
        </ErrorMessage>

        <DarkFormField
          type="email"
          id="email"
          name="email"
          ref={register({
            required: "Please enter your email.",
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: "Please enter a valid email.",
            },
          })}
          placeholder="example@mail.com"
          label="Email"
          className="w-full"
        />

        <ErrorMessage
          errors={errors}
          name="email"
          as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
        >
          {({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <P key={type}>{message}</P>
            ))
          }
        </ErrorMessage>

        <DarkTextArea
          id="message"
          name="message"
          ref={register({
            required: "Please enter your suggestion.",
            minLength: {
              value: 5,
              message: "Please enter a valid suggestion",
            },
          })}
          placeholder="Your suggestion..."
          label="Message"
          rows={6}
          className="w-full"
        />

        <ErrorMessage
          errors={errors}
          name="message"
          as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
        >
          {({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <P key={type}>{message}</P>
            ))
          }
        </ErrorMessage>

        <div className="flex justify-end">
          <PrimaryButton disabled={loading}>
            {!loading ? (
              "Send"
            ) : (
              <img src={loadIcon} alt="Loading" className="mx-auto h-6 w-6" />
            )}
          </PrimaryButton>
        </div>
      </form>
    </ModalHolder>
  );
};

export default SuggestServiceEditModal;
