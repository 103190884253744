import React, { useState, useEffect, useContext, Fragment } from "react";
import styled from "styled-components";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import * as Icon from "react-feather";

import { themeContext } from "../../../shared/store/contexts/context.theme";
import { Layout } from "../../organisms/Layouts";
import { P, H2, H3 } from "../../atoms/Texts";

import { useQuery } from "@apollo/client";
import { GET_ORGANIZATION_BY_ID } from "../../../shared/queries";
import { SmallEditIcon, SmallShareIcon } from "../../atoms/CustomIcons";
import { Badge, TabButton } from "../../atoms/Buttons";
import { decodeToken, triggerShare } from "../../../shared/helpers";
import useOrganization from "../../../shared/hooks/useOrganization";
import { authContext } from "../../../shared/store/contexts";
import Rating from "../../molecules/Rating";
import Review from "../../molecules/Review";
import SuggestOrganizationEditModal from "../../organisms/SuggestOrganizationEditModal";
import ShareOrganizationModal from "../../organisms/ShareOrganizationModal";
import ServiceCard from "../../organisms/ServiceCard";

const Main = styled.main`
  min-height: 100vh;
  max-width: 100vw;
`;

const SpecificOrganizationPage = () => {
  const { id, zipcode } = useParams();
  const { theme } = useContext(themeContext);
  const { auth } = useContext(authContext);
  let history = useHistory();

  const [organization, setOrganization] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  // Organization hook
  const {
    addOrganizationToFavourites,
    getSavedOrganizations,
    isOrganizationSaved,
    organizationError,
    removeOrganizationFromFavorites,
  } = useOrganization();

  // Get all saved organizations
  useEffect(() => {
    getSavedOrganizations(auth?.user?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.id]);

  // Save Organization
  const onSaveOrganization = () => {
    if (!decodeToken(auth.user.id)) {
      setRedirectToLogin(true);
      return;
    }
    setIsSaved(true);

    addOrganizationToFavourites({
      name: organization.name,
      description: organization.description,
      orgId: organization.id,
    });
  };

  // Remove service from favourites
  const removeFromFavourites = () => {
    if (!decodeToken(auth.user.id)) {
      setRedirectToLogin(true);
      return;
    }
    setIsSaved(false);

    removeOrganizationFromFavorites({
      userId: auth?.user?.id,
      orgId: id,
    });
    // setIsSaved(isOrganizationSaved(id));
  };

  // Check if Organization is saved
  useEffect(() => {
    setIsSaved(isOrganizationSaved(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.savedOrganizations]);

  // Show error
  useEffect(() => {
    setIsSaved(isOrganizationSaved(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationError]);

  // Get organization query
  const { data, loading, errors } = useQuery(GET_ORGANIZATION_BY_ID, {
    variables: {
      orgId: id,
      zipCode: Number(zipcode),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setOrganization(data?.searchOrganizationById);
    if (data?.searchOrganizationById?.reviews) {
      setReviews(JSON.parse(data?.searchOrganizationById?.reviews));
    }
    if (data?.searchOrganizationById?.locations?.[0]?.schedule) {
      setSchedule(
        JSON.parse(data?.searchOrganizationById?.locations?.[0]?.schedule),
      );
    }
    return () => {
      setOrganization({});
      setReviews([]);
    };
  }, [data]);

  useEffect(() => {
    if (errors) console.log(errors);
  }, [errors]);

  const handleShare = () => {
    // const shared = triggerShare({
    //   text: organization.name,
    //   title: "Social Safety Services",
    //   url: history.location.pathname,
    // });
    // console.log(history);
    setShowShareModal(true);
  };

  // Suggest Service Edit
  const onSuggestEdit = () => {
    if (!decodeToken(auth.user.id)) {
      setRedirectToLogin(true);
      return;
    }
    setShowEditModal(true);
  };

  // Share for mobile
  const mobileShare = () => {
    triggerShare({
      text: organization.name,
      title: "Social Safety Services",
      url: history.location.pathname,
    });
  };

  return (
    <Layout>
      {/* Redirect to Login if triggered */}
      {redirectToLogin && (
        <Redirect
          push
          to={{
            pathname: "/login",
            state: { from: history.location },
          }}
        />
      )}
      <Main className="container mx-auto">
        <section className=" pt-6 pb-10 md:py-24">
          {loading ? (
            <>
              <div className="mb-8">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
              </div>
            </>
          ) : (
            <>
              {organization?.id && (
                <>
                  <H2 fontSize="32px" color={theme.primaryFont} className="">
                    {organization.name}
                  </H2>
                  <P color={theme.lightFont} className="mb-4">
                    {organization.tagline}
                  </P>
                  {organization.locations?.[0]?.distance !== null &&
                    organization.locations?.[0]?.distance !== undefined && (
                      <>
                        <div>
                          <P
                            fontSize="14px"
                            color={theme.secondaryFont}
                            className="mb-6"
                          >
                            {`${organization.locations?.[0]?.distance}`} miles away
                            from you.
                          </P>
                        </div>
                      </>
                    )}

                  <div className="md:flex justify-between">
                    <div className="lg:w-2/3">
                      <P color={theme.primaryFont}>{organization?.description}</P>
                    </div>
                    <div
                      className="lg:w-1/3 lg:pl-8 md:border-l mt-4 md:mt-0"
                      style={{
                        borderLeftColor: "#E6E5F1",
                        height: "fit-content",
                      }}
                    >
                      {schedule?.weekday_text?.length > 0 && (
                        <P
                          color={
                            schedule?.open_now ? theme.greenFont : theme.redFont
                          }
                          className="mb-4"
                        >
                          {schedule?.open_now ? "Open Now" : "Closed Now"}
                        </P>
                      )}
                      {organization.rating && (
                        <>
                          <P
                            className="mb-2"
                            color={theme.primaryFont}
                            fontFamily="semi"
                          >
                            Rating:
                          </P>
                          <Rating rating={organization.rating} />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center my-6">
                    <TabButton
                      className="flex items-center mr-4"
                      onClick={isSaved ? removeFromFavourites : onSaveOrganization}
                    >
                      <Icon.Star
                        color={theme.purpleFont}
                        fill={isSaved ? theme.purpleFont : "none"}
                        size={18}
                        className="md:mr-2"
                      />
                      <span className="hidden md:block">
                        {isSaved ? "Saved" : "Save"}
                      </span>
                    </TabButton>
                    {/* Desktop Share */}
                    <TabButton
                      className="hidden lg:flex items-center mr-4"
                      onClick={handleShare}
                    >
                      <SmallShareIcon className="md:mr-2" />
                      <span className="hidden md:block">Share</span>
                    </TabButton>
                    {/* Mobile Share */}
                    <TabButton
                      className="flex lg:hidden items-center mr-4"
                      onClick={mobileShare}
                    >
                      <SmallShareIcon className="md:mr-2" />
                      <span className="hidden md:block">Share</span>
                    </TabButton>
                    <TabButton
                      className="flex items-center mr-4"
                      onClick={onSuggestEdit}
                    >
                      <SmallEditIcon className="md:mr-2" />
                      <span className="hidden md:block">Suggest edits</span>
                    </TabButton>
                    <a
                      href={`https://organizations.socialsafety.net/dashboard/claim-specific-organization/${id}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <TabButton className="flex items-center mr-4">
                        <Icon.Package
                          color="#442ed0"
                          size={16}
                          className="md:mr-2"
                        />
                        <span className="hidden md:block">Claim organization</span>
                      </TabButton>
                    </a>
                  </div>
                  {organization.programs?.length > 0 && (
                    <>
                      <H3 color={theme.primaryFont} className="">
                        Programs
                      </H3>
                      {organization.programs?.map((program, index) => (
                        <div key={index} className="md:flex mb-6">
                          <Link to="/service/details" className="block mr-4">
                            <Badge>{program.name}</Badge>
                          </Link>
                        </div>
                      ))}
                    </>
                  )}
                  {organization.services?.length > 0 && (
                    <>
                      <H3 color={theme.primaryFont} className="mt-8 mb-4">
                        Services
                      </H3>
                      <section className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-10">
                        {organization.services?.map((service, index) => (
                          // <Link
                          //   key={index + service.id}
                          //   to={`/service/details/${service.id}`}
                          //   className="flex items-center mr-6"
                          // >
                          //   <P color="#442ed0" className="underline mr-1">
                          //     {service.name}
                          //   </P>
                          //   <Icon.Link color="#442ed0" size={14} />
                          // </Link>
                          <ServiceCard key={service.id} service={service} />
                        ))}
                      </section>
                    </>
                  )}

                  <H3 color={theme.primaryFont} className="mt-8 mb-4">
                    Contact Details
                  </H3>
                  {organization.email && organization.email !== "None" && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Email
                      </P>
                      <div className="md:w-4/5">
                        <a href={`mailto:${organization.email}`} className="">
                          <P color={theme.purpleFont} className="mb-4 md:mb-0">
                            {organization.email}
                          </P>
                        </a>
                      </div>
                    </div>
                  )}
                  {organization.contacts?.length > 0 && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Contacts
                      </P>
                      <div className="md:w-4/5">
                        {organization.contacts.map((contact, index) => (
                          <Fragment key={index}>
                            {contact.name && (
                              <P
                                color={theme.secondaryFont}
                                key={index}
                                className="mb-2"
                              >
                                {contact.title ? (
                                  contact.title ? (
                                    <span>
                                      {contact.name}, <br />{" "}
                                      <span style={{ fontSize: "14px" }}>
                                        {contact.title}
                                      </span>
                                    </span>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  contact.name
                                )}
                              </P>
                            )}
                            {contact.department && (
                              <P
                                color={theme.secondaryFont}
                                key={index}
                                className="mb-2"
                              >
                                {contact.department},
                              </P>
                            )}
                            {contact.email && (
                              <a
                                href={`mailto:${contact.email}`}
                                className="block"
                                style={{ width: "fit-content" }}
                              >
                                <P color={theme.purpleFont} className="mb-2">
                                  {contact.email}
                                </P>
                              </a>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  )}
                  {organization.locations?.length > 0 && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Locations
                      </P>
                      <div className="md:w-4/5">
                        {organization.locations.map((location, index) => (
                          <React.Fragment key={index}>
                            {location.name && location.name !== "None" && (
                              <P color={theme.secondaryFont} className="mb-2">
                                {location.name}
                              </P>
                            )}

                            {location?.address && (
                              <P color={theme.secondaryFont} className="mb-2">
                                {`${
                                  location?.address && location?.address !== "None"
                                    ? location?.address + ","
                                    : ""
                                }${
                                  location?.city ? " " + location?.city + "," : ""
                                } ${
                                  location?.stateOrProvince &&
                                  location?.stateOrProvince !== "None"
                                    ? location?.stateOrProvince + ","
                                    : ""
                                } ${
                                  location?.postalCode ? location?.postalCode : ""
                                }`}
                              </P>
                            )}
                            {location?.zipCode && location?.zipCode !== "None" && (
                              <P color={theme.secondaryFont} className="mb-2">
                                {location?.zipCode}.
                              </P>
                            )}
                            {location?.country && location?.country !== "None" && (
                              <P color={theme.secondaryFont} className="mb-2">
                                {location?.country}.
                              </P>
                            )}
                            {location?.languages && (
                              <P color={theme.secondaryFont} className="mb-2">
                                Language(s): {location?.languages}.
                              </P>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  )}
                  {organization.phones?.length > 0 &&
                    organization.phones?.[0]?.number !== "None" &&
                    organization.phones?.[0]?.number !== "" && (
                      <div className="md:flex mb-6">
                        <P
                          className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                          color={theme.primaryFont}
                          fontFamily="semi"
                        >
                          Phones
                        </P>
                        <div className="md:w-4/5">
                          {organization.phones.map((phone, index) => (
                            <a
                              key={index}
                              href={`tel:+${phone.extension}${phone.number}`}
                            >
                              <P color={theme.purpleFont} className="mb-2">
                                +({phone.extension}){phone.number}
                              </P>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  {organization.url && organization.url !== "None" && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Visit website
                      </P>
                      <div className="md:w-4/5">
                        <a
                          href={organization.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          // className="block my-5"
                        >
                          <P color={theme.purpleFont} className="mb-4">
                            {organization.url}
                          </P>
                        </a>
                      </div>
                    </div>
                  )}

                  <H3 color={theme.primaryFont} className="mt-8 mb-4">
                    Categorization
                  </H3>
                  {organization.opCategory && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Open Eligibility Category
                      </P>
                      <div className="md:w-4/5">
                        <P
                          color={theme.secondaryFont}
                          className="mb-4 md:mb-0 capitalize"
                        >
                          {organization.opCategory}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.nteeClassification && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        NTEE Classification
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.nteeClassification}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.nteeCode && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        NTEE Code
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.nteeCode}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.nteeType && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        NTEE Type
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.nteeType}
                        </P>
                      </div>
                    </div>
                  )}

                  {schedule?.weekday_text?.length > 0 && (
                    <>
                      <H3 color={theme.primaryFont} className="mt-8 mb-4">
                        Schedule
                      </H3>
                      {schedule?.weekday_text.map((schedule, index) => (
                        <P key={index} className="mb-6" color={theme.primaryFont}>
                          {schedule}
                        </P>
                      ))}
                    </>
                  )}

                  {reviews?.length > 0 && (
                    <>
                      <H3 color={theme.primaryFont} className="mt-8 mb-4">
                        Reviews
                      </H3>
                      {reviews.map((review, index) => (
                        <Review key={index} review={review} />
                      ))}
                    </>
                  )}

                  {organization.ein && (
                    <H3 color={theme.primaryFont} className="mt-8 mb-4">
                      Tax Details
                    </H3>
                  )}
                  {organization.ein && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                        title="Employer Identification Number"
                      >
                        EIN
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.ein}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.deductibility && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Deductibility
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.deductibility}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.deductibilityCode && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Deductibility Code
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.deductibilityCode}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.subsection && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Subsection
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.subsection}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.affiliation && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Affiliation
                      </P>
                      <div className="md:w-2/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.affiliation}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.filingRequirement && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Filing Requirement
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.filingRequirement}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.latest990 && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Latest990
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.latest990}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.foundationStatus && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Foundation Status
                      </P>
                      <div className="md:w-2/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.foundationStatus}
                        </P>
                      </div>
                    </div>
                  )}
                  {organization.exemptOrgStatus && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Exempt Organization Status
                      </P>
                      <div className="md:w-2/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {organization.exemptOrgStatus}
                        </P>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </section>
      </Main>
      {showEditModal && (
        <SuggestOrganizationEditModal
          closeModal={() => setShowEditModal(false)}
          organization={organization}
        />
      )}
      {showShareModal && (
        <ShareOrganizationModal
          closeModal={() => setShowShareModal(false)}
          organization={organization}
        />
      )}
    </Layout>
  );
};

export default SpecificOrganizationPage;
