import { gql } from "@apollo/client";

export const SAVE_SERVICE_MUTATION = gql`
  mutation SaveService($data: saveServiceData!) {
    saveServiceToFavorites(data: $data) {
      id
      serviceId
      userId
      name
      description
      createdAt
    }
  }
`;
