import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import LoadingBar from "react-top-loading-bar";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import eyeIcon from "../../../assets/images/eye.svg";
import eyeOffIcon from "../../../assets/images/eye-off.svg";
import darkLogo from "../../../assets/images/darkLogo.svg";
import loadIcon from "../../../assets/images/tail-spin.svg";

import { toastError } from "../../../shared/helpers";
import { LOGIN_QUERY } from "../../../shared/queries";
import { authContext } from "../../../shared/store/contexts";

import { P, H6, TextLink } from "../../atoms/Texts";
import { DarkFormField, CheckBox } from "../../atoms/Inputs";
import { PrimaryButton } from "../../atoms/Buttons";
import { ThemeLessLayout } from "../../organisms/Layouts";
import { FormCard } from "../../atoms/Cards";

const LoginPage = () => {
  let history = useHistory();

  const [passwordType, setPasswordType] = useState("password");
  const [show, setShow] = useState(false);

  const { auth } = useContext(authContext);

  const [logIn, { data, loading, error }] = useLazyQuery(LOGIN_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const loader = useRef(null);

  const onLogin = (data) => {
    if (loader) {
      loader.current.continuousStart();
    }

    logIn({
      variables: {
        email: data.email,
        password: data.password,
      },
    });
  };

  useEffect(() => {
    if (data?.LoginUser?.token) {
      const user_data = {
        loggedIn: true,
        user: { ...data?.LoginUser?.user },
        token: data?.LoginUser?.token,
        newLogin: true,
      };
      localStorage.setItem("ssn_user", JSON.stringify(user_data));
      auth.updateAuth({
        loggedIn: true,
        user: { ...data?.LoginUser?.user },
        token: data?.LoginUser?.token,
        newLogin: true,
      });
      history.push(
        history?.location?.state?.from?.pathname
          ? history?.location?.state?.from?.pathname
          : "/dashboard",
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      toastError(error.message);
      return loader && loader.current?.complete();
    }
  }, [error]);

  const showPassword = () => {
    if (show) {
      setShow(false);
      setPasswordType("password");
    } else {
      setShow(true);
      setPasswordType("text");
    }
  };

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <ThemeLessLayout className="">
      <LoadingBar height={2} color="#00AF72" ref={loader} />

      <section className="container flex flex-col items-center">
        <Link to="/">
          <img src={darkLogo} alt="SocialSafety" className="mb-12 block" />
        </Link>
        <div className="flex items-center">
          <FormCard
            // pVertical="64px"
            // pHorizontal="56px"
            maxW="500px"
            className="px-4 md:px-10 py-8 md:py-14 mx-auto"
          >
            <H6 color="#22202D" fontSize="18px" className="mb-12">
              Sign in to your Social Safety Account.
            </H6>

            <form onSubmit={handleSubmit(onLogin)}>
              <DarkFormField
                type="email"
                id="email"
                name="email"
                ref={register({
                  required: "Email is required.",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Please enter a valid email.",
                  },
                })}
                placeholder="example@mail.com"
                label="Email"
                className="w-full"
              />

              <ErrorMessage
                errors={errors}
                name="email"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <DarkFormField
                type={passwordType}
                id="password"
                name="password"
                ref={register({
                  required: "Password is required.",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                })}
                icon={!show ? eyeIcon : eyeOffIcon}
                iconClick={showPassword}
                placeholder="Use a strong password"
                label="Password"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="password"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <PrimaryButton className="w-full">
                {!loading ? (
                  "Sign in"
                ) : (
                  <img src={loadIcon} alt="Loading" className="mx-auto h-6 w-6" />
                )}
              </PrimaryButton>

              <div className="flex justify-between mt-4">
                <div className="flex items-center">
                  <CheckBox
                    ref={register()}
                    name="rememberMe"
                    id="rememberMe"
                    className="w-5 h-5"
                  />
                  <label htmlFor="rememberMe">
                    <P
                      fontSize="14px"
                      color="#2A2C3F"
                      className="ml-2 cursor-pointer"
                    >
                      Remember me
                    </P>
                  </label>
                </div>
                <Link to="/forgotPassword">
                  <TextLink color="#442ECF" fontFamily="semi">
                    Forgot Password?
                  </TextLink>
                </Link>
              </div>
            </form>

            <div className="text-center mt-12">
              <Link to="/register">
                <TextLink color="#442ECF" fontFamily="semi">
                  Create an account
                </TextLink>
              </Link>
            </div>
          </FormCard>
        </div>
      </section>
    </ThemeLessLayout>
  );
};

export default LoginPage;
