import React, { useEffect, useContext, useState } from "react";

import eyeIcon from "../../../assets/images/eye.svg";
import eyeOffIcon from "../../../assets/images/eye-off.svg";

import { authContext } from "../../../shared/store/contexts";
import { toastWarning } from "../../../shared/helpers";
import { H6, P } from "../../atoms/Texts";
import { PrimaryButton } from "../../atoms/Buttons";
import ApiKeyTable from "../../organisms/ApiKeyTable";
import useApiKey from "../../../shared/hooks/useApiKey";

const APIKeysPage = () => {
  const { auth } = useContext(authContext);

  const [showAppId, setShowAppId] = useState(false);

  const {
    createApiKey,
    creatingKey,
    myApiKeys,
    getApiKeys,
    fetchingKeys: loading,
  } = useApiKey();

  const checkVerified = () => toastWarning("Please verify your email first");

  const onCreateKey = () => {
    createApiKey();
  };

  useEffect(() => {
    getApiKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="py-6">
        <div className="lg:flex justify-between items-center mb-10">
          <div className="mb-4 lg:mb-0">
            {myApiKeys?.appId && (
              <div className="flex items-center">
                <H6 className="mr-4">App ID:</H6>
                <P className="mr-4">
                  {showAppId ? myApiKeys?.appId : "***** ***** ***** ***** *****"}
                </P>
                <img
                  src={showAppId ? eyeOffIcon : eyeIcon}
                  alt={showAppId ? "hide App Id" : "show App"}
                  onClick={() => setShowAppId(!showAppId)}
                  className="cursor-pointer"
                />
              </div>
            )}
          </div>
          {auth?.user?.verified ? (
            <PrimaryButton className="" onClick={onCreateKey} disabled={creatingKey}>
              {creatingKey ? "Generating" : "Generate new keys"}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className=""
              onClick={checkVerified}
              disabled={creatingKey}
            >
              Generate new keys
            </PrimaryButton>
          )}
        </div>

        <ApiKeyTable
          loading={loading}
          data={myApiKeys?.keys}
          // refetchKeys={refetchKeys}
        />
        {myApiKeys?.keys?.length > 0 && (
          <P color="#1E202A" fontFamily="medium" className="mt-10">
            {myApiKeys?.keys?.length} Developer keys
          </P>
        )}
      </section>
    </>
  );
};

export default APIKeysPage;
