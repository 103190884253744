import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";

import { H5, TextLink } from "../../atoms/Texts";

import navToggleLight from "../../../assets/images/menuLight.svg";
import navToggleDark from "../../../assets/images/menuDark.svg";
import moonIcon from "../../../assets/images/ios-moon.svg";
import sunIcon from "../../../assets/images/sunIcon.svg";
import logo from "../../../assets/images/logo.svg";
import darkLogo from "../../../assets/images/darkLogo.svg";

import { themeContext } from "../../../shared/store/contexts/context.theme";
import { authContext, drawerContext } from "../../../shared/store/contexts";
import { HeaderStyle, MenuToggle, ThemeToggle } from "./styles";
import ProfileDropDown from "../../molecules/ProfileDropdown";

export const LoggedInHeader = () => {
  const { theme, toggleTheme, isDark } = useContext(themeContext);
  const { toggleDrawer } = useContext(drawerContext);
  const { auth } = useContext(authContext);
  const [showDropDown, setShowDropDown] = useState(false);

  const toggleDropDown = () => setShowDropDown(!showDropDown);
  return (
    <HeaderStyle
      bg={theme.background}
      className="container sticky inset-0 mx-auto py-2 md:py-4 flex justify-between items-center"
    >
      {/* LOGO */}
      {!isDark ? (
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      ) : (
        <Link to="/">
          <img src={darkLogo} alt="" />
        </Link>
      )}

      <div className="flex items-center">
        <div className="hidden md:flex items-center ml-10">
          <Link to="/organizations" className="mr-10">
            <TextLink color={theme.primaryFont} fontSize="16px" p="9px 16px">
              Organizations
            </TextLink>
          </Link>
          {/* <Link to="/suggest-new-entry" className="mr-10">
            <TextLink
              color={theme.primaryFont}
              fontSize="16px"
              p="9px 16px"
              className="rounded"
            >
              Suggest new entry
            </TextLink>
          </Link> */}
        </div>

        {/* Right Navbar */}
        <nav className="hidden md:flex justify-between items-center">
          <div className="relative cursor-pointer">
            <div onClick={toggleDropDown} className="flex items-center">
              <div
                className="w-10 h-10 rounded-full flex items-center justify-center object-cover"
                style={{ background: "#EAE7FE" }}
              >
                <H5 color="#12046D" className="capitalize">
                  {`${auth?.user?.firstName?.[0]} ${auth?.user?.lastName?.[0]}`}
                </H5>
              </div>
              <TextLink
                color={theme.primaryFont}
                fontSize="16px"
                className="capitalize mx-4"
              >
                {auth?.user?.firstName + " " + auth?.user?.lastName}
              </TextLink>
              <Icon.ChevronDown size={16} className="" color="#767E85" />
            </div>
            {showDropDown && (
              <ProfileDropDown auth={auth} toggleDropDown={toggleDropDown} />
            )}
          </div>

          <ThemeToggle role="button" className="p-2 ml-5" onClick={toggleTheme}>
            <img src={isDark ? sunIcon : moonIcon} alt="" className="object-cover" />
          </ThemeToggle>
        </nav>
        {/* Mid Navbar */}
      </div>

      {/* Mobile Navbar */}
      <nav className="flex md:hidden  justify-between items-center">
        <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img src={isDark ? sunIcon : moonIcon} alt="" className="object-cover" />
        </ThemeToggle>

        <MenuToggle role="button" className="p-2" onClick={toggleDrawer}>
          <img
            src={isDark ? navToggleLight : navToggleDark}
            alt=""
            className="object-cover"
          />
        </MenuToggle>
      </nav>
    </HeaderStyle>
  );
};
