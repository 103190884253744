import UserSessionManager from "../store/session/session.manager";

const user = JSON.parse(UserSessionManager.getItem("ssn_user"));
const BASE_URL = process.env.REACT_APP_BASE_URI;

export const request = async (method, route, data) => {
  let requestOptions = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  if (method.toLowerCase() !== "GET".toLowerCase()) {
    requestOptions.body = JSON.stringify(data);
  }

  let endpoint = `${BASE_URL}${route}`;

  if (route.includes("https://")) {
    endpoint = route;
  }
  const response = await fetch(endpoint, requestOptions);
  return await response.json();
};

export const authorizedRequest = async (
  method,
  route,
  data,
  token = user?.token,
) => {
  let requestOptions = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  if (method.toLowerCase() !== "GET".toLowerCase()) {
    requestOptions.body = JSON.stringify(data);
  }

  let endpoint = `${BASE_URL}${route}`;

  if (route.includes("https://")) {
    endpoint = route;
  }
  const response = await fetch(endpoint, requestOptions);
  return await response.json();
};

export const requestFormData = async (method, route, data, token = user?.token) => {
  let requestOptions = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  };
  if (method.toLowerCase() !== "GET".toLowerCase()) {
    requestOptions.body = data;
  }

  let endpoint = `${BASE_URL}${route}`;

  if (route.includes("https://")) {
    endpoint = route;
  }
  const response = await fetch(endpoint, requestOptions);
  return await response.json();
};
