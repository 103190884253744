import styled from "styled-components";

export const SelectInput = styled.select`
  height: ${(props) => props.h};
  max-height: ${(props) => props.maxH};
  min-height: ${(props) => props.minH};
  width: ${(props) => props.w};
  max-width: ${(props) => props.maxW};
  min-width: ${(props) => props.minW};
  border: none;
  color: ${(props) => props.color};
  background: ${(props) => props.bg || "transparent"};
  padding: ${(props) => props.p};
  font-family: ${(props) => props.fontFamily || "medium"};
  font-size: ${(props) => props.fontSize};
  transition: 0.3s ease-out;

  option {
    background: #f8f6fe;
    color: #232323;
  }

  border: none;
  border-bottom: 2px solid ${(props) => props.borderColor || props.color};

  &:placeholder {
    color: #26262645;
  }

  &:focus {
    outline: none;
  }

  &:-internal-autofill-selected {
    color: ${(props) => props.color} !important;
    font: ${(props) => props.fontFamily || "medium"} !important;
  }

  @media (max-width: 600px) {
    height: fit-content;
    font-size: ${(props) => props.smallSize || "16px"};
    padding: 20px 5px 10px;

    option {
      font-size: ${(props) => props.smallSize || "16px"};
    }
  }

  @media (min-width: 600px) {
    height: ${(props) => props.largeH};
  }

  @media (min-width: 1020px) {
    width: ${(props) => props.largeW};
  }
`;
