import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";

import ModalHolder from "../../molecules/ModalHolder";

const ShareServiceModal = ({ closeModal, service }) => {
  return (
    <ModalHolder closeModal={closeModal} header={`Share Service`}>
      <section className="py-4 md:py-6 flex flex-wrap items-center">
        <FacebookShareButton
          url={window.location.href}
          quote={service.name}
          className="mr-2"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton
          url={window.location.href}
          title={service.name}
          className="mr-2"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <TelegramShareButton
          url={window.location.href}
          title={service.name}
          className="mr-2"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <WhatsappShareButton
          url={window.location.href}
          title={service.name}
          separator=":: "
          className="mr-2"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <LinkedinShareButton
          url={window.location.href}
          title={service.name}
          className="mr-2"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <EmailShareButton
          url={window.location.href}
          subject={`SSN Service - ${service.name}`}
          body={service.description}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </section>
    </ModalHolder>
  );
};

export default ShareServiceModal;
