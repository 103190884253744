import React from "react";
import * as Icon from "react-feather";
import ReactRating from "react-rating";

const Rating = ({ rating, size = 24 }) => {
  return (
    <ReactRating
      fractions={2}
      initialRating={rating}
      readonly
      emptySymbol={<Icon.Star size={size} color="#442ED0" fill="transparent" />}
      fullSymbol={<Icon.Star size={size} color="#442ED0" fill="#442ED0" />}
    />
  );
};

export default Rating;
