import { gql } from "@apollo/client";

export const SAVED_ORGANIZATIONS_QUERY = gql`
  query SavedOrganizations($userId: String!) {
    getAllUserSavedOrganization(userId: $userId) {
      id
      orgId
      userId
      name
      description
      createdAt
    }
  }
`;
