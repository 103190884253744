import styled from "styled-components";

export const DarkFormFieldStyle = styled.div`
  background: ${(props) => props.bg || "#F8F6FE"};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.icon && "space-between"};
  border: 1px solid #f0eefa;
`;

export const DarkFormInput = styled.input`
  color: #47495c;
  font-size: ${(props) => props.fontSize || "16px"};
  background: transparent;
  padding: 8px 16px;

  &:placeholder {
    color: #cecae1;
  }

  &:focus {
    outline: none;
  }
`;

export const DarkFormTextArea = styled.textarea`
  color: #47495c;
  font-size: ${(props) => props.fontSize || "16px"};
  background: transparent;
  padding: 8px 16px;

  &:placeholder {
    color: #cecae1;
  }

  &:focus {
    outline: none;
  }
`;

export const DarkFormSelect = styled.select`
  color: #47495c;
  font-size: ${(props) => props.fontSize || "16px"};
  background: transparent;
  padding: 8px 16px;
  width: fit-content;
  max-width: 100%;

  &:placeholder {
    color: #cecae1;
  }

  &:focus {
    outline: none;
  }
`;

export const SelectToggleStyle = styled.div`
  min-width: 198px;
  height: ${(props) => props.h || "40px"};
  position: relative;
  border: ${(props) => props.border || "1px solid #B4B0D1"};
  border-radius: 3px;
  padding: 10px 20px;
  color: ${(props) => props.color};
  background: ${(props) => props.bg};

  img {
    transition: 0.3s ease;

    &.active {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: #f8f6fe;
  }

  &:focus {
    outline: ${(props) => props.outline || "solid 1px #393649"};
  }

  @media (max-width: 600px) {
    width: ${(props) => props.smallwidth || "100%"};
  }
`;

export const SelectDropdownStyle = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  z-index: 100;
  width: ${(props) => props.w || "273px"};
  border: 1px solid #b4b0d1;
  border-radius: 3px;
  background: ${(props) => props.bg};

  @media (max-width: 600px) {
    width: ${(props) => props.smallwidth || "100%"};
  }
`;

export const SelectOptionStyle = styled.button`
  color: ${(props) => props.color || "#393649"};

  &:hover {
    background: #f8f6fe;
  }
`;
