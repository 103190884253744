import { gql } from "@apollo/client";

export const DELETE_ACCOUNT = gql`
  mutation DeleteUser {
    deleteUser {
      ... on UserNotFound {
        message
      }
      ... on DeletedUserResponse {
        message
      }
    }
  }
`;
