import React from "react";
import styled from "styled-components";

const ThemeLessLayoutStyle = styled.main`
  background: #0d024e;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
`;

export const ThemeLessLayout = (props) => {
  return <ThemeLessLayoutStyle>{props.children}</ThemeLessLayoutStyle>;
};

export const DashboardLayout = styled.main`
  grid-template-columns: 1fr 17fr;
`;
