import styled from "styled-components";

export const CanvasStyle = styled.canvas`
  height: 300px;
  max-width: 100vw;
  width: 100%;
  margin-top: 50px;
  background: ${(props) => props.bg};

  @media (max-width: 600px) {
    height: 78px;
  }
`;
