import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useMutation } from "@apollo/client";
import qs from "query-string";

import dashboardLogo from "../../../assets/images/darkLogo.svg";

import { P, H6 } from "../../atoms/Texts";
import { OutlineButton } from "../../atoms/Buttons";
import { ThemeLessLayout } from "../../organisms/Layouts";
import { FormCard } from "../../atoms/Cards";
import { toastError, toastSuccess } from "../../../shared/helpers";
import { CREATE_REFERRAL_MUTATION } from "../../../shared/queries";

const ReferralAcceptancePage = () => {
  let history = useHistory();
  const location = useLocation();
  const referralQuery = qs.parse(location?.search);

  const { orgId, recipientID, referringUserId, serviceId, serviceName } =
    referralQuery;

  const [isSuccessful, setIsSuccessful] = useState(false);

  const [createReferral, { loading }] = useMutation(CREATE_REFERRAL_MUTATION);

  const loader = useRef(null);

  const onCreateReferral = async () => {
    if (loader) {
      loader.current.continuousStart();
    }

    createReferral({
      variables: {
        data: {
          orgId,
          recipientID,
          referringUserId,
          serviceId,
          serviceName,
        },
      },
    })
      .then((data) => {
        if (data?.data?.createReferral) {
          toastSuccess(data?.data?.createReferral?.message);
          setIsSuccessful(true);
          history.push(`/service/details/${serviceId}`);
          return loader && loader.current?.complete();
        } else {
          throw new Error(data?.errors);
        }
      })
      .catch((error) => {
        console.warn(error);
        toastError(error.message);
        return loader && loader.current?.complete();
      });
  };

  useEffect(() => {
    onCreateReferral();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeLessLayout className="flex">
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <section className="flex flex-col items-center">
        <Link to="/" className="flex">
          <img src={dashboardLogo} alt="SocialSafety" className="mb-12 block" />
        </Link>
        <div className="flex items-center">
          <FormCard className="px-4 md:px-10 py-8 md:py-14 mx-auto" maxW="476px">
            {loading || !isSuccessful ? (
              <H6 color="#22202D" fontSize="18px" className="mb-8">
                Please wait a moment while we complete the referral process for you.
              </H6>
            ) : (
              <H6 color="#22202D" fontSize="18px" className="mb-8">
                Your referral has been created successfully. Congrats.
              </H6>
            )}
            <P color="#5F5D77">
              This usually takes shorter time than it would take you to say
              <em style={{ fontWeight: 700 }}>
                {" "}
                "Sylvester Stallone sold some sly silicon sleeves"
              </em>
            </P>

            {(loading || !isSuccessful) && (
              <P color="#5F5D77" className="mt-4 mb-10">
                You'll be taken to the service page once it is done.
              </P>
            )}

            {!loading && !isSuccessful && (
              <>
                <hr />
                <P color="#5F5D77" className="mt-8 mb-4">
                  If you are not automatically redirected, please click the button
                  below:
                </P>
                <Link to={`/service/details/${serviceId}`}>
                  <OutlineButton
                    className="w-full mt-4 block"
                    color="#442ED0"
                    type="button"
                  >
                    View Service
                  </OutlineButton>
                </Link>
              </>
            )}
          </FormCard>
        </div>
      </section>
    </ThemeLessLayout>
  );
};

export default ReferralAcceptancePage;
