import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Icon from "react-feather";

import { H5, P } from "../../atoms/Texts";

const ProfileDropDownStyle = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background: #fff;
  z-index: 50;
  width: fit-content;
  min-width: 300px;

  .arrow {
    position: absolute;
    z-index: 1;
    top: -10px;
    right: 10px;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
  }

  li.hoverable {
    :hover {
      background: #eee;
      color: #442ed0;
    }
  }

  li {
    list-style-type: none;
  }
`;

const ProfileDropDown = ({ toggleDropDown, auth }) => {
  const checkIfNotInDropDown = ({ target }) => {
    if (!target.classList.contains("profile-dropdown-child")) {
      toggleDropDown();
    }
  };

  useEffect(() => {
    window.addEventListener("click", checkIfNotInDropDown);

    return () => {
      window.removeEventListener("click", checkIfNotInDropDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileDropDownStyle className="shadow-lg rounded-md cursor-default">
      <div className="profile-dropdown-child arrow shadow-lg" />
      <ul className="profile-dropdown-child relative z-10 py-6 px-4 bg-white">
        <li className="profile-dropdown-child p-2 mb-6 flex">
          <div
            className="w-10 h-10 rounded-full flex items-center justify-center object-cover mb-2 profile-dropdown-child mr-4"
            style={{ background: "#EAE7FE" }}
          >
            <H5 color="#12046D" className="capitalize profile-dropdown-child">
              {`${auth?.user?.firstName?.[0]} ${auth?.user?.lastName?.[0]}`}
            </H5>
          </div>
          <div>
            <P
              color="#442ED0"
              fontFamily="medium"
              className="profile-dropdown-child"
            >
              {`${auth?.user?.firstName} ${auth?.user?.lastName}`}
            </P>
            <P color="#575B68" fontSize="12px" className="profile-dropdown-child">
              {auth?.user?.email}
            </P>
          </div>
        </li>
        <Link to="/dashboard">
          <li
            onClick={() => toggleDropDown()}
            className="hoverable duration-300 p-2 cursor-pointer"
          >
            <P className="flex justify-between items-center">Dashboard</P>
          </li>
        </Link>
        <Link to="/dashboard/settings">
          <li
            onClick={() => toggleDropDown()}
            className="profile-dropdown-child hoverable duration-300 p-2 cursor-pointer"
          >
            <P className="profile-dropdown-child flex justify-between items-center">
              Settings
            </P>
          </li>
        </Link>
        <li
          className="profile-dropdown-child hoverable duration-300 p-2 mt-4 cursor-pointer"
          onClick={auth.logOut}
        >
          <P className="profile-dropdown-child flex justify-between items-center">
            Log Out
            <Icon.Power size={16} />
          </P>
        </li>
      </ul>
    </ProfileDropDownStyle>
  );
};

export default ProfileDropDown;
