import { gql } from "@apollo/client";

export const CREATE_API_KEY = gql`
  mutation CreateApiKey {
    createApiKey {
      ... on APIKeys {
        appId
        keys
      }
      ... on APIOutOfBounds {
        message
      }
      ... on PlanExistError {
        message
      }
    }
  }
`;
