import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_API_KEY, DELETE_API_KEYS, FETCH_API_KEYS } from "../queries";
import { authContext } from "../store/contexts";
import { toastError, toastSuccess } from "../helpers";

const useApiKey = () => {
  const { auth } = useContext(authContext);

  // Loading state for getting saved organizations
  const [gettingApiKeys, setGettingApiKeys] = useState(false);
  const [apiKeyError, setApiKeyError] = useState(null);
  // Save An Organization Mutation

  // User's api keys
  const [myApiKeys, setMyApiKeys] = useState([]);

  // Query for getting user's keys
  const [
    _getMyApiKeys,
    { loading: fetchingKeys, data: _apiKeys, error: apiKeysFetchErrors },
  ] = useLazyQuery(FETCH_API_KEYS, { fetchPolicy : "cache-and-network" });

  const getApiKeys = () => {
    // setGettingApiKeys(true);
    _getMyApiKeys();
  };

  useEffect(() => {
    if (_apiKeys?.getApiKeys) {
      auth.updateAuth({
        user: {
          ...auth.user,
          myApiKeys: _apiKeys.getApiKeys,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_apiKeys]);

  useEffect(() => {
    if (apiKeysFetchErrors?.errors) {
      setGettingApiKeys(false);
      // getApiKeys();
      toastError(apiKeysFetchErrors.errors[0].message);
    }
  }, [apiKeysFetchErrors]);

  useEffect(() => {
    setMyApiKeys(auth?.user?.myApiKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.myApiKeys]);

  const createApiKey = () => {
    _createApiKey();
  };

  const [
    _createApiKey,
    { loading: creatingKey, data: keyCreationData, error: keyCreationError },
  ] = useMutation(CREATE_API_KEY);

  useEffect(() => {
    if (keyCreationData) {
      switch (keyCreationData.createApiKey.__typename) {
        case "APIOutOfBounds":
          toastError(keyCreationData.createApiKey.message);
          break;

        case "PlanExistError":
          toastError(keyCreationData.createApiKey.message);
          break;

        default:
          toastSuccess("Api Key created successfully");
          getApiKeys();
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyCreationData]);

  useEffect(() => {
    if (keyCreationError) {
      console.log(keyCreationError);
    }
  }, [keyCreationError]);

  // Mutation for deleting keys
  const [_deleteApiKey] = useMutation(DELETE_API_KEYS);

  const deleteApiKey = (apikey) => {
    setGettingApiKeys(true);

    _deleteApiKey({
      variables: {
        key: apikey,
      },
    })
      .then((response) => {
        if (response?.data?.deleteApiKey) {
          getApiKeys();
          setGettingApiKeys(false);
        } else {
          throw new Error(response?.errors);
        }
      })
      .catch((error) => {
        setGettingApiKeys(false);
        toastError(error.message);
        setApiKeyError(error.message);
        getApiKeys();
      });
  };

  // Methods and Properties from hook
  return {
    apiKeyError,
    createApiKey,
    creatingKey,
    deleteApiKey,
    getApiKeys,
    gettingApiKeys,
    myApiKeys,
    fetchingKeys,
  };
};

export default useApiKey;
