import React from "react";
import styled from "styled-components";
import * as Icon from "react-feather";

import { P } from "../../atoms/Texts";

const ModalHolderStyle = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const ModalContainerStyle = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  width: 40%;
  height: fit-content;

  @media (max-width: 600px) {
    width: 80%;
  }
`;

const ModalHolder = ({ children, closeModal, header }) => {
  return (
    <ModalHolderStyle className="inset-0 fixed flex justify-center pt-20">
      <ModalContainerStyle className="px-4 md:px-10">
        <div
          className="flex items-center justify-between py-4"
          style={{
            borderBottom: "1px solid #DFE0E4",
          }}
        >
          <P color="#000000">{header}</P>
          <Icon.X
            color="#9598A3"
            size={24}
            onClick={closeModal}
            className="cursor-pointer"
          />
        </div>
        {children}
      </ModalContainerStyle>
    </ModalHolderStyle>
  );
};

export default ModalHolder;
