import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";

import * as Icon from "react-feather";

import { P, TextLink } from "../../atoms/Texts";
import { DarkSelectInput } from "../../atoms/Inputs";
import { PrimaryButton } from "../../atoms/Buttons";
import { dirtyValues } from "../../../shared/helpers/util.getDirtyFields";

const ThirdForm = ({ fields, updateFields, changeStep, onSignUp }) => {
  const [dirtyFields, setDirtyFields] = useState({});

  const { errors, handleSubmit, formState, register } = useForm({
    validateCriteriaMode: "all",
    defaultValues: {
      incomeLevel: fields.incomeLevel || "",
      insurance: fields.insurance || "",
      household: fields.household || "",
    },
  });

  useEffect(() => {
    setDirtyFields(formState.dirtyFields);
  }, [formState]);

  const submitDetails = (data) => {
    const updatedFields = dirtyValues(dirtyFields, data);
    // updateFields(updatedFields);
    onSignUp(updatedFields);
    // changeStep(3);
  };

  const goBack = () => {
    changeStep(2);
  };

  return (
    <form onSubmit={handleSubmit(submitDetails)}>
      <div className="w-full mx-auto">
        <div className="mx-auto">
          <div
            className="rounded-sm flex items-center mb-6"
            style={{ backgroundColor: "#FFF4E5", height: "72px" }}
          >
            <P color="#663C02" className="pl-3 font-medium">
              This part of the registration is optional, you only need this when
              applying for services
            </P>
          </div>

          <div>
            <DarkSelectInput
              type="select"
              id="incomeLevel"
              name="incomeLevel"
              color="#F8F6FE"
              className="py-1 px-2"
              label="Income Level"
              ref={register()}
              defaultValue={fields.incomeLevel || ""}
              iconBeforeClass=""
              inputClassName="-ml-3"
            >
              <option disabled value="">
                Select income level
              </option>
              <option value="lowIncome">Low Income</option>
              <option value="highIncome">High Income</option>
              <option value="average">Average</option>
            </DarkSelectInput>
            <ErrorMessage
              errors={errors}
              name="incomeLevel"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkSelectInput
              type="select"
              id="insurance"
              name="insurance"
              color="#F8F6FE"
              className="py-1 px-2"
              label="Insurance"
              ref={register()}
              defaultValue={fields.insurance || ""}
              iconBeforeClass=""
              inputClassName="-ml-3"
            >
              <option disabled value="">
                Select insurance
              </option>
              <option value="insured">Insured</option>
              <option value="underInsured">Under Insured</option>
              <option value="notInsured">Not Insured</option>
            </DarkSelectInput>
            <ErrorMessage
              errors={errors}
              name="insurance"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkSelectInput
              type="select"
              id="household"
              name="household"
              color="#F8F6FE"
              className="py-1 px-2"
              label="Household"
              ref={register()}
              defaultValue={fields.household || ""}
              iconBeforeClass=""
              inputClassName="-ml-3"
            >
              <option disabled value="">
                Select Household
              </option>
              <option value="individual">Individual</option>
              <option value="smallFamily">Small Family</option>
              <option value="largeFamily">Large family</option>
            </DarkSelectInput>
            <ErrorMessage
              errors={errors}
              name="household"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <TextLink className="w-full mb-6 block" color="#716E8B">
            Registering to this website, you accept our
            <Link to="terms" style={{ color: "#442ED0" }}>
              {" "}
              Terms of Use{" "}
            </Link>
            and our
            <Link to="privacy" style={{ color: "#442ED0" }}>
              {" "}
              Privacy Policy
            </Link>
          </TextLink>
        </div>

        <div className="mb-10 mx-auto flex gap-5">
          <div className="w-full">
            <PrimaryButton.Light
              type="button"
              onClick={goBack}
              className="w-full flex items-center"
              color="#000"
            >
              <Icon.ArrowLeft></Icon.ArrowLeft>
              <div className="ml-6">Back</div>
            </PrimaryButton.Light>
          </div>

          <div className="w-full">
            <PrimaryButton type="submit" className="w-full">
              Submit
            </PrimaryButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ThirdForm;
