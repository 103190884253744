import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { decodeToken, decodeTokenWithParam } from "../../../shared/helpers";
import { authContext, notificationContext } from "../../../shared/store/contexts";
import { themeContext } from "../../../shared/store/contexts/context.theme";

import Drawer from "../Drawer";
import { Header, LoggedInHeader } from "../Headers";
import NotificationBar from "../NotificationBar";

const LayoutStyle = styled.main`
  background: ${(props) => props.theme.background};
`;

const HeaderSelector = ({ isValid }) => {
  return isValid ? <LoggedInHeader /> : <Header />;
};

export const Layout = (props) => {
  const { theme } = useContext(themeContext);
  const { auth } = useContext(authContext);
  const { togglePopNotification, notificationMessage, setNotificationMessage } =
    useContext(notificationContext);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (auth && auth.token) {
      setIsValid(decodeTokenWithParam(auth.token));
    } else {
      setIsValid(decodeToken());
    }
  }, [auth]);

  useEffect(() => {
    if (
      isValid &&
      auth &&
      auth?.newLogin &&
      auth?.user?.incompleteFields?.length > 0
    ) {
      setNotificationMessage({
        variant: "error",
        message: `You have ${auth?.user?.incompleteFields?.length} incomplete fields in your profile`,
        customComponent: false,
        action: "View",
        actionLinkHref: "/dashboard/settings/profile",
      });
      togglePopNotification(true);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, isValid]);

  return (
    <LayoutStyle theme={theme}>
      <div className="md:hidden">
        <Drawer />
      </div>

      <HeaderSelector isValid={isValid} />
      <NotificationBar notificationMessage={notificationMessage} />
      {props.children}
    </LayoutStyle>
  );
};
