import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  SAVED_ORGANIZATIONS_QUERY,
  SAVE_ORGANIZATION_MUTATION,
  UNSAVE_ORGANIZATION_MUTATION,
} from "../queries";
import { authContext } from "../store/contexts";
import { toastError } from "../helpers";

const useOrganization = () => {
  const { auth } = useContext(authContext);

  // Loading state for getting saved organizations
  const [gettingSavedOrganizations, setGettingSavedOrganizations] = useState(false);
  const [organizationError, setOrganizationError] = useState(null);
  // Save An Organization Mutation

  const [saveOrganization] = useMutation(SAVE_ORGANIZATION_MUTATION);

  // User's saved organizations
  const [mySavedOrganizations, setMySavedOrganizations] = useState([]);

  useEffect(() => {
    setMySavedOrganizations(auth?.user?.savedOrganizations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.savedOrganizations]);

  const addOrganizationToFavourites = ({
    name,
    description,
    userId = auth?.user?.id,
    orgId,
  }) => {
    saveOrganization({
      variables: {
        data: {
          name,
          description,
          orgId,
          userId,
        },
      },
    })
      .then(() => {
        getSavedOrganizations(auth?.user?.id);
      })
      .catch(() => {
        getSavedOrganizations(auth?.user?.id);
        setOrganizationError("Couldn't save organization, please try again.");
      });
  };

  // Mutation for removing organization from favourites
  const [unSaveOrganization] = useMutation(UNSAVE_ORGANIZATION_MUTATION, {
    onCompleted({ removeOrganizationFromFavorites }) {
      if (removeOrganizationFromFavorites) {
        getSavedOrganizations(auth?.user?.id);
        setGettingSavedOrganizations(false);
      }
    },
  });

  const removeOrganizationFromFavorites = ({ userId = auth?.user?.id, orgId }) => {
    setGettingSavedOrganizations(true);

    unSaveOrganization({
      variables: {
        data: {
          userId,
          orgId,
        },
      },
    })
      .then((response) => {
        if (response?.data?.removeOrganizationFromFavorites) {
          getSavedOrganizations(auth?.user?.id);
          setGettingSavedOrganizations(false);
        } else {
          throw new Error(response?.errors);
        }
      })
      .catch((error) => {
        setOrganizationError(error.message);
        getSavedOrganizations(auth?.user?.id);
        setGettingSavedOrganizations(false);
      });
  };

  // Query for getting saved organizations
  const [savedOrganizations] = useLazyQuery(SAVED_ORGANIZATIONS_QUERY, {
    onCompleted({ getAllUserSavedOrganization }) {
      if (getAllUserSavedOrganization) {
        auth.updateAuth({
          user: {
            ...auth.user,
            savedOrganizations: getAllUserSavedOrganization,
          },
        });
        setGettingSavedOrganizations(false);
      }
    },
    onError({ errors }) {
      if (errors) {
        setGettingSavedOrganizations(false);
        getSavedOrganizations(auth?.user?.id);
        toastError(errors[0].message);
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const getSavedOrganizations = (id = auth?.user?.id) => {
    setGettingSavedOrganizations(true);

    savedOrganizations({
      variables: {
        userId: id,
      },
    });
  };

  // Function for checking if a organization is saved
  const isOrganizationSaved = (organizationId) => {
    const { savedOrganizations } = auth.user;
    return (
      savedOrganizations?.filter(
        (organization) => organization.orgId === organizationId,
      )?.length > 0
    );
  };

  // Methods and Properties from hook
  return {
    addOrganizationToFavourites,
    isOrganizationSaved,
    getSavedOrganizations,
    gettingSavedOrganizations,
    organizationError,
    removeOrganizationFromFavorites,
    savedOrganizations: mySavedOrganizations,
  };
};

export default useOrganization;
