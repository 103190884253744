import React, { useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useMutation } from "@apollo/client";
import qs from "query-string";

import { toastError, toastSuccess } from "../../../shared/helpers";
import check from "../../../assets/images/check.svg";
import darkLogo from "../../../assets/images/darkLogo.svg";
import { P, H6, TextLink, H5 } from "../../atoms/Texts";
import { ThemeLessLayout } from "../../organisms/Layouts";
import { FormCard } from "../../atoms/Cards";
import countries from "../../../shared/fixtures/countries";
import { SIGNUP_MUTATION } from "../../../shared/queries";
import FirstForm from "./signup.first";
import SecondForm from "./signup.second";
import ThirdForm from "./signup.third";

const checklist = [
  {
    heading: "One profile, Mutiple applications",
    body: "Fill out your profile once to apply to multiple services at the same time.",
  },
  {
    heading: "Apply for services",
    body: "With your profile set up, you can instantly apply for any social service on Social Safety net",
  },
  {
    heading: "Share Services",
    body: "Easily refer other people for social services",
  },
  {
    heading: "Save Services",
    body: "Save your favorite service to your dashboard and access it any time",
  },
];

const CheckItem = ({ heading, body }) => (
  <div className="flex mb-6">
    <img src={check} alt="" className="h-6 w-6 mr-4" />
    <div>
      <H5 color="#ffffff" fontSize="18px">
        {heading}
      </H5>
      <P color="#B4B0D1" className="mt-2" fontSize="14px">
        {body}
      </P>
    </div>
  </div>
);

const SignUpPage = () => {
  const [signUp] = useMutation(SIGNUP_MUTATION);

  const loader = useRef(null);
  let history = useHistory();
  const location = useLocation();

  const [activeStep, setActiveStep] = useState(1);
  const changeStep = (step) => setActiveStep(step);

  const [fields, setFields] = useState({});

  const updateFields = (data) => {
    setFields({ ...fields, ...data });
  };

  const referralQuery = qs.parse(location?.search);

  const onSignUp = async (data) => {
    if (loader) {
      loader.current.continuousStart();
    }
    updateFields(data);

    const formData = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      country: fields.country,
      disability: fields.disability,
      password: fields.password,
      // dob: fields.dob,
      email: fields.email,
      employmentStatus: fields.employmentStatus,
      gender: fields.gender,
      housingStatus: fields.housingStatus,
      incomeLevel: fields.incomeLevel,
      insurance: fields.insurance,
      household: fields.household,
      // state: fields.state,
      dob: fields.dob,
      phone: fields.phone,
      ...data,
      // index: "datalabs",
      // role: "writer",
    };

    if (referralQuery?.orgId) {
      formData.orgId = referralQuery?.orgId;
    }

    if (referralQuery?.referringUserId) {
      formData.referringUserId = referralQuery?.referringUserId;
    }

    if (referralQuery?.serviceId) {
      formData.serviceId = referralQuery?.serviceId;
    }

    if (referralQuery?.serviceName) {
      formData.serviceName = referralQuery?.serviceName;
    }

    signUp({
      variables: {
        data: formData,
      },
    })
      .then(() => {
        toastSuccess("User created succesfully");
        if (referralQuery?.orgId) {
          history.push(`/verify/${fields.email}?isReferred=true`);
        } else {
          history.push(`/verify/${fields.email}`);
        }
      })
      .catch((error) => {
        toastError(error.message);
        return loader && loader.current?.complete();
      });
  };

  return (
    <ThemeLessLayout className="flex">
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <section className="flex flex-col items-center container lg:items-start lg:grid lg:grid-cols-2">
        <div className="lg:mx-auto lg:w-3/5 lg:pt-20">
          <Link to="/">
            <img
              src={darkLogo}
              alt="SocialSafety"
              className="mb-12 md:mx-auto lg:mx-0"
            />
          </Link>
          <div className="mt-20 hidden lg:block">
            {checklist.map((item, idx) => (
              <CheckItem key={idx} heading={item.heading} body={item.body} />
            ))}
          </div>
        </div>
        <div className="flex items-center">
          <FormCard className="px-4 md:px-10 py-8 md:py-14 md:mx-auto" maxW="500px">
            <div className="flex">
              <div className="mb-8 mr-10">
                <H6
                  color="#22202D"
                  fontSize="18px"
                  data-testid="create-account-text"
                >
                  Create your Social Safety account
                </H6>
                {referralQuery?.orgId && (
                  <H6
                    color="#22202D"
                    fontSize="18px"
                    data-testid="create-account-text"
                  >
                    to accept your referral
                  </H6>
                )}
              </div>
              <P color="#442ED0" fontSize="18px" className="mb-8">
                <span className="font-extrabold" data-testid="active-step">
                  {activeStep}
                </span>
                <span className="text-xs">/3 steps</span>
              </P>
            </div>

            {activeStep === 1 && (
              <FirstForm
                changeStep={changeStep}
                fields={fields}
                updateFields={updateFields}
                countries={countries}
              />
            )}
            {activeStep === 2 && (
              <SecondForm
                changeStep={changeStep}
                fields={fields}
                updateFields={updateFields}
              />
            )}
            {activeStep === 3 && (
              <ThirdForm
                changeStep={changeStep}
                fields={fields}
                updateFields={updateFields}
                onSignUp={onSignUp}
              />
            )}

            <TextLink
              className="w-full text-center block"
              color="#575B68"
              fontFamily="semi"
            >
              Need some help?
              <span style={{ color: "#442ED0" }}> Chat now</span> or contact us
              <a href="mailto:" style={{ color: "#442ED0" }}>
                {" "}
                via mail
              </a>
            </TextLink>
          </FormCard>
        </div>
      </section>
    </ThemeLessLayout>
  );
};

export default SignUpPage;
