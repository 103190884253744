import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useMutation } from "@apollo/client";
import moment from "moment";

import { P } from "../../atoms/Texts";
import { DarkSelectInput, DarkFormField } from "../../atoms/Inputs";
import { PrimaryButton } from "../../atoms/Buttons";
import { authContext } from "../../../shared/store/contexts";
import { PROFILEUPDATE_MUTATION } from "../../../shared/queries/mutation.profile.update";
import { toastError, toastSuccess } from "../../../shared/helpers";
import { dirtyValues } from "../../../shared/helpers/util.getDirtyFields";
import countries from "../../../shared/fixtures/countries";

const ProfileUpdate = () => {
  const { auth } = useContext(authContext);

  const [dirtyFields, setDirtyFields] = useState({});
  const [userCountry, setUserCountry] = useState(auth?.user?.country);
  const [, setStates] = useState([]);

  const [profileUpdate, { loading }] = useMutation(PROFILEUPDATE_MUTATION);

  const onProfileUpdate = (data) => {
    const updatedFields = dirtyValues(dirtyFields, data);

    profileUpdate({
      variables: {
        data: {
          ...updatedFields,
        },
      },
    })
      .then((response) => {
        if (response?.data?.updateUser) {
          toastSuccess("Profile updated");
          auth.updateAuth({
            user: {
              ...auth.user,
              ...response.data.updateUser,
            },
          });
        } else {
          throw new Error(response.errors);
        }
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const { register, errors, handleSubmit, formState, watch } = useForm({
    validateCriteriaMode: "all",
    defaultValues: {
      firstName: auth?.user?.firstName,
      lastName: auth?.user?.lastName,
      country: auth?.user?.country,
      avatarUrl: auth?.user?.avatarUrl,
      phone: auth?.user?.phone,
      gender: auth?.user?.gender,
      employmentStatus: auth?.user?.employmentStatus,
      disability: auth?.user?.disability,
      dob: auth?.user?.dob,
      housingStatus: auth?.user?.housingStatus,
      incomeLevel: auth?.user?.incomeLevel,
      insurance: auth?.user?.insurance,
      household: auth?.user?.household,
    },
  });

  useEffect(() => {
    if (auth?.user?.country) {
      setUserCountry(auth?.user?.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.country]);

  const selectedCountry = watch("country");

  useEffect(() => {
    setStates(
      countries?.find((country) => country.code3 === selectedCountry)?.states,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, selectedCountry]);

  useEffect(() => {
    setDirtyFields(formState.dirtyFields);
  }, [formState]);

  return (
    <form onSubmit={handleSubmit(onProfileUpdate)} className="mt-10">
      <div className="w-full mt-10 mx-auto">
        <div className="mx-auto">
          <div className="w-full mx-auto lg:mx-0 lg:w-1/2">
            <div>
              <DarkFormField
                type="text"
                id="firstName"
                name="firstName"
                color="#F8F6FE"
                className="py-1 px-2"
                ref={register({
                  required: "Please enter your first name",
                })}
                label="First Name"
                placeholder="Enter your first name"
                defaultValue={auth?.user?.firstName}
                iconBeforeClass=""
                inputClassName="-ml-3"
              />
              <ErrorMessage
                errors={errors}
                name="firstName"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkFormField
                type="text"
                id="lastName"
                name="lastName"
                color="#F8F6FE"
                className="py-1 px-2"
                ref={register({
                  required: "Please enter your last name",
                })}
                label="Last Name"
                placeholder="Enter your last name"
                defaultValue={auth?.user?.lastName}
                iconBeforeClass=""
                inputClassName="-ml-3"
              />
              <ErrorMessage
                errors={errors}
                name="lastName"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkFormField
                type="email"
                id="email"
                name="email"
                w="135px"
                disabled
                placeholder="example@mail.com"
                label="Work Email"
                defaultValue={auth?.user?.email}
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="email"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkSelectInput
                className="py-1 px-2"
                name="country"
                id="country"
                type="select"
                ref={register()}
                label="Country"
                defaultValue={userCountry || ""}
                inputClassName="-ml-3"
              >
                <option value="" disabled>
                  Select country
                </option>
                {countries?.map((_country, idx) => (
                  <option key={idx} value={_country.code3}>
                    {_country.name}
                  </option>
                ))}
              </DarkSelectInput>
              <ErrorMessage
                errors={errors}
                name="country"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkFormField
                type="text"
                id="phone"
                name="phone"
                w="135px"
                ref={register({
                  pattern: {
                    message: "Please enter a valid phone number.",
                  },
                })}
                placeholder="Enter your phone number"
                label="Phone Number"
                defaultValue={auth?.user?.phone}
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="phone"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>
          </div>

          <div
            className="rounded-sm flex items-center lg:w-3/5 mb-4 py-4"
            style={{ backgroundColor: "#FFF4E5" }}
          >
            <P color="#663C02" className="pl-3 font-medium">
              This part of the registration is optional, you only need this when
              applying for services
            </P>
          </div>

          <div className="lg:mx-0 lg:w-1/2">
            {/* <div>
              <DarkSelectInput
                type="select"
                id="gender"
                name="gender"
                color="#F8F6FE"
                className="py-1 px-2"
                label="Gender"
                ref={register()}
                defaultValue={auth?.user?.gender || ""}
                iconBeforeClass=""
                inputClassName="-ml-3"
              >
                <option value="" disabled>
                  Select your gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="not homeless">Prefer not to say</option>
              </DarkSelectInput>
              <ErrorMessage
                errors={errors}
                name="gender"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div> */}

            <div>
              <DarkSelectInput
                type="select"
                id="employmentStatus"
                name="employmentStatus"
                color="#F8F6FE"
                className="py-1 px-2"
                ref={register()}
                label="Employment Status"
                defaultValue={auth?.user?.employmentStatus || ""}
                iconBeforeClass=""
                inputClassName="-ml-3"
              >
                <option disabled value="">
                  Select employment status
                </option>
                <option value="employed">Employed</option>
                <option value="unemployed">Unemployed</option>
              </DarkSelectInput>
              <ErrorMessage
                errors={errors}
                name="employmentStatus"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkFormField
                type="text"
                id="disability"
                name="disability"
                color="#F8F6FE"
                className="py-1 px-2"
                label="Disability:"
                ref={register()}
                defaultValue={auth?.user?.disability || ""}
                iconBeforeClass=""
                inputClassName="-ml-3"
                placeholder="Enter disability, if any"
              ></DarkFormField>
              <ErrorMessage
                errors={errors}
                name="disability"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkFormField
                type="date"
                id="dob"
                name="dob"
                color="#F8F6FE"
                className="py-1 px-2"
                ref={register({
                  validate: (value) =>
                    value === "" ||
                    moment(value).isSameOrBefore(moment()) ||
                    "Enter a date of birth before today",
                })}
                label="Date of Birth"
                placeholder="dd-mm-yyyy"
                defaultValue={auth?.user?.dob}
                iconBeforeClass=""
                inputClassName="-ml-3"
              />
              <ErrorMessage
                errors={errors}
                name="dob"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkSelectInput
                type="select"
                id="housingStatus"
                name="housingStatus"
                color="#F8F6FE"
                className="py-1 px-2"
                label="Housing Status"
                ref={register()}
                placeholder="Enter your first name"
                defaultValue={auth?.user?.housingStatus || ""}
                iconBeforeClass=""
                inputClassName="-ml-3"
              >
                <option disabled value="">
                  Select housing status
                </option>
                <option value="homeless">Homeless</option>
                <option value="nearHomeless">Near Homeless</option>
                <option value="notHomeless">Not Homeless</option>
              </DarkSelectInput>
              <ErrorMessage
                errors={errors}
                name="housingStatus"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkSelectInput
                type="select"
                id="incomeLevel"
                name="incomeLevel"
                color="#F8F6FE"
                className="py-1 px-2"
                label="Income Level"
                ref={register()}
                defaultValue={auth?.user?.incomeLevel || ""}
                iconBeforeClass=""
                inputClassName="-ml-3"
              >
                <option disabled value="">
                  Select income level
                </option>
                <option value="lowIncome">Low Income</option>
                <option value="highIncome">High Income</option>
                <option value="average">Average</option>
              </DarkSelectInput>
              <ErrorMessage
                errors={errors}
                name="incomeLevel"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkSelectInput
                type="select"
                id="insurance"
                name="insurance"
                color="#F8F6FE"
                className="py-1 px-2"
                label="Insurance"
                ref={register()}
                defaultValue={auth?.user?.insurance || ""}
                iconBeforeClass=""
                inputClassName="-ml-3"
              >
                <option disabled value="">
                  Select insurance
                </option>
                <option value="insured">Insured</option>
                <option value="underInsured">Under Insured</option>
                <option value="notInsured">Not Insured</option>
              </DarkSelectInput>
              <ErrorMessage
                errors={errors}
                name="insurance"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkSelectInput
                type="select"
                id="household"
                name="household"
                color="#F8F6FE"
                className="py-1 px-2"
                label="Household"
                ref={register()}
                defaultValue={auth?.user?.household || ""}
                iconBeforeClass=""
                inputClassName="-ml-3"
              >
                <option disabled value="">
                  Select household
                </option>
                <option value="individual">Individual</option>
                <option value="smallFamily">Small Family</option>
                <option value="largeFamily">Large family</option>
              </DarkSelectInput>
              <ErrorMessage
                errors={errors}
                name="household"
                as={
                  <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div className="mb-10 mt-8 mx-auto flex gap-5">
              <div className="w-full">
                <PrimaryButton disabled={loading}>
                  {loading ? "Updating" : "Save changes"}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProfileUpdate;
