import React, { useContext } from "react";
import DeleteAccount from "./account.delete";
import VerifyAccount from "./account.verify";
import { authContext } from "../../../shared/store/contexts";

const AccountSettings = () => {
  const { auth } = useContext(authContext);
  return (
    <>
      <section className="py-6">
        {!auth?.user?.verified && <VerifyAccount />}
        <DeleteAccount />
      </section>
    </>
  );
};

export default AccountSettings;
