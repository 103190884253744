import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useMutation } from "@apollo/client";

import { P } from "../../atoms/Texts";
import { DarkFormField } from "../../atoms/Inputs";
import { PrimaryButton } from "../../atoms/Buttons";

import { UPDATE_PASSWORD_MUTATION } from "../../../shared/queries/mutation.password.update";
import { toastError, toastSuccess } from "../../../shared/helpers";

const PasswordUpdate = () => {
  const [passwordType] = useState("password");

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD_MUTATION);

  const onUpdatePassword = (data) => {
    updatePassword({
      variables: {
        data: {
          password: data.oldpassword,
          newPassword: data.newpassword,
          confirmPassword: data.confirmpassword,
        },
      },
    })
      .then((response) => {
        if (response?.data?.updateUserPassword) {
          reset();
          toastSuccess(response?.data?.updateUserPassword?.message);
        } else {
          throw new Error(response.errors);
        }
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const { register, errors, handleSubmit, watch, reset } = useForm({
    validateCriteriaMode: "all",
  });

  const newPassword = watch("newpassword");

  return (
    <form onSubmit={handleSubmit(onUpdatePassword)}>
      <div className="w-full mx-auto mt-10 lg:mx-0 lg:w-1/2">
        <div>
          <DarkFormField
            type={passwordType}
            id="oldpassword"
            name="oldpassword"
            w="184px"
            ref={register({
              required: "Password is required.",
            })}
            placeholder="Enter old password"
            label="Old Password"
            className="w-full"
          />
          <ErrorMessage
            errors={errors}
            name="oldpassword"
            as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
          >
            {({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => (
                <P key={type}>{message}</P>
              ))
            }
          </ErrorMessage>
        </div>

        <div>
          <DarkFormField
            type={passwordType}
            id="newpassword"
            name="newpassword"
            w="384px"
            ref={register({
              required: "New Password is required.",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters",
              },
            })}
            placeholder="Enter new password"
            label="New Password"
            className="w-full"
          />
          <ErrorMessage
            errors={errors}
            name="newpassword"
            as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
          >
            {({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => (
                <P key={type}>{message}</P>
              ))
            }
          </ErrorMessage>
        </div>

        <div>
          <DarkFormField
            type={passwordType}
            id="confirmpassword"
            name="confirmpassword"
            w="300px"
            ref={register({
              required: "New Password is required.",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters",
              },
              validate: (value) =>
                value === newPassword || "The passwords do not match",
            })}
            placeholder="Enter confirmed new password"
            label="Confirm New Password"
            className="w-full"
          />
          <ErrorMessage
            errors={errors}
            name="confirmpassword"
            as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
          >
            {({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => (
                <P key={type}>{message}</P>
              ))
            }
          </ErrorMessage>
        </div>
      </div>

      <div className="mb-10 mx-auto">
        <PrimaryButton disabled={loading}>
          {loading ? "Updating" : "Change Password"}
        </PrimaryButton>
      </div>
    </form>
  );
};

export default PasswordUpdate;
