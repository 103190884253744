import React from "react";
import styled from "styled-components";
import t from "prop-types";

import { H3, P } from "../../atoms/Texts";

const FeatureCardStyle = styled.div`
  @media (max-width: 600px) {
    img {
      width: 54px;
      height: 56px;
    }
  }
`;

const FeatureCard = ({ icon, heading, body, link }) => (
  <FeatureCardStyle className="flex flex-col justify-between h-full lg:pr-10">
    <div>
      <img src={icon} alt="" className="h-20 mb-8" />
      <H3 className="mb-6" color="#fff">
        {heading}
      </H3>
      <P fontSize="18px" className="mb-6" color="#CECBE2">
        {body}
      </P>
    </div>
    {/* <Link to={link}>
    <TextLink color="#fff" fontFamily="medium" className="flex items-center">
      Learn more
      <Icon.ArrowRight color="#fff" size={16} className="ml-4" />
    </TextLink>
  </Link> */}
  </FeatureCardStyle>
);

FeatureCard.propTypes = {
  icon: t.string,
  heading: t.string.isRequired,
  body: t.string.isRequired,
  link: t.string,
};

export default FeatureCard;
