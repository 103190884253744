import React, { useState, useContext, useEffect } from "react";
import { themeContext } from "../../../shared/store/contexts/context.theme";
import {
  SelectDropdownStyle,
  SelectOptionStyle,
  SelectToggleStyle,
} from "./input.styles";
import arrowDown from "../../../assets/images/arrow-down.svg";
import arrowDownDark from "../../../assets/images/arrow-downDark.svg";

const Dropdown = ({
  setToggle,
  toggle,
  theme,
  options,
  changeView,
  optionColor,
}) => {
  const checkIfNotInDropDown = ({ target }) => {
    if (!target.classList.contains("select-dropdown-child")) {
      setToggle(!toggle);
    }
  };

  useEffect(() => {
    window.addEventListener("click", checkIfNotInDropDown);

    return () => {
      window.removeEventListener("click", checkIfNotInDropDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectDropdownStyle className="py-2" bg={theme.background}>
      {options.map((option, idx) => (
        <SelectOptionStyle
          key={idx}
          onClick={() => changeView(option)}
          color={optionColor}
          className="block w-full text-left p-2 text-lg select-dropdown-child"
        >
          {option}
        </SelectOptionStyle>
      ))}
    </SelectDropdownStyle>
  );
};

export const SelectDropDown = (props) => {
  const { options } = props;
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState(options[0]);

  const changeView = (option) => {
    setSelected(option);
    props.changeView(option);
    setToggle(!toggle);
  };

  const { theme, isDark } = useContext(themeContext);

  return (
    <SelectToggleStyle
      className={`flex items-center justify-between ${props.className}`}
      onClick={() => setToggle(!toggle)}
      // onBlur={() => setToggle(false)}
      color={props.toggleColor}
    >
      {selected}
      {isDark ? (
        <img src={arrowDownDark} alt="" className={`${toggle ? "active" : ""}`} />
      ) : (
        <img src={arrowDown} alt="" className={`${toggle ? "active" : ""}`} />
      )}

      {toggle && (
        <Dropdown
          theme={theme}
          changeView={changeView}
          setToggle={setToggle}
          toggle={toggle}
          options={props.options}
          optionColor={props.optionColor}
        />
      )}
    </SelectToggleStyle>
  );
};
