import React from "react";
import { useTable, usePagination } from "react-table";
import styled from "styled-components";
import * as Icon from "react-feather";

import noResultImg from "../../../assets/images/no-result-img.svg";
// import emptyTableIcon from "../../../assets/images/Add Database.png";
import { LinkButton } from "../../atoms/Buttons";
// import { TextSkeleton } from "../../atoms/Skeletons";
import { H3, H6, P } from "../../atoms/Texts";

const TableHolder = styled.table`
  max-height: 80vh;
`;

export function CardTableFooter({
  pageNumber = 1,
  totalPages = 1,
  pageSize,
  setPageSize,
  prevOnClick,
  nextOnClick,
  canPreviousPage,
  canNextPage,
}) {
  return (
    <div className="flex justify-end items-center p-3 px-6 bg-white rounded-b-md">
      <div className="mr-10 flex items-center">
        <label htmlFor="rows_select">
          <P
            color="#9FA2B4"
            fontSize="14px"
            smallSize="14px"
            className="inline-block"
          >
            Rows per page:
          </P>
        </label>
        <select
          id="rows_select"
          name="rows_select"
          defaultValue={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
        >
          {Array(10)
            .fill("")
            .map((_, idx) => (
              <option key={idx} value={idx + 1}>
                {idx + 1}
              </option>
            ))}
        </select>
      </div>
      <P color="#9FA2B4" fontSize="14px" smallSize="14px" className="mr-6">
        {pageNumber * pageSize + 1} - {(pageNumber + 1) * pageSize} of{" "}
        {totalPages * pageSize}
      </P>
      <div className="flex">
        {canPreviousPage && (
          <LinkButton
            disabled={!canPreviousPage}
            title="Prev"
            className="font-inter font-bold text-base leading-7"
            onClick={prevOnClick}
          >
            <Icon.ChevronLeft className="mr-2" size={24} color="#9FA2B4" />
          </LinkButton>
        )}
        {canNextPage && (
          <LinkButton
            disabled={!canNextPage}
            className="font-inter font-bold text-base leading-7"
            onClick={nextOnClick}
          >
            <Icon.ChevronRight size={24} color="#9FA2B4" />
          </LinkButton>
        )}
      </div>
    </div>
  );
}

export function TableCell({ children, className, style = {} }) {
  return (
    <td className={"px-6 py-8 " + className} style={style}>
      {children}
    </td>
  );
}

export function TableRow({ children, className, style = {} }) {
  return (
    <tr
      className={
        "border border-solid border-b-px px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 " +
        className
      }
      style={style}
    >
      {children}
    </tr>
  );
}

export const Table = ({
  columns,
  data,
  loading = true,
  empty_message = "It looks Empty",
  empty_sub_message = "",
  noHeader,
  noFooter,
}) => {
  const tableData = React.useMemo(
    () => (loading ? Array(3).fill({}) : data?.length > 0 ? data : []),
    [loading, data],
  );

  const tableColumns = React.useMemo(
    () =>
      loading
        ? columns?.map((column) => ({
            ...column,
            // Cell: <TextSkeleton />,
          }))
        : columns,
    [loading, columns],
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    canPreviousPage,
    canNextPage,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    nextPage,
    previousPage,
    page,
    pageCount,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      initialState: { pageIndex: 0, pageSize: 10 },
      columns: tableColumns,
      data: tableData,
    },
    usePagination,
  );

  return (
    <div className={"relative flex flex-col min-w-0 break-words w-full bg-white"}>
      {loading ? (
        <div className="block w-full overflow-x-auto">
          <TableHolder
            {...getTableProps()}
            className="items-center w-full bg-transparent border-collapse relative"
          >
            {!noHeader && (
              <thead className="sticky top-0">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        scope="col"
                        className={
                          " px-6 align-middle border border-solid py-6 border-l-0 border-r-0 whitespace-nowrap text-left "
                        }
                      >
                        <H6
                          color="#22202D"
                          fontFamily="regular"
                          fontSize="16px"
                          smallSize="16px"
                        >
                          {column.render("Header")}
                        </H6>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody {...getTableBodyProps()}>
              {page?.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row?.getRowProps()}>
                    {row?.cells?.map((cell) => {
                      return (
                        <TableCell {...cell?.getCellProps()}>
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </tbody>
          </TableHolder>
        </div>
      ) : (
        <>
          {tableData && data?.length > 0 ? (
            <>
              <div className="block w-full">
                <TableHolder
                  {...getTableProps()}
                  className="items-center w-full bg-transparent border-collapse relative"
                >
                  {!noHeader && (
                    <thead className="sticky top-0">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps()}
                              scope="col"
                              className={
                                "px-6 align-middle border border-solid py-6 border-l-0 border-r-0 whitespace-nowrap text-left "
                              }
                            >
                              <H6
                                color="#22202D"
                                fontFamily="regular"
                                fontSize="16px"
                                smallSize="16px"
                              >
                                {column.render("Header")}
                              </H6>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                  )}
                  <tbody {...getTableBodyProps()}>
                    {page?.map((row, i) => {
                      prepareRow(row);
                      return (
                        <TableRow {...row?.getRowProps()}>
                          {row?.cells?.map((cell) => {
                            return (
                              <TableCell {...cell?.getCellProps()}>
                                {cell.render("Cell")}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </tbody>
                </TableHolder>
              </div>
              {!noFooter && (
                <CardTableFooter
                  pageNumber={pageIndex}
                  totalPages={pageCount}
                  prevOnClick={previousPage}
                  nextOnClick={nextPage}
                  canNextPage={canNextPage}
                  canPreviousPage={canPreviousPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              )}
            </>
          ) : (
            <div className="flex items-center justify-center flex-col py-20 lg:py-32">
              {/* <img src={emptyTableIcon} alt="" className="" /> */}
              <img src={noResultImg} alt="" className="" />
              <H3 className="mb-4">{empty_message}</H3>
              <p className="">{empty_sub_message}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
