import React, { useContext } from "react";
import styled from "styled-components";

import { Layout } from "../../organisms/Layouts";
import { H1, H4, H5 } from "../../atoms/Texts";
import { LandingPageFooter } from "../../organisms/Footers";
import CtaSection from "../../organisms/CtaSection";
import { themeContext } from "../../../shared/store/contexts";

const Hero = styled.section`
  @media (max-width: 1000px) {
    min-height: fit-content;
    max-height: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const AboutUsPage = () => {
  const { theme } = useContext(themeContext);

  return (
    <Layout>
      <main className="container mx-auto">
        <Hero className="lg:mt-40">
          <div className="container-flexible pt-10 lg:pt-10 lg:w-1/2 lg:mb-20">
            <H1
              color={theme.purpleFont}
              className="mb-10 lg:w-5/6 font-bold"
              mediumSize="56px"
              smallSize="32px"
            >
              About
            </H1>
          </div>
        </Hero>

        <div className="container-flexible w-10/12 mb-20 ">
          <H4
            color={theme.primaryFont}
            fontFamily="semi"
            className="mb-4"
            fontSize="18px"
          >
            Social safety net helps you find and apply for social services. You can
            contact social service providers, save services, share services and even
            refer others for services using our platform.
          </H4>
          <H5
            color={theme.primaryFont}
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            - On social safety net, you can:
          </H5>
          <ul className="pl-8" style={{ listStyleType: "disc" }}>
            <li
              className="mt-2"
              style={{
                color: theme.secondaryFont,
              }}
            >
              Search for data on various topics, categories using free text
            </li>
            <li
              className="mt-2"
              style={{
                color: theme.secondaryFont,
              }}
            >
              View search results in text, map and voice form.
            </li>
            <li
              className="mt-2"
              style={{
                color: theme.secondaryFont,
              }}
            >
              Save organizations and services.
            </li>
            <li
              className="mt-2"
              style={{
                color: theme.secondaryFont,
              }}
            >
              Refer friends to services.
            </li>
            <li
              className="mt-2"
              style={{
                color: theme.secondaryFont,
              }}
            >
              Suggest edits to services and organizations.
            </li>
            <li
              className="mt-2"
              style={{
                color: theme.secondaryFont,
              }}
            >
              Share services and organization details with friends.
            </li>
            <li
              className="mt-2"
              style={{
                color: theme.secondaryFont,
              }}
            >
              Create keys for use with our API.
            </li>
          </ul>
          <H5
            color={theme.primaryFont}
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            Social safety net was developed and is managed by{" "}
            <a
              href="https://www.philanthrolab.co"
              style={{
                color: theme.purpleFont,
              }}
            >
              Philanthrolab.co
            </a>
            .
          </H5>
          <H5
            color={theme.primaryFont}
            fontFamily="medium"
            className="mt-8 mb-2"
            fontSize="16px"
          >
            For inquiries or complaints, send an email to{" "}
            <a
              href="mailto:contact@philanthrolab.co"
              style={{
                color: theme.purpleFont,
              }}
            >
              contact@philanthrolab.co.
            </a>
          </H5>
        </div>
      </main>
      <CtaSection />
      <LandingPageFooter theme={theme} />
    </Layout>
  );
};

export default AboutUsPage;
