import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";

import moment from "moment";
import * as Icon from "react-feather";
import { P, TextLink } from "../../atoms/Texts";
import { DarkSelectInput, DarkFormField } from "../../atoms/Inputs";
import { PrimaryButton } from "../../atoms/Buttons";
import { dirtyValues } from "../../../shared/helpers/util.getDirtyFields";

const SecondForm = ({ fields, updateFields, changeStep }) => {
  const [dirtyFields, setDirtyFields] = useState({});

  const { register, errors, handleSubmit, formState } = useForm({
    validateCriteriaMode: "all",
    defaultValues: {
      gender: fields.gender || "",
      employmentStatus: fields.employmentStatus || "",
      disability: fields.disability || "",
      dob: fields.dob,
      housingStatus: fields.housingStatus || "",
    },
  });

  useEffect(() => {
    setDirtyFields(formState.dirtyFields);
  }, [formState]);

  const submitDetails = (data) => {
    const updatedFields = dirtyValues(dirtyFields, data);

    updateFields(updatedFields);
    changeStep(3);
  };

  const goBack = () => {
    changeStep(1);
  };

  return (
    <form onSubmit={handleSubmit(submitDetails)}>
      <div className="w-full mx-auto">
        <div className="mx-auto">
          <div
            className="rounded-sm flex items-center mb-6"
            style={{ backgroundColor: "#FFF4E5", height: "72px" }}
          >
            <P color="#663C02" className="pl-3 font-medium">
              This part of the registration is optional, you only need this when
              applying for services
            </P>
          </div>

          <div>
            <DarkSelectInput
              type="select"
              id="gender"
              name="gender"
              color="#F8F6FE"
              className="py-1 px-2"
              label="Gender"
              ref={register()}
              defaultValue={fields.gender || ""}
              iconBeforeClass=""
              inputClassName="-ml-3"
            >
              <option value="" disabled>
                Select your gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Prefer not to say</option>
            </DarkSelectInput>
            <ErrorMessage
              errors={errors}
              name="gender"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkSelectInput
              type="select"
              id="employmentStatus"
              name="employmentStatus"
              color="#F8F6FE"
              className="py-1 px-2"
              label="Employment Status"
              ref={register()}
              defaultValue={fields.employmentStatus || ""}
              iconBeforeClass=""
              inputClassName="-ml-3"
            >
              <option disabled value="">
                Select employment status
              </option>
              <option value="employed">Employed</option>
              <option value="unemployed">Unemployed</option>
            </DarkSelectInput>
            <ErrorMessage
              errors={errors}
              name="employmentStatus"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkFormField
              type="text"
              id="disability"
              name="disability"
              color="#F8F6FE"
              className="py-1 px-2"
              label="Disability:"
              ref={register()}
              defaultValue={fields.disability || ""}
              iconBeforeClass=""
              inputClassName="-ml-3"
              placeholder="Enter disability, if any"
            ></DarkFormField>
            <ErrorMessage
              errors={errors}
              name="disability"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkFormField
              type="date"
              id="dob"
              name="dob"
              color="#F8F6FE"
              className="py-1 px-2"
              ref={register({
                // pattern: {
                //   value: /^(\d{2})-(\d{2})-(\d{4})$/,
                //   message: "Enter date in dd-mm-yyyy format",
                // },
                validate: (value) =>
                  value === "" ||
                  moment(value).isSameOrBefore(moment()) ||
                  "Enter a date of birth before today",
              })}
              label="Date of Birth"
              placeholder="dd-mm-yyyy"
              defaultValue={fields.dob}
              iconBeforeClass=""
              inputClassName="-ml-3"
            />
            <ErrorMessage
              errors={errors}
              name="dob"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkSelectInput
              type="select"
              id="housingStatus"
              name="housingStatus"
              color="#F8F6FE"
              className="py-1 px-2"
              label="Housing Status"
              ref={register()}
              placeholder="Enter your first name"
              defaultValue={fields.housingStatus || ""}
              iconBeforeClass=""
              inputClassName="-ml-3"
            >
              <option disabled value="">
                Select housing status
              </option>
              <option value="homeless">Homeless</option>
              <option value="nearHomeless">Near Homeless</option>
              <option value="notHomeless">Not Homeless</option>
            </DarkSelectInput>
            <ErrorMessage
              errors={errors}
              name="housingStatus"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <TextLink className="w-full mb-6 block" color="#716E8B">
            Registering to this website, you accept our
            <Link to="terms" style={{ color: "#442ED0" }}>
              {" "}
              Terms of Use{" "}
            </Link>
            and our
            <Link to="privacy" style={{ color: "#442ED0" }}>
              {" "}
              Privacy Policy
            </Link>
          </TextLink>
        </div>

        <div className="mb-10 mt-8 mx-auto flex gap-5">
          <div className="w-full">
            <PrimaryButton.Light
              type="button"
              onClick={goBack}
              className="w-full flex items-center"
              color="#000"
            >
              <Icon.ArrowLeft></Icon.ArrowLeft>
              <div className="ml-6">Back</div>
            </PrimaryButton.Light>
          </div>

          <div className="w-full">
            <PrimaryButton type="submit" className="w-full">
              Continue
            </PrimaryButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SecondForm;
