import React, { useContext } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import markdown from "./content";
import remarkGfm from "remark-gfm";
import remarkAutoLinkHeading from "remark-autolink-headings";
import remarkBreaks from "remark-breaks";
import remarkCodeBlocks from "remark-code-blocks";
import remarkFixGuillements from "remark-fix-guillemets";
import remarkSlug from "remark-slug";
import remarkToc from "remark-toc";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Layout } from "../../organisms/Layouts";
import { themeContext } from "../../../shared/store/contexts/context.theme";

const MainStyle = styled.main`
  h1 {
    font-size: 2em;
    font-weight: bold;
    margin: 0.67em 0;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.83em 0;
  }

  p {
    margin: 1em 0;
    color: ${(props) => props.theme.primaryFont};
  }

  li,
  table {
    color: ${(props) => props.theme.primaryFont};
  }

  ul {
    display: block;
    list-style-type: circle;
    margin: 1em 0;
    padding-left: 40px;
  }

  blockquote {
    padding: 0 1em;
    margin: 1em 40px;
    border-left: 2px solid #442ecf;
  }

  h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin: 1em 0;
  }

  code {
    font-family: monospace;
    background: aliceblue;
    padding: 2px;
    color: ${(props) => props.theme.purpleFont};
  }

  a {
    color: #442ecf;
  }

  table,
  th,
  td {
    border: 1px solid black;
    padding: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border-bottom: 1px solid whitesmoke;
    padding-bottom: 10px;
    color: ${(props) => props.theme.primaryFont};
  }
`;

const DocumentationPage = () => {
  const { theme } = useContext(themeContext);

  return (
    <Layout>
      <MainStyle className="container mx-auto py-20" theme={theme}>
        <ReactMarkdown
          children={markdown}
          // renderers={{ code: Renderer }}
          remarkPlugins={[
            remarkAutoLinkHeading,
            remarkGfm,
            remarkBreaks,
            remarkCodeBlocks,
            // remarkFirstHeading,
            remarkFixGuillements,
            remarkSlug,
            remarkToc,
          ]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, "")}
                  style={dracula}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        />
      </MainStyle>
    </Layout>
  );
};

export default DocumentationPage;
