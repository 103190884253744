import { useContext, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SAVED_SERVICES_QUERY, UNSAVE_SERVICE_MUTATION } from "../queries";
import { authContext } from "../store/contexts";
import { toastError } from "../helpers";

const useService = () => {
  const { auth } = useContext(authContext);

  // Loading state for getting saved services
  const [gettingSavedServices, setGettingSavedServices] = useState(false);

  // Mutation for removing service from favourites
  const [unSaveService] = useMutation(UNSAVE_SERVICE_MUTATION, {
    onCompleted({ removeServiceFromFavorites }) {
      if (removeServiceFromFavorites) {
        getSavedServices(auth?.user?.id);
        setGettingSavedServices(false);
      }
    },
    onError(error) {
      console.log(error, error.errors);
      // if (errors) {
      //   setGettingSavedServices(false);
      //   getSavedServices(auth?.user?.id);
      //   toastError(errors[0].message);
      // }
    },
  });

  const removeServiceFromFavorites = ({ userId = auth?.user?.id, serviceId }) => {
    setGettingSavedServices(true);

    unSaveService({
      variables: {
        data: {
          userId,
          serviceId,
        },
      },
    })
      .then((response) => {
        if (response?.data?.removeServiceFromFavorites) {
          getSavedServices(auth?.user?.id);
          setGettingSavedServices(false);
        }
      })
      .catch(() => {
        setGettingSavedServices(false);
        getSavedServices(auth?.user?.id);
        // toastError(errors[0].message);
      });
  };

  // Query for getting saved services
  const [savedServices] = useLazyQuery(SAVED_SERVICES_QUERY, {
    onCompleted({ getAllUserFavorites }) {
      if (getAllUserFavorites) {
        auth.updateAuth({
          user: {
            ...auth.user,
            savedServices: getAllUserFavorites,
          },
        });
        setGettingSavedServices(false);
      }
    },
    onError({ errors }) {
      if (errors) {
        setGettingSavedServices(false);
        getSavedServices(auth?.user?.id);
        toastError(errors[0].message);
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const getSavedServices = (id = auth?.user?.id) => {
    setGettingSavedServices(true);

    savedServices({
      variables: {
        userId: id,
      },
    });
  };

  // Function for checking if a service is saved
  const isServiceSaved = (serviceId) => {
    const { savedServices } = auth.user;
    return (
      savedServices?.filter((service) => service.serviceId === serviceId)?.length > 0
    );
  };

  // Methods and Properties from hook
  return {
    isServiceSaved,
    getSavedServices,
    gettingSavedServices,
    removeServiceFromFavorites,
  };
};

export default useService;
