import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useMutation } from "@apollo/client";

import loadIcon from "../../../assets/images/tail-spin.svg";

import ModalHolder from "../../molecules/ModalHolder";

import { REFER_SELF_MUTATION } from "../../../shared/queries";
import { toastError, toastSuccess } from "../../../shared/helpers";
import { authContext } from "../../../shared/store/contexts";
import { CircleRadio, DarkFormField } from "../../atoms/Inputs";
import { P } from "../../atoms/Texts";
import { PrimaryButton } from "../../atoms/Buttons";
import { REFER_OTHER_MUTATION } from "../../../shared/queries";

const ReferServiceModal = ({ closeModal, service }) => {
  const { auth } = useContext(authContext);

  const { register, errors, handleSubmit, watch } = useForm({
    validateCriteriaMode: "all",
  });

  const refer = watch("refer");

  const [loading, setLoading] = useState(false);

  // Save A Service Mutation

  const [referSelf] = useMutation(REFER_SELF_MUTATION);
  const [referSomeone] = useMutation(REFER_OTHER_MUTATION);

  const onSuggestEdit = async (data) => {
    setLoading(true);
    if (refer === "self") {
      referSelf({
        variables: {
          userId: auth.user.id,
          serviceId: service.id,
          orgId: service.organization.id,
          serviceName: service.name,
        },
      })
        .then((data) => {
          toastSuccess("You have been referred to the service.");
          setLoading(false);
          closeModal();
        })
        .catch((error) => {
          setLoading(false);
          toastError(error.message);
        });
    } else {
      referSomeone({
        variables: {
          data: {
            email: data.email,
            name: data.name,
            serviceId: service.id,
            orgId: service.organization.id,
            serviceName: service.name,
          },
        },
      })
        .then(() => {
          toastSuccess(`You have referred ${data.name} to the service.`);
          setLoading(false);
          closeModal();
        })
        .catch((error) => {
          setLoading(false);
          toastError(error.message);
        });
    }
  };

  return (
    <ModalHolder closeModal={closeModal} header="Refer">
      <form onSubmit={handleSubmit(onSuggestEdit)} className="py-10">
        <CircleRadio
          id="referSelf"
          name="refer"
          value="self"
          ref={register()}
          label="Refer Yourself"
        />

        <CircleRadio
          id="referSomeone"
          name="refer"
          value="someone"
          ref={register()}
          label="Refer Someone"
        />

        {refer === "someone" && (
          <div className="mt-4">
            <DarkFormField
              type="text"
              id="name"
              name="name"
              ref={register({
                required: "Please enter your full name.",
                minLength: {
                  value: 2,
                  message: "Please enter your full name",
                },
              })}
              placeholder="John Doe"
              label="Name"
              className="w-full"
            />

            <ErrorMessage
              errors={errors}
              name="name"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>

            <DarkFormField
              type="email"
              id="email"
              name="email"
              ref={register({
                required: "Please enter your email.",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Please enter a valid email.",
                },
              })}
              placeholder="example@mail.com"
              label="Email"
              className="w-full"
            />

            <ErrorMessage
              errors={errors}
              name="email"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>
        )}

        <div className="flex justify-end">
          <PrimaryButton disabled={loading}>
            {!loading ? (
              "Send"
            ) : (
              <img src={loadIcon} alt="Loading" className="mx-auto h-6 w-6" />
            )}
          </PrimaryButton>
        </div>
      </form>
    </ModalHolder>
  );
};

export default ReferServiceModal;
