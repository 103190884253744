import { gql } from "@apollo/client";

export const NEARBY_ORGANIZATIONS = gql`
  query GetNearbyOrganizations(
    $text: String!
    $from: Int
    $size: Int
    $zipCode: String!
  ) {
    getNearbyOrganizations(
      text: $text
      from: $from
      size: $size
      zipCode: $zipCode
    ) {
      id
      description
      name
      tagline
      alternateName
      email
      url
      ein
      deductibility
      subsection
      assetAmount
      nteeType
      incomeAmount
      nteeSuffix
      filingRequirement
      classification
      latest990
      rulingDate
      nteeCode
      groupName
      deductibilityCode
      affiliation
      foundationStatus
      nteeClassification
      accountingPeriod
      exemptOrgStatus
      deductibilityDetail
      exemptOrgStatusCode
      nteeLetter
      programs {
        name
      }
      services {
        name
      }
      contacts {
        name
        email
        title
      }
      locations {
        latitude
        longitude
        address
        city
        region
        stateOrProvince
        zipCode
        country
        distance
      }
      phones {
        number
      }
    }
  }
`;
