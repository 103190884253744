import { gql } from "@apollo/client";

export const USER_REFERRALS_QUERY = gql`
  query GetAllUserReferrals($userId: String!) {
    getAllUserReferrals(userId: $userId) {
      id
      from
      user {
        id
        firstName
        lastName
        country
        avatarUrl
        isDeleted
        email
        gender
        employmentStatus
        disability
        dob
        housingStatus
        incomeLevel
        insurance
        household
        createdAt
        updatedAt
        phone
        ext
        verified
        completeFields
        incompleteFields
      }
      userId
      eligibilityStatus
      serviceId
      serviceName
      orgId
      status
      isSelfReferral
      recipientID
    }
  }
`;
