/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { themeContext } from "../../../shared/store/contexts";

import noResultImg from "../../../assets/images/no-result-img.svg";
import { Layout } from "../../organisms/Layouts";
import { P, H2, TextLink, H3 } from "../../atoms/Texts";
import { SelectDropDown } from "../../atoms/Inputs";
import Result from "../../organisms/SearchResultCard";
import Map from "../../organisms/Map/map";
// import { useQuery } from "@apollo/client";
import { Main, MapSection, ToggleHolder } from "./styles";
// import { SEARCH_SERVICEs } from "../../shared/queries";

const base_url = process.env.REACT_APP_BASE_URI;

const SearchResults = (props) => {
  const { need, zipCode } = props.match.params;

  const { theme, isDark } = useContext(themeContext);
  const [view, setView] = useState("Text");
  const [selected] = useState("");
  const [selectedResult, setSelectedResult] = useState(null);
  const [results, setResults] = useState([]);
  const [, setCenter] = useState({});
  const [loading] = useState(false);
  const [, setStopSpeech] = useState(false);
  const [page] = useState(0);
  const [count, setCount] = useState(0);

  // const deActivateInfo = (result) => setShowInfo(false);

  const changeView = (option) => setView(option);

  // const { loading, data } = useQuery(SEARCH_SERVICEs, {
  //   variables: {
  //     text: need,
  //     zipCode,
  //     // page: 0,
  //     // limit: 9,
  //   },
  // });

  const getResults = () => {
    // setLoading(true);
    fetch(`${base_url}/services/search`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: need,
        zip_code: zipCode,
        page: page,
        limit: 10,
        sort_by: "name",
        order: "asc",
      }),
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.success) {
            setCount(data.query_count);
            if (data.data.services_nearby.length > 0) {
              setCenter({
                lat: Number(data.data.services_nearby[0]?.location_lat),
                lng: Number(data.data.services_nearby[0]?.location_lng),
              });
              setResults(data.data);
              // setLoading(false);
            } else if (
              data.data.services.length > 0 &&
              data.data.services_nearby.length === 0
            ) {
              setCenter({
                lat: Number(data.data.services[0]?.location_lat),
                lng: Number(data.data.services[0]?.location_lng),
              });
              setResults(data.data);
              // setLoading(false);
            } else {
              setResults([]);
              // setLoading(false);
            }
          } else {
            setTimeout(() => {
              getResults();
            }, 5000);
          }
        });
      })
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          getResults();
        }, 5000);
      });
  };

  useEffect(() => {
    getResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (view !== "Voice") {
      if (
        "speechSynthesis" in window &&
        ("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
      ) {
        // console.log("speech exists")
        setStopSpeech(true);
        // console.log("stopped speech and recognition")
        let synth = window.speechSynthesis;
        let SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;

        // PLUG THE GRAMMAR INTO THE SPEECH RECOGNITION
        let recognition = new SpeechRecognition();

        // STOPPING SPEECH RECOGNITION
        recognition.stop();
        synth.cancel();
      }
    } else {
      setStopSpeech(false);
    }
  }, [view]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    if (results.services) {
      // eslint-disable-next-line array-callback-return
      results.services.map((result) => {
        if (result.name.toLowerCase().includes(selected)) {
          setSelectedResult(result);
        }
      });
    }
    if (results.services_nearby) {
      // eslint-disable-next-line array-callback-return
      results.services_nearby.map((result) => {
        if (result.name.toLowerCase().includes(selected)) {
          setSelectedResult(result);
        }
      });
    }
  }, [results, selected]);

  return (
    <Layout>
      <Main className="">
        <section className=" pt-6 pb-10 md:py-24 px-4 md:px-0 w-full md:w-4/5 lg:w-4/6 mx-auto">
          <div className="flex flex-wrap md:flex-no-wrap justify-between">
            {selected && (
              <ToggleHolder className="md:hidden mb-12 md:mb-0">
                <TextLink color={theme.lightPurple} className="mb-2">
                  Change View Mode
                </TextLink>
                <SelectDropDown
                  toggleColor={theme.lightPurple}
                  optionColor={theme.lightPurple}
                  options={["Text", "Map", "Voice"]}
                  changeView={(option) => changeView(option)}
                />
              </ToggleHolder>
            )}

            {selected ? (
              <div className="">
                <H2 color={theme.primaryFont} className="mb-12 capitalize">
                  {selected}
                </H2>
                <P color={theme.secondaryFont} fontSize="16px">
                  {selectedResult?.address}
                </P>

                <P color={theme.secondaryFont} fontSize="16px">
                  {selectedResult?.phone}
                </P>

                <P color={theme.secondaryFont} fontSize="16px">
                  {selectedResult?.email}
                </P>

                <TextLink
                  color={theme.lightPurple}
                  fontFamily="italic"
                  className="mt-1 mb-4"
                >
                  Last updated {selectedResult?.lastUpdate}
                </TextLink>
              </div>
            ) : (
              <div className="flex items-center flex-wrap">
                <H2 color={theme.primaryFont} className="mr-3">
                  Search Results for
                </H2>
                <H2 color={theme.purpleFont} className="mr-3 capitalize">
                  {need}
                </H2>
                <H2 color={theme.primaryFont} className="mr-3">
                  in
                </H2>
                <H2 color={theme.purpleFont} className="mr-3">
                  {zipCode}
                </H2>
              </div>
            )}

            {(results.services?.length > 0 ||
              results.services_nearby?.length > 0) && (
              <ToggleHolder
                className={`${selected ? "hidden" : ""} md:block mt-4 md:mt-0`}
              >
                <TextLink color={theme.lightPurple} className="mb-2">
                  Change View Mode
                </TextLink>
                <SelectDropDown
                  toggleColor={theme.lightPurple}
                  optionColor={theme.lightPurple}
                  options={["Text", "Map", "Voice"]}
                  changeView={(option) => changeView(option)}
                />
              </ToggleHolder>
            )}
          </div>

          {!loading && (
            <div className="flex flex-wrap-reverse md:flex-wrap items-center justify-between">
              {selected ? (
                ""
              ) : (
                <ToggleHolder className="flex items-center justify-between md:justify-start mt-6 mb-16">
                  <P color={theme.lightPurple} fontSize="16px" className="mr-8">
                    {count} result{count > 1 && "s"} found
                  </P>
                  {/* <P 
                    color={theme.purpleFont}
                    className="capitalize"
                    fontFamily="semi"
                  >
                    Advance Filter
                  </P> */}
                </ToggleHolder>
              )}
            </div>
          )}

          {loading ? (
            <>
              <div className="mt-10 mb-8">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
              </div>
              <div className="mb-8">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
              </div>
            </>
          ) : (
            <>
              {results.length === 0 ? (
                <div className=" pt-20 flex flex-col items-center">
                  <img src={noResultImg} alt="" className="" />
                  <H3 color={theme.purpleFont} className="mt-10">
                    No results found
                  </H3>
                  <P
                    fontSize="16px"
                    color={theme.lightFont}
                    className="mt-2 mb-0 text-center"
                  >
                    We cannot locate an entry for your search.
                    <br />
                    <Link to="/" className="ml-2 underline">
                      Please modify your search and try again
                    </Link>
                  </P>
                </div>
              ) : (
                <>
                  {view === "Text" && (
                    <>
                      {results?.services?.length > 0 && (
                        <section>
                          {results?.services?.map((result, idx) => (
                            <Result
                              key={idx}
                              title={result.name}
                              alternate_name={result.alternate_name}
                              address={result.address}
                              tel={result.phones}
                              email={result.email}
                              lastUpdate={result.lastUpdate}
                              open={result.status === "Open" ? true : false}
                              verified={result.verified}
                              wait_time={result.wait_time}
                              eligibility={result.eligibility}
                              description={result.description}
                              accreditations={result.accreditations}
                              disability={result.disability}
                              application_process={result.application_process}
                              languages={result.languages}
                              fees={result.fees}
                              website={result.url}
                              twitter={result.twitter}
                              id={result.id}
                            />
                          ))}
                        </section>
                      )}
                      {results?.services_nearby?.length > 0 && (
                        <section>
                          <H3 color={theme.primaryFont} className="mb-2">
                            Services nearby
                          </H3>
                          {results?.services_nearby?.map((result, idx) => (
                            <Result
                              key={idx}
                              title={result.name}
                              alternate_name={result.alternate_name}
                              address={result.address}
                              tel={result.phones}
                              email={result.email}
                              lastUpdate={result.lastUpdate}
                              open={result.status === "Open" ? true : false}
                              verified={result.verified}
                              wait_time={result.wait_time}
                              eligibility={result.eligibility}
                              description={result.description}
                              accreditations={result.accreditations}
                              disability={result.disability}
                              application_process={result.application_process}
                              languages={result.languages}
                              fees={result.fees}
                              website={result.url}
                              twitter={result.twitter}
                              id={result.id}
                            />
                          ))}
                        </section>
                      )}
                    </>
                  )}

                  {view === "Map" && (
                    <MapSection
                      bg={theme.background}
                      color={theme.secondaryFont}
                      isDark={isDark}
                    >
                      <Map
                        results={[...results.services, ...results.services_nearby]}
                      />
                    </MapSection>
                  )}

                  {/* {view === "Voice" && (
                    <VoiceResult
                      results={[...results.services, ...results.services_nearby]}
                      selected={selected}
                      setSelected={setSelected}
                      bg={theme.background}
                      stopSpeech={stopSpeech}
                    />
                  )} */}
                </>
              )}
            </>
          )}
        </section>
      </Main>
    </Layout>
  );
};

export default SearchResults;
