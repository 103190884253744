import React, { useRef, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useMutation } from "@apollo/client";
import qs from "query-string";

import dashboardLogo from "../../../assets/images/darkLogo.svg";
import loadIcon from "../../../assets/images/tail-spin.svg";

import { P, H6 } from "../../atoms/Texts";
import { OutlineButton, PrimaryButton } from "../../atoms/Buttons";
import { ThemeLessLayout } from "../../organisms/Layouts";
import { FormCard } from "../../atoms/Cards";
import { toastSuccess } from "../../../shared/helpers";
import { RESEND_VERFICATION_EMAIL } from "../../../shared/queries";

const VerifyEmailPage = () => {
  const { email } = useParams();
  const location = useLocation();
  const referralQuery = qs.parse(location?.search);

  const [isReffered] = useState(!!referralQuery?.isReferred);

  const [resendEmail, { loading }] = useMutation(RESEND_VERFICATION_EMAIL);

  const loader = useRef(null);

  const onResendEmail = async () => {
    if (loader) {
      loader.current.continuousStart();
    }

    resendEmail({
      variables: {
        email: email?.toLowerCase(),
      },
    })
      .then((data) => {
        if (data?.data?.sendVerificationEmail) {
          toastSuccess(data.data.sendVerificationEmail.message);
          return loader && loader.current?.complete();
        } else {
          throw new Error(data?.errors);
        }
      })
      .catch((error) => {
        console.warn(error);
        return loader && loader.current?.complete();
      });
  };

  return (
    <ThemeLessLayout className="flex">
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <section className="flex flex-col items-center">
        <Link to="/" className="flex">
          <img src={dashboardLogo} alt="SocialSafety" className="mb-12 block" />
        </Link>
        <div className="flex items-center">
          <FormCard className="px-4 md:px-10 py-8 md:py-14 mx-auto" maxW="476px">
            {isReffered ? (
              <H6 color="#22202D" fontSize="18px" className="mb-8">
                Referral accepted successfully.
              </H6>
            ) : (
              <H6 color="#22202D" fontSize="18px" className="mb-8">
                Account created successfully.
              </H6>
            )}
            <P color="#5F5D77">
              We sent an email to this email address
              <span
                className="semi mr-2 ml-2"
                style={{
                  color: "#22202D",
                }}
              >
                {email}
              </span>
              Verify your email to keep using Socialsafety.
            </P>
            <P color="#5F5D77" className="mt-4 mb-10">
              Click on the link in that email to verify your account. You may need to
              check your spam folder.
            </P>

            <PrimaryButton className="w-full" onClick={onResendEmail}>
              {!loading ? (
                "Don't see it? Resend"
              ) : (
                <img src={loadIcon} alt="Loading" className="mx-auto h-6 w-6" />
              )}
            </PrimaryButton>
            <Link to="/login">
              <OutlineButton
                className="w-full mt-4 block"
                color="#575B68"
                type="button"
              >
                Have an account?
                <span style={{ color: "#442ED0" }}> Sign in</span>
              </OutlineButton>
            </Link>
          </FormCard>
        </div>
      </section>
    </ThemeLessLayout>
  );
};

export default VerifyEmailPage;
