import { gql } from "@apollo/client";

export const SEARCH_SERVICES = gql`
  query GetSearchResults($text: String!, $zipCode: Int!) {
    SearchService(text: $text, zipCode: $zipCode) {
      id
      name
      status
      alternateName
      description
      url
      email
      interpretationServices
      applicationProcess
      waitTime
      fees
      accreditations
      licenses
      # parentTaxonomy
      taxonomy
      programs {
        id
        programName
        description
        overallCapacity
        currentlyServing
        autoScreen
      }
      organizations {
        id
        description
        name
        alternateName
        email
        url
        taxStatus
        taxId
        yearIncorporated
        legalStatus
      }
      requiredDocuments {
        id
        document
      }
      paymentsAccepted {
        id
        serviceId
        payment
      }
      eligibilities {
        id
        autoScreen
        eligibilityCriteria
        otherCriteria
      }
      locations {
        id
        name
        alternateName
        description
        transportation
        latitude
        longitude
        zipCode
        accessibiltyForDisabilities {
          id
          locationId
          accessibilty
          details
        }
        physicalAddresses {
          id
          locationId
          attention
          address
          city
          region
          stateProvince
          postalCode
          country
        }
        postalAddresses {
          id
          locationId
          attention
          address
          city
          region
          stateProvince
          postalCode
          country
        }
        languages {
          id
          serviceId
          locationId
          s
          requiredDocuments
        }
        phones {
          id
          number
          locationId
          serviceId
          organizationId
          contactId
          extension
          type
          requiredDocuments
          description
        }
        schedules {
          id
          serviceId
          locationId
          validFrom
          validTo
          dtstart
          timezone
          until
          count
          wkst
          freq
          interval
          byday
          byweekno
          bymonthday
          byyearday
          description
          opensAt
          closesAt
        }
      }
      fundings {
        id
        organizationId
        serviceId
        source
      }
      contacts {
        id
        organizationId
        serviceId
        name
        title
        department
        email
      }
      serviceAreas {
        id
        serviceId
        serviceArea
        description
      }
      languages {
        id
        serviceId
        locationId
        s
        requiredDocuments
      }
      phones {
        id
        number
        locationId
        serviceId
        organizationId
        contactId
        extension
        type
        requiredDocuments
        description
      }
      schedules {
        id
        serviceId
        locationId
        validFrom
        validTo
        dtstart
        timezone
        until
        count
        wkst
        freq
        interval
        byday
        byweekno
        bymonthday
        byyearday
        description
        opensAt
        closesAt
      }
    }
  }
`;
