import React, { useState, useEffect, useContext, Fragment } from "react";
import styled from "styled-components";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import * as Icon from "react-feather";

import { themeContext } from "../../../shared/store/contexts/context.theme";
import { Layout } from "../../organisms/Layouts";
import { P, H2, H3 } from "../../atoms/Texts";

import { useMutation, useQuery } from "@apollo/client";
import { GET_SERVICE_BY_ID } from "../../../shared/queries";
import { SmallEditIcon, SmallShareIcon } from "../../atoms/CustomIcons";
import { Badge, PrimaryButton, TabButton } from "../../atoms/Buttons";
import { decodeToken, toastError, triggerShare } from "../../../shared/helpers";
import { SAVE_SERVICE_MUTATION } from "../../../shared/queries";
import { authContext } from "../../../shared/store/contexts";
import SuggestServiceEditModal from "../../organisms/SuggestServiceEditModal";
import ReferServiceModal from "../../organisms/ReferServiceModal";
import useService from "../../../shared/hooks/useService";
import ShareServiceModal from "../../organisms/ShareServiceModal";

const Main = styled.main`
  min-height: 100vh;
  max-width: 100vw;
`;

const SpecificServicePage = () => {
  const { id } = useParams();
  const { theme } = useContext(themeContext);
  const { auth } = useContext(authContext);
  let history = useHistory();

  const [service, setService] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReferServiceModal, setShowReferServiceModal] = useState(false);
  const [eligibilities, setEligibilities] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);

  // Format eligibilities

  const formatEligibilities = (_eligibilities) => {
    const arr = _eligibilities.split(",");
    let list = [];

    if (arr.length > 1) {
      arr.forEach((item) => {
        const trimmedWord = item.trim();
        const separated_words = trimmedWord
          .split(/(?=[A-Z])/)
          .join(" ")
          .toLowerCase()
          .replace(trimmedWord[0], trimmedWord[0].toUpperCase());

        list.push(separated_words);
      });
    } else {
      const trimmedWord = arr[0].trim();
      const formatted_word = trimmedWord.replace(
        trimmedWord[0],
        trimmedWord[0].toUpperCase(),
      );

      list.push(formatted_word);
    }

    return list;
  };

  // Get saved services
  const { isServiceSaved, getSavedServices, removeServiceFromFavorites } =
    useService();

  useEffect(() => {
    getSavedServices(auth?.user?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.id]);

  // Remove service from favourites
  const removeFromFavourites = () => {
    if (!decodeToken(auth.user.id)) {
      setRedirectToLogin(true);
      return;
    }
    setIsSaved(false);

    removeServiceFromFavorites({
      userId: auth?.user?.id,
      serviceId: id,
    });
    setIsSaved(isServiceSaved(id));
  };

  // Check if service is saved

  useEffect(() => {
    setIsSaved(isServiceSaved(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.savedServices]);

  // Get Service by ID query
  const { data, loading, errors } = useQuery(GET_SERVICE_BY_ID, {
    variables: {
      serviceId: id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data?.getServiceById) {
      setService(data?.getServiceById);
      setEligibilities(formatEligibilities(data?.getServiceById?.eligibilities));
    }
  }, [data]);

  useEffect(() => {
    if (errors) console.log(errors);
    return () => {};
  }, [errors]);

  const handleShare = () => {
    triggerShare({
      text: service.name,
      title: "Social Safety Services",
      url: history.location.pathname,
    });
  };

  const desktopShare = () => {
    setShowShareModal(true);
  };

  // Save A Service Mutation

  const [saveService] = useMutation(SAVE_SERVICE_MUTATION);

  const onSaveService = () => {
    if (!decodeToken(auth.user.id)) {
      setRedirectToLogin(true);
      return;
    }
    setIsSaved(true);
    saveService({
      variables: {
        data: {
          name: service.name,
          description: service.description,
          serviceId: service.id,
          userId: auth.user.id,
          organizationName: service.organization?.name,
          opCategory: service.opCategory,
        },
      },
    })
      .then(() => {
        getSavedServices(auth?.user?.id);
      })
      .catch(() => {
        getSavedServices(auth?.user?.id);
        setIsSaved(isServiceSaved(id));
        toastError("Couldn't save service, please try again.");
      });
  };

  // Suggest Service Edit

  const onSuggestEdit = () => {
    if (!decodeToken(auth.user.id)) {
      setRedirectToLogin(true);
      return;
    }
    setShowEditModal(true);
  };
  // Refer Service Edit

  const onReferService = () => {
    if (!decodeToken(auth.user.id)) {
      setRedirectToLogin(true);
      return;
    }
    setShowReferServiceModal(true);
  };

  return (
    <Layout>
      {/* Redirect to Login if triggered */}
      {redirectToLogin && (
        <Redirect
          push
          to={{
            pathname: "/login",
            state: { from: history.location },
          }}
        />
      )}
      {/* Page content */}
      <Main className="container mx-auto">
        <section className=" pt-6 pb-10 md:py-24">
          {loading ? (
            <>
              <div className="mb-8">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
              </div>
            </>
          ) : (
            <>
              {service?.id && (
                <>
                  <H2 fontSize="32px" color={theme.primaryFont} className="mb-4">
                    {service.name}
                  </H2>
                  {service.organization?.name && (
                    <P color={theme.primaryFont} className="mb-4">
                      by{" "}
                      <Link
                        to={`/organization/details/${service.organization?.id}`}
                        style={{
                          color: theme.purpleFont,
                        }}
                      >
                        {service.organization?.name}
                      </Link>
                    </P>
                  )}
                  <div className="md:flex justify-between">
                    <div className="lg:w-2/3">
                      <div className="lg:w-4/5">
                        <P color={theme.primaryFont} className="mb-6">
                          {service?.description}
                        </P>
                        {service.opCategory && (
                          <div className="mb-6">
                            <div className="flex items-center mb-2">
                              <Icon.Tag
                                color={theme.primaryFont}
                                size={14}
                                className="transform rotate-90 mr-2 block"
                              />
                              <P color={theme.primaryFont} fontFamily="medium">
                                Category
                              </P>
                            </div>
                            <P color={theme.purpleFont} className="capitalize">
                              {service.opCategory}
                            </P>
                          </div>
                        )}
                        {eligibilities?.length && (
                          <div className="mb-4">
                            <div className="flex items-center mb-2">
                              <Icon.User
                                color={theme.primaryFont}
                                size={14}
                                className="mr-2 block"
                              />
                              <P color={theme.primaryFont} fontFamily="medium">
                                Eligibilty
                              </P>
                            </div>
                            {eligibilities?.map((eligibility, idx) => (
                              <Badge key={idx} className="mr-4 cursor-default mb-4">
                                <P color={theme.purpleFont}>{eligibility}</P>
                              </Badge>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="lg:w-1/3 lg:pl-8  md:border-l"
                      style={{
                        borderLeftColor: "#E6E5F1",
                        height: "fit-content",
                      }}
                    >
                      <P
                        className="mb-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Next steps:
                      </P>
                      <PrimaryButton
                        className="flex items-center justify-center mr-4"
                        onClick={onReferService}
                        w="250px"
                        smallW="100%"
                      >
                        <Icon.Mail size={16} className="mr-2" />
                        <span className="">Refer</span>
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center mt-4 mb-10">
                    <TabButton
                      className="flex items-center mr-4"
                      onClick={isSaved ? removeFromFavourites : onSaveService}
                    >
                      <Icon.Star
                        color={theme.purpleFont}
                        fill={isSaved ? theme.purpleFont : "none"}
                        size={18}
                        className="md:mr-2"
                      />
                      <span className="hidden md:block">
                        {isSaved ? "Saved" : "Save"}
                      </span>
                    </TabButton>
                    {/* Share Desktop */}
                    <TabButton
                      className="hidden md:flex items-center mr-4"
                      onClick={desktopShare}
                    >
                      <SmallShareIcon className="md:mr-2" />
                      <span className="hidden md:block">Share</span>
                    </TabButton>

                    {/* Share Mobile */}
                    <TabButton
                      className="flex md:hidden items-center mr-4"
                      onClick={handleShare}
                    >
                      <SmallShareIcon className="md:mr-2" />
                    </TabButton>

                    <TabButton
                      className="flex items-center mr-4"
                      onClick={onSuggestEdit}
                    >
                      <SmallEditIcon className="md:mr-2" />
                      <span className="hidden md:block">Suggest edits</span>
                    </TabButton>
                  </div>

                  {service.programs?.length > 0 && (
                    <>
                      <H3 color={theme.primaryFont} className="">
                        Programs
                      </H3>
                      {service.programs?.map((program, index) => (
                        <div key={index} className="md:flex mb-6">
                          <Link to="/service/details" className="block mr-4">
                            <Badge>{program.name}</Badge>
                          </Link>
                        </div>
                      ))}
                    </>
                  )}
                  {service.services?.length > 0 && (
                    <>
                      <H3 color={theme.primaryFont} className="mt-8 mb-4">
                        Services
                      </H3>
                      {service.services?.map((service, index) => (
                        <div key={index} className="md:flex mb-6">
                          <Link to="/service/details" className="block mr-4">
                            <Badge>{service.name}</Badge>
                          </Link>
                        </div>
                      ))}
                    </>
                  )}

                  {((service.email && service.email !== "None") ||
                    service.contacts?.length > 0 ||
                    service.locations?.length > 0 ||
                    (service.phones?.length > 0 &&
                      service.phones?.[0]?.number !== "None") ||
                    (service.url && service.url !== "None")) && (
                    <H3 color={theme.primaryFont} className="mt-8 mb-4">
                      Contact Details
                    </H3>
                  )}
                  {service.email && service.email !== "None" && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Email
                      </P>
                      <div className="md:w-4/5">
                        <a href={`mailto:${service.email}`} className="">
                          <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                            {service.email}
                          </P>
                        </a>
                      </div>
                    </div>
                  )}
                  {service.contacts?.length > 0 && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Contacts
                      </P>
                      <div className="md:w-4/5">
                        {service.contacts.map((contact, index) => (
                          <Fragment key={index}>
                            {contact.name && (
                              <P
                                color={theme.secondaryFont}
                                key={index}
                                className="mb-2"
                              >
                                {contact.title
                                  ? contact.title && ", " + contact.name
                                  : contact.name}
                              </P>
                            )}
                            {contact.department && (
                              <P
                                color={theme.secondaryFont}
                                key={index}
                                className="mb-2"
                              >
                                {contact.department}
                              </P>
                            )}
                            {contact.email && (
                              <a
                                href={`mailto:${contact.email}`}
                                className="block"
                                style={{ width: "fit-content" }}
                              >
                                <P color={theme.purpleFont} className="mb-2">
                                  {contact.email}
                                </P>
                              </a>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  )}
                  {service.locations?.length > 0 && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Locations
                      </P>
                      <div className="md:w-4/5">
                        {service.locations.map((location, index) => (
                          <React.Fragment key={index}>
                            <P color={theme.secondaryFont} className="mb-2">
                              {location.name}
                            </P>
                            {location?.address && (
                              <P color={theme.secondaryFont} className="mb-2">
                                {`${location.address}, ${location?.stateOrProvince} ${location?.postalCode}`}
                              </P>
                            )}
                            {location?.country && (
                              <P color={theme.secondaryFont} className="mb-2">
                                {location?.country}.
                              </P>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  )}
                  {service.phones?.length > 0 &&
                    service.phones?.[0]?.number !== "None" && (
                      <div className="md:flex mb-6">
                        <P
                          className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                          color={theme.primaryFont}
                          fontFamily="semi"
                        >
                          Phones
                        </P>
                        <div className="md:w-4/5">
                          {service.phones.map((phone, index) => (
                            <a
                              key={index}
                              href={`tel:+${phone.extension}${phone.number}`}
                            >
                              <P color={theme.secondaryFont} className="mb-2">
                                +({phone.extension}){phone.number}
                              </P>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  {service.url && service.url !== "None" && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Visit website
                      </P>
                      <div className="md:w-4/5">
                        <a
                          href={service.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          // className="block my-5"
                        >
                          <P color={theme.purpleFont} className="mb-4">
                            {service.url}
                          </P>
                        </a>
                      </div>
                    </div>
                  )}

                  {service.opCategory && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Open Eligibility Category
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.opCategory}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.subOpCategory && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Open Eligibility SubCategory
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.subOpCategory}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.fees && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                        title="Employer Identification Number"
                      >
                        Fees
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.fees}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.waitTime && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Wait Time
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.waitTime}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.applicationProcess && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Application Process
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.applicationProcess}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.interpretationServices && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Interpretation Services
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.interpretationServices}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.paymentsAccepted && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Payments Accepted
                      </P>
                      <div className="md:w-2/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.paymentsAccepted}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.requiredDocuments && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Required Documents
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.requiredDocuments}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.accreditations && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Accreditations
                      </P>
                      <div className="md:w-4/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.accreditations}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.licenses && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Licenses
                      </P>
                      <div className="md:w-2/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.licenses}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.fundings && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Fundings
                      </P>
                      <div className="md:w-2/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.fundings}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.serviceAreas && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Service Areas
                      </P>
                      <div className="md:w-2/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.serviceAreas}
                        </P>
                      </div>
                    </div>
                  )}
                  {service.languages && (
                    <div className="md:flex mb-6">
                      <P
                        className="md:w-1/5 mb-2 md:mb-0 md:mr-4"
                        color={theme.primaryFont}
                        fontFamily="semi"
                      >
                        Languages
                      </P>
                      <div className="md:w-2/5">
                        <P color={theme.secondaryFont} className="mb-4 md:mb-0">
                          {service.languages}
                        </P>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </section>
      </Main>
      {showEditModal && (
        <SuggestServiceEditModal
          closeModal={() => setShowEditModal(false)}
          service={service}
        />
      )}
      {showReferServiceModal && (
        <ReferServiceModal
          closeModal={() => setShowReferServiceModal(false)}
          service={service}
        />
      )}
      {showShareModal && (
        <ShareServiceModal
          closeModal={() => setShowShareModal(false)}
          service={service}
        />
      )}
    </Layout>
  );
};

export default SpecificServicePage;
