import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/index.css";
import "./App.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client/react";
import { BrowserRouter as Router } from "react-router-dom";
import { client } from "./shared/store/contexts/context.apollo.utils";
import { AuthProvider } from "./shared/store/contexts";
import { LocationProvider } from "./shared/store/contexts/context.location";

import posthog from "posthog-js";

posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
  api_host: "https://us.i.posthog.com",
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <ApolloProvider client={client}>
          <LocationProvider>
            <App />
          </LocationProvider>
        </ApolloProvider>
      </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

serviceWorker.unregister();
