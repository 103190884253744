import { gql } from "@apollo/client";

export const LOGIN_QUERY = gql`
  query LoginUser($email: String!, $password: String!) {
    LoginUser(email: $email, password: $password) {
      token
      user {
        id
        firstName
        lastName
        country
        avatarUrl
        isDeleted
        email
        gender
        employmentStatus
        disability
        dob
        housingStatus
        incomeLevel
        insurance
        household
        createdAt
        updatedAt
        phone
        ext
        verified
        completeFields
        incompleteFields
      }
    }
  }
`;
