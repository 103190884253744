import React, { useContext } from "react";
import { Link, Route } from "react-router-dom";

import { CustomSwitch } from "../../../shared/routing";
import { authContext, themeContext } from "../../../shared/store/contexts";
import { P } from "../../atoms/Texts";
import SettingsNavBar from "../../molecules/SettingsNavBar";
import PageHero from "../../organisms/PageHero";
import PasswordUpdate from "../../templates/ProfileForms/form.password.update";
import ProfileUpdate from "../../templates/ProfileForms/form.profile.update";
import APIKeysPage from "../../templates/ApiKeys";
import AccountSettings from "../../templates/AccountSettings";

const SettingsPage = () => {
  const { theme } = useContext(themeContext);

  const { auth } = useContext(authContext);

  return (
    <section>
      <PageHero title="Settings" description="Manage account settings" />
      <main className="container py-10">
        {!auth?.user?.verified && (
          <div
            className="rounded-sm lg:w-3/5 flex items-center mb-4 py-4"
            style={{ backgroundColor: "#FFF4E5" }}
          >
            <P color="#663C02" className="pl-3 font-medium">
              You have not verified your account, as such some services are
              restricted. To verify your account, click on the verification link sent
              to your email on signup. Cannot find the verification email? First
              check your spam or request a new one by clicking this{" "}
              <Link
                to="/dashboard/settings/account"
                style={{ color: "#12046D" }}
                className="hover:underline font-medium"
              >
                link
              </Link>
              .
            </P>
          </div>
        )}
        <div>
          <SettingsNavBar theme={theme} />

          <CustomSwitch>
            <Route path="/dashboard/settings/account">
              <AccountSettings />
            </Route>
            <Route path="/dashboard/settings/apikeys">
              <APIKeysPage />
            </Route>
            <Route path="/dashboard/settings/password-update">
              <PasswordUpdate />
            </Route>
            <Route
              exact
              path={["/dashboard/settings", "/dashboard/settings/profile"]}
            >
              <ProfileUpdate />
            </Route>
          </CustomSwitch>
        </div>
      </main>
    </section>
  );
};

export default SettingsPage;
