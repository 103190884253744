import React from "react";
import { P } from "../Texts";
import { DarkFormFieldStyle, DarkFormTextArea } from "./input.styles";

export const DarkTextArea = React.forwardRef(
  (
    {
      className,
      containerClass,
      icon,
      iconClick,
      iconBefore,
      iconBeforeClass,
      iconBeforeClick,
      id,
      inputClassName,
      label,
      name,
      placeholder,
      required,
      rows,
      defaultValue,
      columns,
    },
    ref,
  ) => {
    return (
      <div className={`mb-6 ${containerClass}`}>
        {label && (
          <label htmlFor={id}>
            <P fontSize="14px" fontFamily="semi" color="#47495C" className="mb-2">
              {label}
              {required && (
                <span
                  style={{
                    color: "#E62250",
                  }}
                >
                  *
                </span>
              )}
            </P>
          </label>
        )}

        <DarkFormFieldStyle className={className}>
          {iconBefore && (
            <div
              className="h-full flex-shrink-0 py-2 px-4"
              onClick={iconBeforeClick}
            >
              <img src={iconBefore} alt="" className={iconBeforeClass} />
            </div>
          )}

          <DarkFormTextArea
            ref={ref}
            rows={rows}
            id={id}
            name={name}
            defaultValue={defaultValue}
            className={`flex-grow ${inputClassName}`}
            placeholder={placeholder}
          />
          {icon && (
            <div
              className="h-full flex-shrink-0 py-2 px-4 cursor-pointer"
              onClick={iconClick}
            >
              <img src={icon} alt="" />
            </div>
          )}
        </DarkFormFieldStyle>
      </div>
    );
  },
);
