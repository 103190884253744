import { gql } from "@apollo/client";

export const SEARCH_ORGANIZATIONS = gql`
  query GetOrgainzationResults(
    $text: String!
    $filter: [FilterOrg]
    $from: Int
    $size: Int
    $zipCode: String
    $latitude: Float
    $longitude: Float
  ) {
    searchOrganization(
      text: $text
      filter: $filter
      from: $from
      size: $size
      zipCode: $zipCode
      latitude: $latitude
      longitude: $longitude
    ) {
      id
      description
      name
      tagline
      alternateName
      email
      url
      ein
      deductibility
      subsection
      assetAmount
      nteeType
      incomeAmount
      nteeSuffix
      filingRequirement
      classification
      latest990
      rulingDate
      nteeCode
      groupName
      deductibilityCode
      affiliation
      foundationStatus
      nteeClassification
      accountingPeriod
      exemptOrgStatus
      deductibilityDetail
      exemptOrgStatusCode
      nteeLetter
      programs {
        name
      }
      services {
        name
      }
      contacts {
        name
        email
        title
      }
      locations {
        latitude
        longitude
        address
        city
        region
        stateOrProvince
        zipCode
        country
        distance
      }
      phones {
        number
      }
    }
  }
`;
