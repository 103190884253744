import { gql } from "@apollo/client";

export const SIGNUP_MUTATION = gql`
  mutation RegisterUser($data: registerDetails!) {
    RegisterUser(data: $data) {
      user {
        firstName
        lastName
        email
      }
      token
    }
  }
`;
