/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";

import { themeContext } from "../../../shared/store/contexts";

import noVoiceImg from "../../../assets/images/no-voice-img.svg";
import { P, H6, H3 } from "../../atoms/Texts";
// import { useQuery } from "@apollo/client";
import { CanvasStyle } from "./styles";
// import { SEARCH_SERVICEs } from "../../shared/queries";

let voices;

const timer = setInterval(function () {
  voices = speechSynthesis.getVoices();
  if (voices.length !== 0) {
    clearInterval(timer);
  }
  return voices;
}, 200);

const Canvas = React.forwardRef((_props, ref) => {
  const { theme } = useContext(themeContext);

  return <CanvasStyle ref={ref} bg={theme.background} />;
});

const VoiceResult = (props) => {
  const canvas = useRef(null);
  let history = useHistory();

  const { theme } = useContext(themeContext);
  const [canSpeak, setCanSpeak] = useState(false);

  useEffect(() => {
    if (
      "speechSynthesis" in window &&
      ("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
    ) {
      // let SpeechRecognition =
      //   window.SpeechRecognition || window.webkitSpeechRecognition;
      // let recognition = new SpeechRecognition();
      GetSpeech();
    }
  }, [theme]);

  const { selected } = props;

  // const [background, setBackground] = useState(theme.background);
  const [speech] = useState(true);
  const [results] = useState([...new Set(props.results)]);
  const [stopSpeech, setStopSpeech] = useState(false);

  console.log(results);

  let synth = window.speechSynthesis;
  let voice = voices[1];

  // useEffect(() => {
  //   setBackground(theme.background)
  // }, [theme])

  let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  let SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;

  let otherResults = [];

  for (let index = 0; index < results.length - 1; index++) {
    const result = results[index];
    otherResults.push(`${result.name} `);
  }

  // DEFINE RESULTS
  let accepted_words = [];
  results.map((result) => accepted_words.push(result.name.toLowerCase()));
  console.log(accepted_words);

  // let grammar = '#JSGF V1.0; grammar accepted_words; public <word> = ' + accepted_words.join(' | ') + ' ;'
  let grammar =
    "#JSGF V1.0; grammar words; public <word> = " +
    accepted_words.join(" | ") +
    " ;";

  // PLUG THE GRAMMAR INTO THE SPEECH RECOGNITION
  let recognition = new SpeechRecognition();
  let speechRecognitionList = new SpeechGrammarList();

  speechRecognitionList.addFromString(grammar, 1);

  // SPEECH PROPERTIES
  recognition.grammars = speechRecognitionList;
  recognition.continuous = false;
  recognition.lang = "en-US";
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;

  useEffect(() => {
    if (
      "speechSynthesis" in window &&
      ("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
    ) {
      // SPEECH SYNTHESIS FOR RESULTS
      setCanSpeak(true);
      let otherResults = [];

      for (let index = 0; index < results.length - 1; index++) {
        const result = results[index];
        otherResults.push(`${result.name}, `);
      }

      let resultString = otherResults.toLocaleString();
      console.log(resultString);

      let words = new SpeechSynthesisUtterance(`
        The results for your search are 
        ${resultString} and ${results?.[results.length - 1].name}.
        Which would you like to use?
      `);
      words.rate = 0.7;
      words.voice = voice;

      synth.speak(words);

      // call startSpeech after reading the results
      words.onend = () => {
        startSpeech();
      };
    } else {
      setCanSpeak(false);
    }

    return () => {
      if (
        "speechSynthesis" in window &&
        ("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
      ) {
        // console.log("speech exists")
        setStopSpeech(true);
        // console.log("stopped speech and recognition")
        // let synth = window.speechSynthesis;
        // let SpeechRecognition =
        // window.SpeechRecognition || window.webkitSpeechRecognition;

        // PLUG THE GRAMMAR INTO THE SPEECH RECOGNITION
        // let recognition = new SpeechRecognition();

        // STOPPING SPEECH RECOGNITION
        recognition.stop();
        synth.cancel();
        console.log("removed voice");
      }
    };
  }, []);

  const startSpeech = () => {
    if (
      "speechSynthesis" in window &&
      ("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
    ) {
      console.log("started");

      // let resultString = otherResults.toLocaleString();

      // let SpeechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent

      // start recognition
      recognition.start();

      // This will run when the speech recognition service returns a result
      recognition.onstart = function () {
        GetSpeech();
      };

      // HANDLING SPEECH RESULT
      recognition.onresult = function (event) {
        let result = event.results[0][0].transcript.toLowerCase();
        if (accepted_words.includes(result)) {
          // setSelected(result);
          history.push(`/organization/details/${result.id}`);
          // suggestEdit();
          console.log("accepted", result);
        } else {
          console.log(result);
          let words = new SpeechSynthesisUtterance(`
            None of the results match your reply, please try again.
          `);
          words.rate = 1.2;
          words.voice = voice;

          synth.speak(words);

          // call startSpeech after reading the results
          words.onend = () => {
            recognition.start();
          };
        }
      };

      // HANDLING NO MATCH
      recognition.onnomatch = function (_event) {
        console.log("I didnt recognise that result.");
        let words = new SpeechSynthesisUtterance(`
          None of the results match your reply, please try again. The available results are
          ${props.results[0].title},
          ${props.results[1].title}
          and ${props.results[2].title}. Which would you like to use?
        `);
        words.rate = 1.2;
        words.voice = voice;

        synth.speak(words);

        // call startSpeech after reading the results
        words.onend = () => {
          recognition.start();
        };
      };

      // HANDLING ERRORS
      recognition.onerror = (error) => {
        const speak = (words) => {
          words.rate = 1.2;
          words.voice = voice;

          synth.speak(words);

          // call startSpeech after reading the results
          words.onend = () => {
            recognition.start();
          };
        };

        if (error.error === "network") {
          let words = new SpeechSynthesisUtterance(`
            You seem to have network issues, please check your connection and try again
          `);
          speak(words);
        } else if (error.error === "no-speech") {
          let words = new SpeechSynthesisUtterance(`
            Couldn't capture what you said, please try again.
          `);
          speak(words);
        }
        console.log(error);
      };

      // STOPPING SPEECH RECOGNITION
      recognition.onspeechend = function () {
        recognition.stop();
        console.log("stopped recognition");
      };

      console.log("STOPSPEECH =>>", stopSpeech);
      if (stopSpeech) {
        recognition.stop();
        console.log("stopped recognition");
      }
    }
  };

  // const suggestEdit = () => {
  //   if (
  //     "speechSynthesis" in window &&
  //     ("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
  //   ) {
  //     console.log("started");
  //     let SpeechRecognition =
  //       window.SpeechRecognition || window.webkitSpeechRecognition;
  //     let SpeechGrammarList =
  //       window.SpeechGrammarList || window.webkitSpeechGrammarList;

  //     // DEFINE RESULTS
  //     let accepted_words = ["yes", "no"];

  //     // let grammar = '#JSGF V1.0; grammar accepted_words; public <word> = ' + accepted_words.join(' | ') + ' ;'
  //     let grammar =
  //       "#JSGF V1.0; grammar words; public <word> = " +
  //       accepted_words.join(" | ") +
  //       " ;";

  //     // PLUG THE GRAMMAR INTO THE SPEECH RECOGNITION
  //     let recognition = new SpeechRecognition();
  //     let speechRecognitionList = new SpeechGrammarList();

  //     speechRecognitionList.addFromString(grammar, 1);

  //     // SPEECH PROPERTIES
  //     recognition.grammars = speechRecognitionList;
  //     recognition.continuous = false;
  //     recognition.lang = "en-US";
  //     recognition.interimResults = false;
  //     recognition.maxAlternatives = 1;

  //     let words = new SpeechSynthesisUtterance(`
  //       Is this entry wrong, would you like to suggest an edit?
  //     `);
  //     words.rate = 1.2;
  //     words.voice = voice;

  //     synth.speak(words);

  //     // start recognition after reading the results
  //     words.onend = () => {
  //       // start recognition
  //       recognition.start();
  //     };

  //     // HANDLING SPEECH RESULT
  //     recognition.onresult = function (event) {
  //       let result = event.results[0][0].transcript;
  //       if (accepted_words.includes(result)) {
  //         console.log("accepted", result);

  //         // redirect to suggest entry if the user says yes
  //         if (result === "yes") {
  //           history.push("/suggest");
  //           recognition.stop();
  //           return;
  //         } else {
  //           console.log(result);
  //           let words = new SpeechSynthesisUtterance(`
  //             Alright then, thank you for your feedback.
  //           `);
  //           words.rate = 1.2;
  //           words.voice = voice;

  //           synth.speak(words);

  //           // call startSpeech after reading the results
  //           words.onend = () => {
  //             setSpeech(false);
  //             recognition.stop();
  //             return;
  //           };
  //           return;
  //         }
  //       }
  //       console.log(result);
  //       let words = new SpeechSynthesisUtterance(`
  //         Please say either yes or no.
  //         Is this entry wrong, would you like to suggest an edit?
  //       `);
  //       words.rate = 1.2;
  //       words.voice = voice;

  //       synth.speak(words);

  //       // call startSpeech after reading the results
  //       words.onend = () => {
  //         recognition.start();
  //       };
  //     };

  //     // HANDLING NO MATCH
  //     // recognition.onnomatch = function(event) {
  //     //   console.log('I didnt recognise that result.');
  //     //   let words = new SpeechSynthesisUtterance(`
  //     //    Please say either yes or no.
  //     //   `);
  //     //   words.rate = 1.2  ;
  //     //   words.voice = voice;

  //     //   synth.speak(words);

  //     //   // call startSpeech after reading the results
  //     //   words.onend = () => {
  //     //     recognition.start();
  //     //   }

  //     // }

  //     // HANDLING ERRORS
  //     recognition.onerror = (error) => {
  //       const speak = (words) => {
  //         words.rate = 1.2;
  //         words.voice = voice;

  //         synth.speak(words);

  //         // call startSpeech after reading the results
  //         words.onend = () => {
  //           recognition.start();
  //         };
  //       };

  //       if (error.error === "network") {
  //         let words = new SpeechSynthesisUtterance(`
  //           You seem to have network issues, please check your connection and try again.
  //           Would you like to suggest an edit?
  //         `);
  //         speak(words);
  //       } else if (error.error === "no-speech") {
  //         let words = new SpeechSynthesisUtterance(`
  //           Couldn't capture what you said, would you like to suggest an edit?.
  //         `);
  //         speak(words);
  //       } else {
  //         let words = new SpeechSynthesisUtterance(`
  //           An error occured, would you like to suggest an edit?
  //         `);
  //         speak(words);
  //       }
  //       console.log(error);
  //     };

  //     // STOPPING SPEECH RECOGNITION
  //     recognition.onspeechend = function () {
  //       recognition.stop();
  //       console.log("stopped recognition");
  //     };

  //     // if (stopSpeech) {
  //     //   recognition.stop();
  //     //   console.log("stopped recognition")
  //     // }
  //   }
  // };

  const GetSpeech = () => {
    console.log("Voice recognition started. Try speaking into the microphone.");
    // CHECKING FOR THE MIC
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((mediaStream) => {
        // GETTING SOUND FROM MIC
        let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        // let distortion = audioCtx.createWaveShaper();
        let analyser = audioCtx.createAnalyser();
        let source = audioCtx.createMediaStreamSource(mediaStream);
        source.connect(analyser);
        // analyser.connect(distortion);
        // distortion.connect(audioCtx.destination);

        // CALCULATING THE BANDS
        analyser.fftSize = 2048 / 2;
        let bufferLength = analyser.frequencyBinCount;
        let dataArray = new Uint8Array(bufferLength);
        analyser.getByteTimeDomainData(dataArray);

        console.log(analyser);
        console.log(dataArray);

        let canvasCtx = canvas.current.getContext("2d");
        let WIDTH = canvas.current.width;
        let HEIGHT = canvas.current.height;
        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

        const draw = () => {
          if (canvas) {
            // let drawVisual = requestAnimationFrame(draw);
            analyser.getByteTimeDomainData(dataArray);

            canvasCtx.fillStyle = theme.background;
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = theme.purpleFont;
            canvasCtx.beginPath();

            let sliceWidth = (WIDTH * 1.0) / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
              let v = dataArray[i] / 128.0;
              let y = (v * HEIGHT) / 2;

              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
              }

              x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
          }
        };

        draw();
      })
      .catch((err) => console.warn(err));
  };

  return (
    <>
      {canSpeak ? (
        <section className="w-full px-4 md:px-0 md:w-4/5 lg:w-3/5 mx-auto ">
          {!selected && (
            <div className="flex flex-wrap items-center justify-center mb-8 md:mb-20">
              {[...new Set(props.results)].map((result, idx) => (
                <H6
                  key={idx}
                  color={theme.purpleFont}
                  className="w-full md:w-auto mb-4 md:mb-0 md:mr-4"
                  fontSize="18px"
                >
                  {result.name}
                </H6>
              ))}
            </div>
          )}
          {selected ? (
            <P
              color={theme.primaryFont}
              className="mt-20 md:mt-0 mb-4 md:mb-0 text-center"
              fontSize="14px"
            >
              Suggest an edit?
            </P>
          ) : (
            <P
              color={theme.primaryFont}
              className="mb-4 md:mb-0 text-center"
              fontSize="14px"
            >
              Which would you like?
            </P>
          )}
        </section>
      ) : (
        <div className=" pt-20 flex flex-col items-center">
          <img src={noVoiceImg} alt="" className="" />
          <H3 color={theme.purpleFont} className="mt-10">
            Voice is not available
          </H3>
          <P
            fontSize="16px"
            color={theme.lightFont}
            className="mt-2 mb-0 text-center"
          >
            It seems your browser can't use the voice feature.
          </P>
        </div>
      )}

      {speech && <Canvas ref={canvas} bg={theme.background} />}
    </>
  );
};

export default VoiceResult;
