import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { P } from "../../atoms/Texts";

const NavStyle = styled.nav`
  border-bottom: 2px solid #f8f6fe;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CustomNavLink = styled(NavLink)`
  padding: 9px;
  color: #646a86;
  font-size: 16px;
  // margin-right: 40px;
  border-bottom: solid 2px transparent;
  positon: relative;

  &.active {
    font-weight: 600;
    // background: #442ed0;
    color: #442ed0;
    z-index: 2;
    border-bottom: solid 2px #442ed0;
  }
`;

const SettingsNavBar = ({ theme }) => {
  return (
    <NavStyle className="flex flex-no-wrap overflow-x-auto items-center">
      <CustomNavLink
        to={`/dashboard/settings/profile`}
        className="flex items-center"
        // data-testid="services-link"
        activeClassName="active"
        isActive={(match, location) => {
          if (location.pathname === "/dashboard/settings") {
            return true;
          }
          if (match) {
            return true;
          }
        }}
      >
        <div className="flex flex-grow">
          <P color="inherit" fontFamily="medium" className="mr-2 title">
            Profile
          </P>
        </div>
      </CustomNavLink>
      <CustomNavLink
        to={`/dashboard/settings/password-update`}
        className="flex items-center"
        data-testid="organization-link"
        activeClassName="active"
      >
        <div className="flex flex-grow">
          <P color="inherit" fontFamily="medium" className="mr-2 title">
            Password
          </P>
        </div>
      </CustomNavLink>
      <CustomNavLink
        to={`/dashboard/settings/apikeys`}
        className="flex items-center"
        data-testid="organization-link"
        activeClassName="active"
      >
        <div className="flex flex-grow">
          <P
            color="inherit"
            fontFamily="medium"
            className="mr-2 title whitespace-no-wrap"
          >
            API Keys
          </P>
        </div>
      </CustomNavLink>
      <CustomNavLink
        to={`/dashboard/settings/account`}
        className="flex items-center"
        data-testid="organization-link"
        activeClassName="active"
      >
        <div className="flex flex-grow">
          <P color="inherit" fontFamily="medium" className="mr-2 title">
            Account
          </P>
        </div>
      </CustomNavLink>
    </NavStyle>
  );
};

export default SettingsNavBar;
