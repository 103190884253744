export const calculatePageButtons = (presentPage, range) => {
  let lowerLimit;
  let upperLimit = presentPage + range;
  if (presentPage < range) {
    lowerLimit = presentPage;
  } else {
    lowerLimit = presentPage - range;
  }
  let pages = [];

  if (presentPage === 1) pages.push(0);

  for (let index = lowerLimit; index < presentPage; index++) {
    pages.push(index);
  }
  const highCap = upperLimit + 1;
  for (let index = presentPage; index < highCap; index++) {
    pages.push(index);
  }

  return pages;
};
