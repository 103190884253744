import React, { useContext, useRef } from "react";
import { useMutation } from "@apollo/client";
import LoadingBar from "react-top-loading-bar";

import loadIcon from "../../../assets/images/tail-spin.svg";

import { RESEND_VERFICATION_EMAIL } from "../../../shared/queries";
import { authContext, themeContext } from "../../../shared/store/contexts";
import { H6, P } from "../../atoms/Texts";
import { toastSuccess } from "../../../shared/helpers";
import { PrimaryButton } from "../../atoms/Buttons";

const VerifyAccount = () => {
  const { theme } = useContext(themeContext);
  const { auth } = useContext(authContext);

  const [resendEmail, { loading }] = useMutation(RESEND_VERFICATION_EMAIL);

  const loader = useRef(null);

  const onResendEmail = async () => {
    if (loader) {
      loader.current.continuousStart();
    }

    resendEmail({
      variables: {
        email: auth?.user?.email?.toLowerCase(),
      },
    })
      .then((data) => {
        if (data?.data?.sendVerificationEmail) {
          toastSuccess(data.data.sendVerificationEmail.message);
          return loader && loader.current?.complete();
        } else {
          throw new Error(data?.errors);
        }
      })
      .catch((error) => {
        console.warn(error);
        return loader && loader.current?.complete();
      });
  };

  return (
    <div className="mb-10">
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <H6 className="mb-2" color={theme.primaryFont}>
        Account Verfication
      </H6>
      <P color={theme.primaryFont} className="mb-0">
        If you haven&apos;t received your verification email, you can request for a
        new verification email.
      </P>
      <P color={theme.primaryFont} className="mb-4">
        Click on the link in that email to verify your account. You may need to check
        your spam folder.
      </P>
      <PrimaryButton.Light smallW="100%" onClick={onResendEmail}>
        {!loading ? (
          "Send Email"
        ) : (
          <img src={loadIcon} alt="Loading" className="mx-auto h-6 w-6" />
        )}
      </PrimaryButton.Light>
    </div>
  );
};

export default VerifyAccount;
