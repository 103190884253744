import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { themeContext } from "../../../shared/store/contexts";
import { H3, P } from "../../atoms/Texts";
import ExpandableDescription from "../../molecules/ExpandableDescription";

const ServiceCardStyle = styled.div`
  background: ${(props) => props.bg};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

const ServiceCard = ({ service }) => {
  const { theme } = useContext(themeContext);

  return (
    <ServiceCardStyle
      bg={theme.background}
      className="p-5 h-full flex flex-col justify-between"
    >
      <div>
        <H3
          fontSize="18px"
          smallSize="18px"
          color={theme.primaryFont}
          className="mb-2"
          fontFamily="medium"
        >
          {service?.name}
        </H3>
        {service?.organizationName && (
          <P color={theme.primaryFont} className="mb-4">
            by{" "}
            <span
              style={{
                color: theme.purpleFont,
              }}
            >
              {service.organizationName}
            </span>
          </P>
        )}
        {service?.description && (
          <ExpandableDescription
            ending="..."
            fontSize="14px"
            hideMoreOption
            length={50}
            text={service?.description}
            text_color={theme.lightFont}
          />
        )}
      </div>
      <div className="flex items-center justify-between">
        <Link
          key={service.id}
          to={`/service/details/${service.id}`}
          className="mt-8"
        >
          <P fontSize="15px" color={theme.purpleFont}>
            View
          </P>
        </Link>
      </div>
    </ServiceCardStyle>
  );
};

export default ServiceCard;
