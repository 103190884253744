import styled from "styled-components";

export const TopicCardStyle = styled.button`
  background: ${(props) => props.bg || "#FFFFFF"};
  border-radius: 4px;
  border: ${(props) =>
    (props.selected && props.selected === true
      ? "2px solid #442ECF"
      : "2px solid #E7E5F2") || props.border};
  transition: 0.3s ease-in;

  &:hover {
    border: ${(props) => props.hoverBorder || "2px solid #442ECF"};
  }

  &:focus {
    outline: none;
    border: 2px solid #442ecf;
  }
`;
