import React, { useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";

export const RedirectToLostPage = ({ CustomNotFoundPage }) => {
  const history = useHistory();
  useEffect(() => {
    history.replace("/404");
  }, [history]);
  return null;
};

export const CustomSwitch = (props) => {
  const { children } = props;
  return (
    <Switch>
      {children}
      <RedirectToLostPage />
    </Switch>
  );
};
