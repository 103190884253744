import React, { useState, useContext, useMemo } from "react";
import { Redirect } from "react-router-dom";

import UserLocationOrganizations from "../../templates/UserLocationOrganizations";
import { Layout } from "../../organisms/Layouts";
import { locationContext } from "../../../shared/store/contexts/context.location";

// const map_key = process.env.REACT_APP_MAP_KEY;

const OrganizationSearch = () => {
  const { location, isFetchingUserLocation } = useContext(locationContext);

  const [isUsingLocation, setIsUsingLocation] = useState("");
  const [zipCode, setZipCode] = useState("");

  useMemo(() => {
    if (isFetchingUserLocation) {
      setIsUsingLocation("");
    } else {
      console.log({ location });
      if (location?.latitude || location?.zipCode) {
        console.log("using location");
        setZipCode(location?.zipCode);
        setIsUsingLocation(true);
      } else {
        setIsUsingLocation(false);
      }
    }
  }, [isFetchingUserLocation, location]);

  return (
    <Layout>
      {isUsingLocation === true && (
        <UserLocationOrganizations
          zipCode={zipCode}
          latitude={location?.latitude}
          longitude={location?.longitude}
          setIsUsingLocation={setIsUsingLocation}
        />
      )}
      {isUsingLocation === false && <Redirect to="/organizations/all" />}
      {isUsingLocation === "" && (
        <section className="container mt-20 pb-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export default OrganizationSearch;
