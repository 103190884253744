import React, { useState, createContext } from "react";
import t from "prop-types";

export const notificationContext = createContext();

export const PopNotificationProvider = ({ children }) => {
  const _default_message = {
    variant: "default",
    message: "",
    customComponent: false,
    action: "",
    actionLinkHref: "",
  };

  const [isPopNotificationActive, setIsPopNotificationActive] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(_default_message);

  const togglePopNotification = (state) => {
    setIsPopNotificationActive(state);
  };

  return (
    <notificationContext.Provider
      value={{
        togglePopNotification,
        isPopNotificationActive,
        notificationMessage,
        setNotificationMessage,
      }}
    >
      {children}
    </notificationContext.Provider>
  );
};

PopNotificationProvider.propTypes = {
  children: t.node.isRequired,
};
