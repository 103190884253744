import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { themeContext } from "../../../shared/store/contexts";
import { H3 } from "../../atoms/Texts";
import ExpandableDescription from "../../molecules/ExpandableDescription";

const OrganizationCardStyle = styled.div`
  background: ${(props) => props.bg};
  box-shadow: 0px 8px 10px #0d024e05;
  border-radius: 4px;
`;

const OrganizationCard = ({ organization }) => {
  const { theme } = useContext(themeContext);

  return (
    <Link to={`/organization/details/${organization.id}`} className="block mb-6">
      <OrganizationCardStyle bg={theme.background} className="p-5 h-full">
        <H3
          fontSize="16px"
          smallSize="16px"
          color={theme.primaryFont}
          className="mb-5"
          fontFamily="medium"
        >
          {organization.name}
        </H3>
        <ExpandableDescription
          ending="..."
          fontSize="14px"
          hideMoreOption
          length={250}
          text={organization?.description}
          text_color="#716E8B"
        />
      </OrganizationCardStyle>
    </Link>
  );
};

export default OrganizationCard;
