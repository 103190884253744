import { gql } from "@apollo/client";

export const GET_ORGANIZATION_BY_ID = gql`
  query GetOrgainzationById($orgId: String!, $zipCode: Int) {
    searchOrganizationById(orgId: $orgId, zipCode: $zipCode) {
      id
      description
      name
      tagline
      alternateName
      email
      url
      ein
      deductibility
      subsection
      assetAmount
      nteeType
      incomeAmount
      nteeSuffix
      filingRequirement
      opCategory
      classification
      latest990
      rulingDate
      nteeCode
      groupName
      deductibilityCode
      affiliation
      foundationStatus
      nteeClassification
      accountingPeriod
      exemptOrgStatus
      deductibilityDetail
      exemptOrgStatusCode
      nteeLetter
      reviews
      rating
      programs {
        id
        name
        description
      }
      services {
        id
        name
        status
        alternateName
        description
        url
        email
        interpretationServices
        applicationProcess
        waitTime
        fees
        accreditations
        licenses
        subOpCategory
        opCategory
        program {
          id
          name
          description
        }
        organization {
          id
          description
          name
          tagline
          alternateName
          email
        }
        requiredDocuments
        paymentsAccepted
        eligibilities
        location {
          id
          name
          transportation
          latitude
          longitude
          zipCode
          accessibiltyForDisabilities
          languages
          address
          city
          region
          stateOrProvince
          postalCode
          country
          schedule
        }
        fundings
        contacts {
          id
          name
          title
          department
          email
        }
        serviceAreas
        languages
        phones {
          id
          number
          extension
          type
          language
          description
        }
        schedule {
          id
          #         serviceId
          #         locationId
          timezone
          description
          mon {
            startTime
            endTime
          }
          tue {
            startTime
            endTime
          }
          wed {
            startTime
            endTime
          }
          thur {
            startTime
            endTime
          }
          fri {
            startTime
            endTime
          }
          sat {
            startTime
            endTime
          }
          sun {
            startTime
            endTime
          }
        }
      }
      contacts {
        id
        name
        title
        department
        email
      }
      locations {
        id
        name
        transportation
        latitude
        longitude
        zipCode
        accessibiltyForDisabilities
        languages
        address
        city
        region
        stateOrProvince
        postalCode
        country
        schedule
      }
      phones {
        id
        number
        extension
        type
        language
        description
      }
    }
  }
`;
