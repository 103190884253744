import React from "react";
import styled from "styled-components";

import loadIcon from "../../../assets/images/tail-spin.svg";

const ModalHolderStyle = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const ModalContainerStyle = styled.div`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  width: 40%;
  height: fit-content;

  @media (max-width: 600px) {
    width: 80%;
  }
`;
const LoadingModal = ({ closeModal }) => {
  return (
    <ModalHolderStyle className="inset-0 fixed flex justify-center items-center pt-20">
      <ModalContainerStyle className="px-4 md:px-10">
        <img src={loadIcon} alt="Loading" className="mx-auto h-20 w-20" />
      </ModalContainerStyle>
    </ModalHolderStyle>
  );
};

export default LoadingModal;
