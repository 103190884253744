import styled from "styled-components";

export const BasicTextArea = styled.textarea`
  height: ${(props) => props.h};
  max-height: ${(props) => props.maxH};
  min-height: ${(props) => props.minH};
  width: ${(props) => props.w};
  max-width: ${(props) => props.maxW};
  min-width: ${(props) => props.minW};
  color: ${(props) => props.color};
  background: ${(props) => props.bg || "transparent"};
  padding: 16px;
  padding: ${(props) => props.p};
  font-family: ${(props) => props.fontFamily || "regular"};
  font-size: ${(props) => props.fontSize};
  transition: 0.3s ease-out;

  border: 1px solid ${(props) => props.borderColor || "#c3c5cc"};
  border-radius: 4px;

  &:placeholder {
    color: #9598a3;
  }

  &:focus {
    outline: none;
    border-color: #442ed0;
  }

  &:-internal-autofill-selected {
    color: ${(props) => props.color} !important;
    font: ${(props) => props.fontFamily || "regular"} !important;
  }

  // @media (max-width: 600px) {
  //   font-size: ${(props) => props.smallSize || "36px"};
  //   padding: ${(props) => props.smallSize && "20px 5px 10px"};
  // }

  @media (min-width: 1020px) {
    width: ${(props) => props.largeW};
  }
`;
