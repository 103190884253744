import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useService from "../../../shared/hooks/useService";

import { themeContext } from "../../../shared/store/contexts";
import { Badge, LinkButton, OutlineButton } from "../../atoms/Buttons";
import { H3, P } from "../../atoms/Texts";

const SavedServiceCardStyle = styled.div`
  background: ${(props) => props.bg};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

const SavedServiceCard = ({ service }) => {
  const { theme } = useContext(themeContext);

  // Remove service from favourites
  const { removeServiceFromFavorites } = useService();

  const onRemoveFromFavourites = () => {
    removeServiceFromFavorites({
      serviceId: service.serviceId,
    });
  };

  return (
    <SavedServiceCardStyle
      bg={theme.background}
      className="p-5 h-full flex flex-col justify-between"
    >
      <div>
        <H3
          fontSize="18px"
          smallSize="18px"
          color={theme.primaryFont}
          className="mb-2"
          fontFamily="medium"
        >
          {service?.name}
        </H3>
        {service?.organizationName && (
          <P color={theme.primaryFont} className="mb-4">
            by{" "}
            <span
              style={{
                color: theme.purpleFont,
              }}
            >
              {service.organizationName}
            </span>
          </P>
        )}
        {service?.opCategory && (
          <Badge
            borderRadius="0"
            fontFamily="medium"
            className="mb-4 cursor-default"
          >
            {service?.opCategory}
          </Badge>
        )}
      </div>
      <div className="flex items-center justify-between">
        <Link to={`/service/details/${service?.serviceId}`} className="block">
          <OutlineButton color={theme.purpleFont}>View</OutlineButton>
        </Link>
        <LinkButton color={theme.primaryFont} onClick={onRemoveFromFavourites}>
          Remove
        </LinkButton>
      </div>
    </SavedServiceCardStyle>
  );
};

export default SavedServiceCard;
