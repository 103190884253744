import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { Layout } from "../../organisms/Layouts";

import { useQuery } from "@apollo/client";
import { SEARCH_ORGANIZATIONS } from "../../../shared/queries";
import OrganizationResultWithoutZipCodeQuery from "../../templates/OrganizationResultWithoutZipCodeQuery";
import OrganizationsInZipCode from "../../templates/OrganizationsInZipCode";
import NearbyOrganizations from "../../templates/NearbyOrganizations";
import { locationContext } from "../../../shared/store/contexts/context.location";

const Main = styled.main`
  min-height: calc(100vh - 80px);
  max-width: 100vw;
`;

const OrganizationSearchResultsPage = (props) => {
  const { need, zipCode } = props.match.params;
  const { location, isFetchingUserLocation } = useContext(locationContext);
  // const [userCoordinates, setUserCoordinates] = useState({});
  const [results, setResults] = useState([]);

  const { data, loading } = useQuery(SEARCH_ORGANIZATIONS, {
    variables: {
      text: need,
      zipCode,
      from: 0,
      size: 10,
      latitude: zipCode ? null : location?.latitude,
      longitude: zipCode ? null : location?.longitude,
    },
  });

  useEffect(() => {
    setResults(data?.searchOrganization);
    return () => {
      setResults([]);
    };
  }, [data]);

  return (
    <Layout>
      <Main className="container mx-auto">
        <section className=" pt-6 pb-10 md:py-12">
          {loading || isFetchingUserLocation ? (
            <>
              <div className="mt-10 mb-8">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
              </div>
              <div className="mb-8">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
              </div>
            </>
          ) : (
            <>
              {!zipCode && !location?.latitude && !location?.longitude ? (
                <OrganizationResultWithoutZipCodeQuery />
              ) : (
                <>
                  {results?.length > 0 ? (
                    <OrganizationsInZipCode />
                  ) : (
                    <NearbyOrganizations />
                  )}
                </>
              )}
            </>
          )}
        </section>
      </Main>
    </Layout>
  );
};

export default OrganizationSearchResultsPage;
