import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { themeContext } from "../../../shared/store/contexts/context.theme";
import markerIcon from "../../../assets/images/markerIcon.svg";
import starIcon from "../../../assets/images/star.svg";
import { P, TextLink } from "../../atoms/Texts";
import { OutlineButton, PrimaryButton } from "../../atoms/Buttons";
import { toastError, toastWarning } from "../../../shared/helpers";
import {
  containerStyle,
  darkMapStyle,
  InfoWindowStyle,
  lightMapStyle,
} from "./map.styles";
import { locationContext } from "../../../shared/store/contexts/context.location";

const map_key = process.env.REACT_APP_MAP_KEY;

const MapResult = ({ theme, mapInfo, setDestination }) => {
  const [more, setMore] = useState(false);

  return (
    <div
      className="w-full h-full z-50 inset-0 pb-4"
      style={{
        background: theme.background,
      }}
    >
      <div className="flex">
        <div className="w-10/12">
          <P
            fontFamily="medium"
            color={theme.purpleFont}
            className="mb-4"
            fontSize="16px"
          >
            {mapInfo.name}
          </P>
          <P fontSize="14px" color={theme.secondaryFont}>
            {`${mapInfo.locations?.[0]?.address}, ${mapInfo.locations?.[0]?.stateOrProvince} ${mapInfo.locations?.[0]?.postalCode} ${mapInfo.locations?.[0]?.country}.`}
          </P>
          <a href={`tel:${mapInfo.phones?.[0]?.number}`}>
            <P fontSize="14px" color={theme.secondaryFont}>
              {mapInfo.phones?.[0]?.number}
            </P>
          </a>
          <a
            href={
              mapInfo?.email?.includes("mailto")
                ? mapInfo.email
                : `mailto:${mapInfo.email}`
            }
          >
            <P
              fontSize="14px"
              color={theme.secondaryFont}
              style={{
                overflowWrap: "anywhere",
              }}
            >
              {mapInfo.email}
            </P>
          </a>

          {mapInfo.url && (
            <a href={mapInfo.url} target="_blank" rel="noopener noreferrer">
              <P color={theme.purpleFont} fontSize="16px" className="mt-2">
                {mapInfo.url}
              </P>
            </a>
          )}

          {mapInfo.classification && (
            <>
              <TextLink color={theme.primaryFont} fontFamily="semi" className="mt-8">
                Classification
              </TextLink>
              <P color={theme.lightFont} fontSize="16px" className="mt-2">
                {mapInfo.classification}
              </P>
            </>
          )}
          {/* <TextLink
            color={theme.lightPurple}
            fontFamily="italic"
            className="mt-1 mb-4"
          >
            Last updated {mapInfo?.lastUpdate}
          </TextLink> */}

          <div className="flex items-center">
            {/* <TextLink
              color={mapInfo.status === "Open" ? theme.greenFont : theme.redFont}
              fontFamily="medium"
              className="mr-2"
            >
              {mapInfo.status === "Open" ? "Open Now" : "Closed now"}
            </TextLink> */}
            {/* <Link to="">
              <TextLink
                color={theme.purpleFont}
                fontFamily="medium"
              >
                {
                  mapInfo.status === "Open"  ? "" : "See open hours"
                }
              </TextLink>
            </Link> */}
          </div>

          {more && (
            <>
              {mapInfo.description && (
                <>
                  <TextLink
                    color={theme.primaryFont}
                    fontFamily="semi"
                    className="mt-8"
                  >
                    Description
                  </TextLink>
                  <P
                    color={theme.lightFont}
                    fontSize="16px"
                    className="mt-2 md:w-1/2"
                  >
                    {mapInfo.description}
                  </P>
                </>
              )}

              <div className="flex flex-wrap items-center mt-6">
                {/* <OutlineButton
                  color={theme.buttonFont}
                  bg="transparent"
                  className="mr-6"
                  onClick={() => setMore(false)}
                >
                  Collapse
                </OutlineButton> */}

                <Link to={`/organization/details/${mapInfo.id}`}>
                  <OutlineButton
                    color={theme.buttonFont}
                    bg="transparent"
                    className="mr-6"
                    onClick={() => setMore(false)}
                  >
                    View
                  </OutlineButton>
                </Link>

                {/* <Link to={`/suggest/${mapInfo.id}`}>
                <OutlineButton
                  color={theme.buttonFont}
                  bg="transparent"
                  className="mt-6 mr-6"
                >
                  Suggest Edit
                </OutlineButton>
              </Link> */}
                <PrimaryButton
                  color="#fff"
                  className="mt-6"
                  style={{
                    padding: "10px 20px",
                  }}
                  onClick={() =>
                    setDestination({
                      lat: Number(mapInfo.locations?.[0]?.latitude),
                      lng: Number(mapInfo.locations?.[0]?.longitude),
                    })
                  }
                >
                  Get Direction
                </PrimaryButton>
              </div>
            </>
          )}

          {!more && (
            <>
              <div className="flex flex-wrap items-center mt-6">
                {/* <OutlineButton
                  color={theme.buttonFont}
                  bg="transparent"
                  className="mr-6"
                  onClick={() => setMore(true)}
                >
                  Expand
                </OutlineButton> */}

                <Link to={`/organization/details/${mapInfo.id}`}>
                  <OutlineButton
                    color={theme.buttonFont}
                    bg="transparent"
                    className="mr-6"
                    onClick={() => setMore(false)}
                  >
                    View
                  </OutlineButton>
                </Link>

                <PrimaryButton
                  color="#fff"
                  className=""
                  style={{
                    padding: "10px 20px",
                  }}
                  onClick={() =>
                    setDestination({
                      lat: Number(mapInfo.locations?.[0]?.latitude),
                      lng: Number(mapInfo.locations?.[0]?.longitude),
                    })
                  }
                >
                  Get Direction
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
        <div className="ml-2">
          <img src={starIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

const Map = ({ results }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: map_key,
  });
  const { theme, isDark } = useContext(themeContext);
  const { location } = useContext(locationContext);

  const [, setMap] = React.useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [mapInfo, setMapInfo] = useState({});
  const [, setCenter] = useState({});
  const [userCoords, setUserCoords] = useState({
    lat: location.latitude,
    lng: location.longitude,
  });
  const [destination, setDestination] = useState("");
  const [directionResponse, setDirectionResponse] = useState(null);
  const [travelMode] = useState("DRIVING");

  const filtered_results = results?.filter(
    (result) =>
      result?.locations?.[0]?.latitude && result?.locations?.[0]?.longitude,
  );

  const activateInfo = (result) => {
    setMapInfo(result);
    setCenter({
      lat: Number(result.locations?.[0]?.latitude),
      lng: Number(result.locations?.[0]?.longitude),
    });
    setShowInfo(true);
  };

  const deActivateInfo = () => setShowInfo(false);

  const directionsCallback = React.useCallback((response) => {
    if (response !== null) {
      if (response.status === "OK") {
        setDirectionResponse(response);
        console.log("Directions response:", response);
      } else if (response.status === "ZERO_RESULTS") {
        toastError("Can't generate direction from your location");
      } else {
        console.log("Directions response:", response);
      }
    } else {
      toastError("Can't get directions");
    }
  }, []);

  const changeDestination = (coord) => {
    deActivateInfo();
    setDestination(coord);
  };

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // Memoize the options for DirectionsService
  const directionsServiceOptions = React.useMemo(
    () => ({
      destination: destination,
      origin: userCoords,
      travelMode: travelMode,
    }),
    [destination, userCoords, travelMode],
  );

  // Memoize the options for DirectionsRenderer
  const directionsRendererOptions = React.useMemo(
    () => ({
      directions: directionResponse,
      polylineOptions: {
        strokeColor: "#442ECF",
        strokeOpacity: 0.8,
        strokeWeight: 5,
      },
      suppressMarkers: true,
    }),
    [directionResponse],
  );

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat:
          Number(userCoords.lat) ||
          Number(filtered_results?.[0]?.locations?.[0]?.latitude) ||
          30,
        lng:
          Number(userCoords.lng) ||
          Number(filtered_results?.[0]?.locations?.[0]?.longitude) ||
          -93,
      }}
      zoom={12}
      options={{
        styles: isDark ? darkMapStyle : lightMapStyle,
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Directions */}
      {destination !== "" && userCoords !== "" && (
        <DirectionsService
          options={directionsServiceOptions}
          callback={directionsCallback}
        />
      )}

      {directionResponse !== null && (
        <DirectionsRenderer options={directionsRendererOptions} />
      )}

      {/* User Marker */}
      {userCoords.lng !== "" && userCoords.lat !== "" && (
        <Marker
          position={{
            lat: Number(userCoords.lat),
            lng: Number(userCoords.lng),
          }}
          title="My Location"
        />
      )}

      {/* Services Markers */}
      {filtered_results?.map((result, idx) => (
        <Marker
          key={idx}
          position={{
            lat: Number(result.locations?.[0]?.latitude),
            lng: Number(result.locations?.[0]?.longitude),
          }}
          zIndex={10}
          icon={markerIcon}
          onClick={() => activateInfo(result)}
          title={result.name}
        >
          {showInfo &&
            result.locations?.[0]?.latitude === mapInfo.locations?.[0]?.latitude &&
            result.locations?.[0]?.longitude ===
              mapInfo.locations?.[0]?.longitude && (
              <InfoWindowStyle
                position={{
                  lat: Number(mapInfo?.locations?.[0]?.latitude),
                  lng: Number(mapInfo?.locations?.[0]?.longitude),
                }}
                onCloseClick={deActivateInfo}
                bg={theme.background}
                style={{
                  background: theme.background,
                  width: "fit-content",
                }}
              >
                <MapResult
                  theme={theme}
                  mapInfo={mapInfo}
                  setDestination={(coord) => changeDestination(coord)}
                />
              </InfoWindowStyle>
            )}
        </Marker>
      ))}
    </GoogleMap>
  ) : (
    <div
      style={containerStyle}
      className="animate-pulse rounded-md bg-gray-300 opacity-75 mb-4"
    />
  );
};

export default Map;
