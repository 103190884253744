import styled from "styled-components";

export const Main = styled.main`
  min-height: calc(100vh - 83px);
  max-width: 100vw;
`;

export const ToggleHolder = styled.div`
  @media (max-width: 600px) {
    width: 100%;
  } ;
`;

export const MapSection = styled.section`
  max-width: 100vw;
  .gm-style-iw.gm-style-iw-c {
    padding: 30px !important;
    border-radius: 0;
    background: ${(props) => props.bg};

    @media (max-width: 600px) {
      padding: 20px !important;
      max-width: 350px !important;
    }

    @media (max-width: 400px) {
      padding: 20px !important;
      max-width: 300px !important;
    }

    @media (max-width: 325px) {
      padding: 20px !important;
      max-width: 280px !important;
    }

    color: ${(props) => props.color};

    .gm-style-iw-d {
      overflow: auto !important;
      margin-bottom: 10px !important;
      max-height: 300px !important;

      ::-webkit-scrollbar {
        width: 10px !important;
        background: #ffffff00 !important;
      }

      ::-webkit-scrollbar-track {
        background: #ffffff00 !important;
      }

      ::-webkit-scrollbar-thumb {
        background: #442ecf !important;
        border: 1px solid slategrey !important;
      }
    }

    .gm-ui-hover-effect {
      top: 0 !important;
      right: 0 !important;

      img {
        filter: ${(props) => props.isDark && "invert(1)"};
      }
    }
  }
`;

export const CanvasStyle = styled.canvas`
  height: 300px;
  max-width: 100vw;
  width: 100%;
  margin-top: 50px;
  background: ${(props) => props.bg};

  @media (max-width: 600px) {
    height: 78px;
  }
`;
