import { gql } from "@apollo/client";

export const GET_SERVICE_BY_ID = gql`
  query GetServiceById($serviceId: String!) {
    getServiceById(serviceId: $serviceId) {
      id
      description
      name
      status
      alternateName
      email
      url
      interpretationServices
      applicationProcess
      waitTime
      fees
      accreditations
      licenses
      subOpCategory
      opCategory
      requiredDocuments
      paymentsAccepted
      eligibilities
      fundings
      serviceAreas
      languages
      program {
        id
        name
        description
      }
      contacts {
        id
        name
        title
        department
        email
      }
      # location {
      #   id
      #   name
      #   transportation
      #   #       latitude
      #   #       longitude
      #   zipCode
      #   #       accessibiltyForDisabilities
      #   #       languages
      #   address
      #   city
      #   region
      #   stateOrProvince
      #   postalCode
      #   country
      # }
      organization {
        id
        description
        name
        tagline
        alternateName
        email
        url
        ein
        deductibility
        subsection
        assetAmount
        nteeType
        incomeAmount
        nteeSuffix
        filingRequirement
        classification
        latest990
        rulingDate
        nteeCode
        groupName
        deductibilityCode
        affiliation
        foundationStatus
        nteeClassification
        accountingPeriod
        exemptOrgStatus
        deductibilityDetail
        exemptOrgStatusCode
        subOpCategory
        opCategory
        nteeLetter
      }
      phones {
        id
        number
        extension
        type
        language
        description
      }
      schedule {
        id
        timezone
        description
        mon {
          startTime
          endTime
        }
        tue {
          startTime
          endTime
        }
        wed {
          startTime
          endTime
        }
        thur {
          startTime
          endTime
        }
        fri {
          startTime
          endTime
        }
        sat {
          startTime
          endTime
        }
        sun {
          startTime
          endTime
        }
      }
    }
  }
`;
