import { gql } from "@apollo/client";

export const SAVE_ORGANIZATION_MUTATION = gql`
  mutation SaveOrganization($data: saveOrganizationData!) {
    saveOrganizationToFavorites(data: $data) {
      id
      orgId
      userId
      name
      description
      createdAt
    }
  }
`;
