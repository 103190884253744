import { gql } from "@apollo/client";

export const SAVED_SERVICES_QUERY = gql`
  query SavedServices($userId: String!) {
    getAllUserFavorites(userId: $userId) {
      id
      serviceId
      userId
      name
      description
      organizationName
      opCategory
      createdAt
    }
  }
`;
