import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Icon from "react-feather";

import noResultImg from "../../../assets/images/no-result-img.svg";
import { Badge } from "../../atoms/Buttons";
import { CheckBox } from "../../atoms/Inputs";
import { H3, P } from "../../atoms/Texts";
import useApiKey from "../../../shared/hooks/useApiKey";

const More = ({ apikey }) => {
  const { deleteApiKey, gettingApiKeys: loading } = useApiKey();

  const onDeleteKey = () => {
    deleteApiKey(apikey);
  };

  return (
    <div className="relative">
      <Icon.Trash2
        color={loading ? "#C3C5CC50" : "#C3C5CC"}
        size={24}
        disabled={loading}
        onClick={onDeleteKey}
        className={`ml-auto ${loading ? "cursor-not-allowed" : "cursor-pointer"}`}
      />
    </div>
  );
};

const ApiKeyTableStyle = styled.table``;

const CopyKeyButton = ({ apikey }) => {
  const [copyText, setCopyText] = useState("Copy");

  const copyToClipBoard = async (apikey) => {
    try {
      await navigator.clipboard.writeText(apikey);
      setCopyText("Copied");
    } catch (err) {
      setCopyText("Failed to copy");
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopyText("Copy");
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [copyText]);

  return (
    <Badge
      onClick={() => copyToClipBoard(apikey)}
      bg="#BFDEFF"
      color="#2E6FD0"
      className="font-medium cursor-pointer"
    >
      {copyText}
    </Badge>
  );
};

const ApiKeyTable = ({ loading, data }) => {
  return (
    <ApiKeyTableStyle className="w-full">
      {loading ? (
        <>
          <thead className="border-b border-gray-400">
            <tr>
              <th className="sm:pr-4 pb-2">
                <CheckBox />
              </th>
              <th className="mr-10 pb-2 w-2/5">
                <P color="#575B68">Developer keys</P>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="text-left py-4">
                <CheckBox />
              </td>
              <td className="text-left py-4 ">
                <div className="flex items-center pr-10">
                  <P
                    color="#575B68"
                    className="animate-pulse rounded-md bg-gray-600 opacity-75 h-4 w-full"
                  ></P>
                </div>
              </td>
              <td className="text-left py-4">
                <P
                  color="#575B68"
                  className="animate-pulse rounded-md bg-gray-600 opacity-75 h-4 w-10 mr-10"
                ></P>
              </td>
              <td className="text-right py-4">
                <Icon.MoreHorizontal
                  color="#C3C5CC"
                  size={30}
                  className="ml-auto cursor-pointer"
                />
              </td>
            </tr>
          </tbody>
        </>
      ) : (
        <>
          {data?.length > 0 ? (
            <>
              <thead className="border-b border-gray-400">
                <tr>
                  <th className="text-left pb-2">
                    <CheckBox />
                  </th>
                  <th className="text-left pb-2">
                    <P color="#575B68">Developer keys</P>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {data?.map((apikey, idx) => (
                  <tr key={idx}>
                    <td className="text-left py-4">
                      <CheckBox />
                    </td>
                    <td className="text-left py-4 ">
                      <div className="flex items-center justify-between pr-4">
                        <P color="#575B68" className="mr-10">
                          {apikey}
                        </P>
                        <CopyKeyButton apikey={apikey} />
                      </div>
                    </td>
                    <td className="text-right py-4">
                      <More apikey={apikey} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <div className="pt-10 flex flex-col items-center">
              <img src={noResultImg} alt="" className="" />
              <H3 color="#22202D" className="my-10">
                You have not created any keys yet.
              </H3>
            </div>
          )}
        </>
      )}
    </ApiKeyTableStyle>
  );
};

export default ApiKeyTable;
