import jwt_decode from "jwt-decode";

export const decodeToken = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("ssn_user"));
  const token = loggedInUser?.token;
  if (token === undefined) {
    return false;
  }
  let decoded = jwt_decode(token);
  const { exp } = decoded;
  const date = new Date();
  if (date > exp * 1000) {
    return false;
  }
  return true;
};

export const decodeTokenWithParam = (token) => {
  if (token === undefined) {
    return false;
  }
  let decoded = jwt_decode(token);
  const { exp } = decoded;
  const date = new Date();
  if (date > exp * 1000) {
    return false;
  }
  return true;
};
