import React, { useState, useEffect, useContext, useMemo } from "react";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useLazyQuery, useQuery } from "@apollo/client";
import queryString from "query-string";

import { themeContext } from "../../../shared/store/contexts/context.theme";
import { calculatePageButtons } from "../../../shared/helpers";
import { P, H3 } from "../../atoms/Texts";
import { DarkFormField } from "../../atoms/Inputs";
import { PrimaryButton, LinkButton, Badge } from "../../atoms/Buttons";
import { GET_ORGANIZATIONS } from "../../../shared/queries";
import OrganizationCard from "../../organisms/OrganizationCard";
// import ZipCodeFilter from "../../molecules/OrganizationZIpcodeFilter";

import noResultImg from "../../../assets/images/no-result-img.svg";
import searchSmallIcon from "../../../assets/images/search.svg";
import { Layout } from "../../organisms/Layouts";

const Main = styled.main`
  background: ${(props) => props.bg};
  min-height: calc(100vh - 75px);
  max-width: 100vw;
`;

const AllOrganizations = () => {
  let history = useHistory();
  const { zipcode } = useParams();

  const query = queryString.parse(window.location.search ?? "");
  const pageQuery = query?.page ?? 0;

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(pageQuery ? pageQuery * 9 : 0);
  const [canLoadNextPage, setCanLoadNextPage] = useState(false);
  const [pageRange, setPageRange] = useState([]);

  const { theme } = useContext(themeContext);

  const { data, loading } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      from: Number(page),
      size: 9,
      // zipCode: zipcode,
    },
  });

  const [preLoadNextPage, { data: preloadedData }] = useLazyQuery(GET_ORGANIZATIONS);

  const [pageSearch, { data: newData, loading: loadingSearch }] =
    useLazyQuery(GET_ORGANIZATIONS);

  useEffect(() => {
    if (preloadedData?.getOrganizations?.length > 0) {
      setCanLoadNextPage(true);
      setPageRange(calculatePageButtons(page / 9, 2));
    }
  }, [page, preloadedData]);

  // useEffect(() => {
  //   console.warn(preloadedDataErrors);
  // }, [preloadedDataErrors]);

  const fetchNextPage = () => {
    const _page = page + 9;
    preLoadNextPage({
      variables: {
        from: _page,
        size: 9,
        // zipCode: zipcode,
      },
    });
  };

  useEffect(() => {
    setResults(newData?.getOrganizations);
    window.scrollTo(0, 0);
  }, [newData]);

  // useEffect(() => {
  //   console.warn(newDataErrors);
  // }, [newDataErrors]);

  // Get organization by page
  useEffect(() => {
    fetchNextPage();
    pageSearch({
      variables: {
        from: Number(page),
        size: 9,
        // zipCode: zipcode,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, zipcode]);

  // Results query
  useEffect(() => {
    setResults(data?.getOrganizations);
  }, [data]);

  // Organization searchbar
  const onSearch = (data) => {
    history.push(`/organization/results/${data.query}`);
  };

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  const changePage = (page) => {
    history.push(`${history.location.pathname}?page=${page}`);
  };

  useMemo(() => {
    setPage(pageQuery * 9);
  }, [pageQuery]);

  return (
    <Layout>
      <Main className="container pt-10" bg={theme.foreground}>
        <div className="md:w-2/5">
          <form onSubmit={handleSubmit(onSearch)}>
            <div className="flex flex-wrap lg:flex-no-wrap">
              <div className="w-full flex-grow">
                <DarkFormField
                  type="search"
                  id="query"
                  name="query"
                  className="py-1 flex w-full"
                  ref={register({
                    required: "Please enter a search phrase",
                  })}
                  placeholder="Search 1,800,000 organizations"
                  iconBefore={searchSmallIcon}
                  iconBeforeClass=""
                  inputClassName="-ml-3"
                  data_testid="query-input"
                />
                <ErrorMessage
                  errors={errors}
                  name="query"
                  as={
                    <P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />
                  }
                >
                  {({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <P key={type}>{message}</P>
                    ))
                  }
                </ErrorMessage>
              </div>
              <div className="md:ml-4">
                <PrimaryButton>Search</PrimaryButton>
              </div>
            </div>
          </form>
          <div className="flex items-center space-x-6">
            {/* <ZipCodeFilter /> */}

            <Link
              to="/organization/categories"
              className="inline-block underline hover:underline"
            >
              <LinkButton
                data-testid="browse-categories-link"
                className="hover:underline"
              >
                Browse Organization Categories
              </LinkButton>
            </Link>
          </div>
          {zipcode && (
            <div className="flex items-center">
              <P fontSize="14px" fontFamily="medium" className="mr-4">
                Filters applied:{" "}
              </P>
              <Badge>{zipcode}</Badge>
              {zipcode && (
                <LinkButton
                  color="#393649"
                  className="flex items-center flex-shrink-0 whitespace-no-wrap ml-4"
                  onClick={() => {
                    history.push("/organizations");
                  }}
                >
                  Reset filters
                </LinkButton>
              )}
            </div>
          )}
        </div>

        <section className="mt-6 pb-10">
          {loading || loadingSearch ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
              </div>
              <div className="">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
              </div>
              <div className="">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
              </div>
              <div className="">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
              </div>
              <div className="">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
              </div>
              <div className="">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
              </div>
            </div>
          ) : (
            <>
              {results?.length < 1 ? (
                <div className=" pt-20 flex flex-col items-center">
                  <img src={noResultImg} alt="" className="" />
                  <H3 color={theme.purpleFont} className="mt-10">
                    No results found
                  </H3>
                  <P
                    fontSize="16px"
                    color={theme.lightFont}
                    className="mt-2 mb-0 text-center"
                  >
                    There are no organizations at the moment.
                  </P>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {results?.map((organization) => (
                      <OrganizationCard
                        key={organization.id}
                        organization={organization}
                      />
                    ))}
                  </div>
                  <div className="flex items-center justify-center">
                    {page > 0 && (
                      <LinkButton
                        onClick={() => changePage((page - 9) / 9)}
                        className="mr-4"
                      >
                        Previous
                      </LinkButton>
                    )}
                    {pageRange.map((_page, index) => (
                      <LinkButton
                        key={index}
                        className="mr-4"
                        disabled={page === _page * 9}
                        onClick={() => changePage(_page)}
                      >
                        {_page + 1}
                      </LinkButton>
                    ))}
                    {page >= 0 && canLoadNextPage && (
                      <LinkButton onClick={() => changePage((page + 9) / 9)}>
                        Next
                      </LinkButton>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </section>
      </Main>
    </Layout>
  );
};

export default AllOrganizations;
