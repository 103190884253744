import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
// import * as R from "ramda";
import { useForm } from "react-hook-form";

import { themeContext } from "../../../shared/store/contexts/context.theme";
// import { toastWarning } from "../../../shared/helpers";
import { Layout } from "../../organisms/Layouts";
import { P, BigP, H2 } from "../../atoms/Texts";
import { InputField } from "../../atoms/Inputs";
import { LinkButton, PrimaryButton } from "../../atoms/Buttons";
import { LandingPageFooter } from "../../organisms/Footers";
import CtaSection from "../../organisms/CtaSection";
import FeatureCard from "../../organisms/FeatureCard";

import doMoreImg from "../../../assets/images/do-more-curly.png";
import listingsIcon from "../../../assets/images/Listings.svg";
import referralsIcon from "../../../assets/images/Referrals.svg";
import trackIcon from "../../../assets/images/Track.svg";
import dashboardIcon from "../../../assets/images/Dashboard.svg";
import LoadingModal from "../../organisms/LoadingModal";
import LoadingBar from "react-top-loading-bar";
import { locationContext } from "../../../shared/store/contexts/context.location";

const Main = styled.main`
  min-height: 100vh;
  max-width: 100vw;
`;

// const map_key = process.env.REACT_APP_MAP_KEY;

const OrganizationSearch = (props) => {
  const history = useHistory();
  const loader = useRef(null);

  const { theme, isDark } = useContext(themeContext);
  const { location } = useContext(locationContext);

  const [fontSize, setFontSize] = useState("40px");
  const [gettingLocationData] = useState(false);
  // const [location, setIpLocation] = useState({});

  const [search, setSearch] = useState({
    need: "",
    zipCode: "",
  });

  const getNeed = ({ need }) => {
    setSearch({ ...search, need });
  };

  const watchZipCode = ({ target }) => {
    const { value } = target;
    setSearch({ ...search, zipCode: value });
  };

  const submitSearch = ({ zipCode }) => {
    if (zipCode) {
      setSearch({ ...search, zipCode: zipCode });
      history.push(`/organization/results/${search.need}/${search.zipCode}`);
    } else {
      // setSearch({ ...search, zipCode: ipLocation?.postal });
      setSearch({ ...search, zipCode: location?.zipCode || "" });
      // history.push(
      //   `/organization/results/${search.need}/${ipLocation?.postal ?? ""}`,
      // );
      if (location?.zipCode) {
        history.push(`/organization/results/${search.need}/${location?.zipCode}`);
      } else {
        history.push(`/organization/results/${search.need}`);
      }
    }
  };

  // const getZipCode = (info) => {
  //   const getAddressComponents = R.path(["results", "0", "address_components"]);

  //   const hasPostalCode = R.where({
  //     types: R.includes("postal_code"),
  //   });

  //   const zip_code = R.compose(
  //     R.path(["long_name"]),
  //     R.head,
  //     R.filter(hasPostalCode),
  //     getAddressComponents,
  //   )(info);

  //   history.push(`/organization/results/${search.need}/${zip_code}`);

  //   // console.log(hasPostalCode)
  // };

  // const getLocation = () => {
  //   // if (navigator.geolocation) {
  //   try {
  //     setGettingLocationData(true);
  //     if (loader) {
  //       loader.current.continuousStart();
  //     }
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         let lat = position.coords.latitude;
  //         let lng = position.coords.longitude;

  //         fetch(
  //           `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${map_key}`,
  //         )
  //           .then((res) => res.json())
  //           .then((data) => getZipCode(data));
  //         // .catch(() => {
  //         //   toastWarning(
  //         //     "Couldn't get your location. Please check your network or try again.",
  //         //   );
  //         // });
  //       },
  //       () => {
  //         setGettingLocationData(false);
  //         toastWarning(
  //           "Couldn't get your location. Please check your network or try again.",
  //         );
  //         return loader && loader.current?.complete();
  //       },
  //     );
  //   } catch (error) {
  //     setGettingLocationData(false);
  //     // toastWarning(error);
  //     toastWarning(
  //       "Please ensure that you have granted location permission and your browser supports geolocation.",
  //     );
  //     return loader && loader.current?.complete();
  //   }
  //   // } else {
  //   //   toastWarning("Please enable geolocation")
  //   // }
  // };

  const { register, handleSubmit, watch } = useForm({
    validateCriteriaMode: "all",
  });

  const need = watch("need");

  useEffect(() => {
    if (need) {
      let { length } = need;
      switch (true) {
        case length > 8 && length < 15:
          setFontSize("40px");
          break;

        case length >= 15:
          setFontSize("30px");
          break;

        default:
          setFontSize("45px");

          break;
      }
    }

    return () => setFontSize("45px");
  }, [need]);

  // useEffect(() => {
  //   fetch("https://ipapi.co/json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setIpLocation(data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);

  return (
    <Layout>
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <Main className=" px-6 lg:px-0 ">
        <section className="py-40 w-full md:w-4/5 lg:w-3/5 mx-auto">
          <P
            fontSize="18px"
            color={theme.lightFont}
            className={search.need !== "" && "opacity-25"}
            data-testid="page-heading"
          >
            How can we help you?
          </P>
          <form onSubmit={handleSubmit(getNeed)} className="w-full">
            <div
              className={`flex flex-wrap md:flex-no-wrap items-end mt-6 max-w-full ${
                search.need !== "" ? "opacity-25" : ""
              }`}
            >
              <BigP
                color={theme.primaryFont}
                className="mr-6 md:flex-shrink-0"
                fontSize={fontSize}
                data-testid="need-title"
              >
                I need
              </BigP>
              <InputField
                type="text"
                id="need"
                name="need"
                data-testid="need-input"
                w="100%"
                largeW="350px"
                ref={register()}
                className="w-full"
                fontSize={fontSize}
                placeholder="health care"
                color={theme.purpleFont}
                onFocus={() => setSearch({ need: "", zipCode: "" })}
              />
            </div>
          </form>

          {search.need !== "" && (
            <form onSubmit={handleSubmit(submitSearch)} className="w-full">
              <div className="flex flex-wrap md:flex-no-wrap items-end mt-6 max-w-full">
                <BigP
                  fontSize={"40px"}
                  color={theme.primaryFont}
                  className="mr-6 md:flex-shrink-0"
                >
                  My zipcode is
                </BigP>
                <InputField
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  data-testid="zipCode-input"
                  w="135px"
                  largeW="150px"
                  ref={register({
                    maxLength: {
                      value: 5,
                    },
                    minLength: {
                      value: 5,
                    },
                  })}
                  maxLength={5}
                  placeholder={location?.zipCode}
                  // defaultValue={location?.zipCode}
                  fontSize={"40px"}
                  color={theme.purpleFont}
                  onChange={watchZipCode}
                  // onFocus={() => setSearch({...search, zipCode: ""})}
                  autoFocus={search.need !== "" && true}
                />
              </div>

              {search?.need?.length !== "" && (
                <PrimaryButton className="mt-16">Search</PrimaryButton>
              )}

              {search?.zipCode?.length < 5 && (
                <LinkButton
                  color={theme.purpleFont}
                  fontSize="24px"
                  smallSize="18px"
                  className="mt-10 block"
                  // onClick={getLocation}
                  // type="button"
                  // disabled={gettingLocationData}
                >
                  Use current location
                </LinkButton>
              )}
            </form>
          )}
        </section>
      </Main>
      <div style={{ backgroundColor: "#0C024D" }} className="mb-20">
        <section
          className="pl-6 lg:pl-24 py-6 md:py-32 -mt-12 flex items-center"
          style={{ backgroundColor: "#0C024D" }}
        >
          <div className="mr-10 md:w-3/5">
            <H2
              color="#ffffff"
              fontSize="50px"
              fontFamily="semi"
              lineHeight="64px"
              className="w-full mb-14 lg:w-5/6"
            >
              Search, find, and connect with local, social service providers
            </H2>
            <P
              color="#B4B0D1"
              fontSize="23px"
              className="leading-8 md:w-5/6 lg:w-4/6 mt-32"
            >
              Through Social Safety Net, people in need can connect with social
              service providers. In turn, we empower service providers with tools to
              create and manage service users.
            </P>
          </div>
          <img
            src={doMoreImg}
            alt=""
            className="hidden md:block md:w-2/5 lg:block"
          />
        </section>
        <section
          className="container mx-auto py-10 md:pb-56 -mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 row-gap-20 col-gap-10"
          style={{ backgroundColor: "#0C024D" }}
        >
          <FeatureCard
            icon={listingsIcon}
            heading="Find services"
            body="Find programs and services for your need"
            link="/organizations"
          />
          <FeatureCard
            icon={referralsIcon}
            heading="Share services"
            body="Share services with friends and people in need"
            link="/initiatives"
          />
          <FeatureCard
            icon={trackIcon}
            heading="Apply for services"
            body="Easily apply for services and get feedback on acceptance"
            link="/datalabs"
          />
          <FeatureCard
            icon={dashboardIcon}
            heading="Save services"
            body="Save your favorite services and organizations for quick access"
            link="/datalabs"
          />
        </section>
      </div>
      <CtaSection />
      <LandingPageFooter isDark={isDark} theme={theme} />
      {gettingLocationData && <LoadingModal />}
    </Layout>
  );
};

export default OrganizationSearch;
