import React, { useState, useEffect, useContext, useMemo } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";

import { themeContext } from "../../../shared/store/contexts/context.theme";
import { Layout } from "../../organisms/Layouts";
import { P, H2, H3 } from "../../atoms/Texts";
import noResultImg from "../../../assets/images/no-result-img.svg";

import { useLazyQuery, useQuery } from "@apollo/client";
import { ORGANIZATIONS_BY_CATEGORY } from "../../../shared/queries";
import OrganizationResultCard from "../../organisms/OrganizationResultCard";
import { LinkButton } from "../../atoms/Buttons";
import { calculatePageButtons } from "../../../shared/helpers";
import { locationContext } from "../../../shared/store/contexts/context.location";

const Main = styled.main`
  min-height: calc(100vh - 75px);
  max-width: 100vw;
`;

const SpecificOrganizationCategoryPage = (props) => {
  let history = useHistory();

  const { location, isFetchingUserLocation } = useContext(locationContext);

  const query = queryString.parse(window.location.search ?? "");
  const pageQuery = query?.page ?? 0;

  const { category, subCategory } = props.match.params;
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(pageQuery ? pageQuery * 10 : 0);
  const [canLoadNextPage, setCanLoadNextPage] = useState(false);
  const [pageRange, setPageRange] = useState([]);
  const [zipCode, setZipCode] = useState("");
  // const [userCoordinates, setUserCoordinates] = useState({});
  const [isGettingLocation, setIsGettingLocation] = useState(false);

  const { theme } = useContext(themeContext);

  useMemo(() => {
    if (isFetchingUserLocation) {
      setIsGettingLocation(true);
    } else {
      if (location) {
        setZipCode(location?.zipCode);
      }
      setIsGettingLocation(false);
    }
  }, [isFetchingUserLocation, location]);

  const { data, loading } = useQuery(ORGANIZATIONS_BY_CATEGORY, {
    variables: {
      category,
      subCategory,
      from: Number(page),
      size: 10,
      zipCode,
      latitude: location?.latitude,
      longitude: location?.longitude,
    },
  });

  const [preLoadNextPage, { data: preloadedData }] = useLazyQuery(
    ORGANIZATIONS_BY_CATEGORY,
  );

  const [pageSearch, { data: newData }] = useLazyQuery(ORGANIZATIONS_BY_CATEGORY);

  useEffect(() => {
    if (preloadedData?.searchOrganizationByCategory?.length > 0) {
      setCanLoadNextPage(true);
      setPageRange(calculatePageButtons(page / 10, 2));
    }
  }, [page, preloadedData]);

  // useEffect(() => {
  //   console.warn(preloadedDataErrors);
  // }, [preloadedDataErrors]);

  const fetchNextPage = () => {
    const _page = page + 10;
    preLoadNextPage({
      variables: {
        category,
        subCategory,
        from: _page,
        size: 10,
        zipCode,
        latitude: location?.latitude,
        longitude: location?.longitude,
      },
    });
  };

  useEffect(() => {
    setResults(newData?.searchOrganizationByCategory);
    window.scrollTo(0, 0);
  }, [newData]);

  // useEffect(() => {
  //   console.warn(newDataErrors);
  // }, [newDataErrors]);

  useEffect(() => {
    fetchNextPage();
    pageSearch({
      variables: {
        category,
        subCategory,
        from: Number(page),
        size: 10,
        zipCode,
        latitude: location?.latitude,
        longitude: location?.longitude,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, zipCode, location]);

  useEffect(() => {
    setResults(data?.searchOrganizationByCategory);
  }, [data]);

  // useEffect(() => {
  //   console.warn(errors);
  // }, [errors]);

  const changePage = (page) => {
    history.push(`${history.location.pathname}?page=${page}`);
  };

  useMemo(() => {
    setPage(pageQuery * 10);
  }, [pageQuery]);

  return (
    <Layout>
      <Main className="container mx-auto">
        <section className=" pt-6 pb-10 md:py-12">
          <div className="flex items-center flex-wrap mb-10">
            <H2 fontSize="32px" color={theme.primaryFont}>
              <span
                className="mr-3 capitalize"
                style={{
                  fontSize: "32px",
                }}
              >
                Showing organizations under
              </span>
              <span
                className="md:mr-3 capitalize inline-block"
                style={{
                  fontSize: "32px",
                  color: theme.purpleFont,
                  borderBottom: "solid 3px #442ED0",
                  textTransform: "capitalize",
                }}
              >
                {category}
              </span>
              {subCategory && (
                <>
                  <span
                    style={{
                      fontSize: "32px",
                    }}
                    className="mr-3"
                  >
                    /
                  </span>
                  <span
                    className="mr-3"
                    style={{
                      fontSize: "32px",
                      color: theme.purpleFont,
                      borderBottom: "solid 3px #442ED0",
                    }}
                  >
                    {subCategory}
                  </span>
                </>
              )}
            </H2>
          </div>
          {loading || isGettingLocation ? (
            <>
              <div className="mt-10 mb-8">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
              </div>
              <div className="mb-8">
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
                <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
              </div>
            </>
          ) : (
            <>
              {results?.length < 1 ? (
                <div className=" pt-20 flex flex-col items-center">
                  <img src={noResultImg} alt="" className="" />
                  <H3 color={theme.purpleFont} className="mt-10">
                    No results found
                  </H3>
                  <P
                    fontSize="16px"
                    color={theme.lightFont}
                    className="mt-2 mb-0 text-center"
                  >
                    We cannot locate an entry for your search.
                    <br />
                    <Link to="/organizations" className="ml-2 underline">
                      Please modify your search and try again
                    </Link>
                  </P>
                </div>
              ) : (
                <>
                  {results?.map((organization) => (
                    <OrganizationResultCard
                      key={organization.id}
                      organization={organization}
                    />
                  ))}
                  <div className="flex items-center justify-center">
                    {page > 0 && (
                      <LinkButton
                        onClick={() => changePage((page - 10) / 10)}
                        className="mr-4"
                      >
                        Previous
                      </LinkButton>
                    )}
                    {pageRange.map((_page, index) => (
                      <LinkButton
                        key={index}
                        className="mr-4"
                        disabled={page === _page * 10}
                        onClick={() => changePage(_page)}
                      >
                        {_page + 1}
                      </LinkButton>
                    ))}
                    {page >= 0 && canLoadNextPage && (
                      <LinkButton onClick={() => changePage((page + 10) / 10)}>
                        Next
                      </LinkButton>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </section>
      </Main>
    </Layout>
  );
};

export default SpecificOrganizationCategoryPage;
