import React, { useContext, useEffect } from "react";

import { themeContext } from "../../../shared/store/contexts/context.theme";
import { H2, H3 } from "../../atoms/Texts";
import noResultImg from "../../../assets/images/no-saved-img.svg";

import useOrganization from "../../../shared/hooks/useOrganization";
import { authContext } from "../../../shared/store/contexts";
import SavedOrganizationCard from "../../organisms/SavedOrganizationCard";

const SavedOrganizations = () => {
  const { theme } = useContext(themeContext);
  const { auth } = useContext(authContext);

  // Get saved organizations
  const { getSavedOrganizations, gettingSavedOrganizations, savedOrganizations } =
    useOrganization();

  useEffect(() => {
    getSavedOrganizations(auth?.user?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.id]);

  return (
    <section className="py-10">
      <H2 fontSize="24px" color={theme.primaryFont}>
        Saved Organizations
      </H2>
      {gettingSavedOrganizations ? (
        <>
          <div className="mt-10 mb-8">
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
          </div>
          <div className="mb-8">
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full md:w-1/2 mb-4"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full md:w-2/3 h-5"></div>
          </div>
        </>
      ) : (
        <>
          {auth?.user?.savedOrganizations?.length < 1 ? (
            <div className=" pt-20 flex flex-col items-center">
              <img src={noResultImg} alt="" className="" />
              <H3 color={theme.purpleFont} fontSize="24px" className="mt-10">
                No saved organization
              </H3>
            </div>
          ) : (
            <section className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-10">
              {savedOrganizations?.map((organization) => (
                <SavedOrganizationCard
                  key={organization.id}
                  organization={organization}
                />
              ))}
            </section>
          )}
        </>
      )}
    </section>
  );
};

export default SavedOrganizations;
