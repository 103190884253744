import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import eyeIcon from "../../../assets/images/eye.svg";
import eyeOffIcon from "../../../assets/images/eye-off.svg";

import { P, TextLink } from "../../atoms/Texts";
import { DarkFormField, DarkSelectInput } from "../../atoms/Inputs";
import { PrimaryButton, OutlineButton } from "../../atoms/Buttons";
import { dirtyValues } from "../../../shared/helpers/util.getDirtyFields";

const FirstForm = ({ countries, fields, updateFields, changeStep }) => {
  const [dirtyFields, setDirtyFields] = useState({});
  const [, setStates] = useState([]);

  const { register, errors, handleSubmit, watch, formState } = useForm({
    validateCriteriaMode: "all",
    defaultValues: {
      firstName: fields.firstName,
      lastName: fields.lastName,
      country: fields.country,
      email: fields.email,
      password: fields.password,
      phone: fields.phone,
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const [show, setShow] = useState(false);

  const showPassword = () => {
    if (show) {
      setShow(false);
      setPasswordType("password");
    } else {
      setShow(true);
      setPasswordType("text");
    }
  };

  const selectedCountry = watch("country");

  useEffect(() => {
    setStates(
      countries.find((country) => country.code3 === selectedCountry)?.states,
    );
  }, [countries, selectedCountry]);

  useEffect(() => {
    setDirtyFields(formState.dirtyFields);
  }, [formState]);

  const submitDetails = (data) => {
    const updatedFields = dirtyValues(dirtyFields, data);

    updateFields(updatedFields);
    changeStep(2);
  };

  return (
    <form onSubmit={handleSubmit(submitDetails)}>
      <div className="w-full mx-auto">
        <div className="mx-auto">
          <div>
            <DarkFormField
              type="text"
              id="firstName"
              name="firstName"
              color="#F8F6FE"
              className="py-1 px-2"
              ref={register({
                required: "Please enter your first name",
              })}
              label="First Name"
              placeholder="Enter your first name"
              defaultValue={fields.firstName}
              iconBeforeClass=""
              inputClassName="-ml-3"
            />
            <ErrorMessage
              errors={errors}
              name="firstName"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkFormField
              type="text"
              id="lastName"
              name="lastName"
              color="#F8F6FE"
              className="py-1 px-2"
              ref={register({
                required: "Please enter your last name",
              })}
              label="Last Name"
              placeholder="Enter your last name"
              defaultValue={fields.lastName}
              iconBeforeClass=""
              inputClassName="-ml-3"
            />
            <ErrorMessage
              errors={errors}
              name="lastName"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkSelectInput
              className="py-1 px-2"
              name="country"
              id="country"
              type="select"
              ref={register({
                required: "Country is required.",
              })}
              label="Country"
              defaultValue={fields.country || ""}
              inputClassName="-ml-3"
            >
              <option value="" disabled>
                Select a country
              </option>
              {countries.map((_country, idx) => (
                <option key={idx} value={_country.code3}>
                  {_country.name}
                </option>
              ))}
            </DarkSelectInput>
            <ErrorMessage
              errors={errors}
              name="country"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkFormField
              type="email"
              id="email"
              name="email"
              w="135px"
              ref={register({
                required: "Email is required",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Please enter a valid email.",
                },
              })}
              placeholder="example@mail.com"
              label="Email"
              defaultValue={fields.email}
              className="w-full"
            />
            <ErrorMessage
              errors={errors}
              name="email"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkFormField
              type={passwordType}
              id="password"
              name="password"
              w="135px"
              ref={register({
                required: "Password is required.",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              })}
              icon={!show ? eyeIcon : eyeOffIcon}
              iconClick={showPassword}
              placeholder="Use a strong password"
              defaultValue={fields.password}
              label="Password"
              className="w-full"
            />
            <ErrorMessage
              errors={errors}
              name="password"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkFormField
              type="text"
              id="phone"
              name="phone"
              w="135px"
              ref={register({
                pattern: {
                  message: "Please enter a valid phone number.",
                },
              })}
              placeholder="Enter your phone number"
              label="Phone Number"
              defaultValue={fields.phone}
              className="w-full"
            />
            <ErrorMessage
              errors={errors}
              name="phone"
              as={<P fontSize="12px" className="-mt-4 mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <TextLink className="w-full mb-6 block" color="#716E8B">
            Registering to this website, you accept our
            <Link to="/terms" style={{ color: "#442ED0" }}>
              {" "}
              Terms of Use{" "}
            </Link>
            and our
            <Link to="/privacy" style={{ color: "#442ED0" }}>
              {" "}
              Privacy Policy
            </Link>
          </TextLink>
        </div>

        <div className="mb-10 mx-auto">
          <PrimaryButton className="w-full">Continue</PrimaryButton>
          <Link to="/login">
            <OutlineButton
              className="w-full mt-4 block"
              color="#575B68"
              type="button"
            >
              Have an account?
              <span style={{ color: "#442ED0" }}> Sign in</span>
            </OutlineButton>
          </Link>
        </div>
      </div>
    </form>
  );
};

export default FirstForm;
