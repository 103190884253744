const markdown = `
# API Documentation Guide

## Introduction
### Getting Started


The SSN API is a [GraphQL](https://en.wikipedia.org/wiki/GraphQL) powered web service that provides developers with a set of resources that enables them to build applications and query for large amount of datasets backed by SSN API. The Developer API supports GraphQL requests. All responses rendered by the API are in JSON format. Feel free to make use of the sandbox to test integrations with the provided endpoints. It is a secure space created specifically for you to familiarize yourself with our services. We are constantly working on new features to help you build more feature rich applications. As such we constantly update this documentation with new resources.

### Application Credentials
The SSN API makes use of:

* App ID: This is your unique ID which is paired with your API Key in order to identify your application. It is  a security credential for all API requests. It is used to identify your application. The App Key is passed as \`appId\` in all API requests.
* API Key: This is your generated \`API Key\` which contains information about your application and your current plan. The API Key is passed as \`apiKey\` in all API requests.

> Never expose your application credentials to a third party. They are highly sensitive secrets and should be treated as such.

### Errors
The SSN Developer API utilizes the following Error codes to indicate errors:

| Error Code | Meaning |
| ------ | ------ |
| APIOutOfBounds | The API Key being used cannot be identified by the server. |
| PlanExistError | An API Key for this plan exists already. |

In order to check for these errors, you should check the corresponding \`__typename\` and return the approriate \`message\` rendered by the error. Typically, errors can be checked for in this manner using [Inline Fragments](https://www.apollographql.com/docs/android/essentials/inline-fragments/)

\`\`\`javascript=
{
  getSomeProtectedRoute: {
      ...on APIOutOfBounds {
          message
          __typename
      }
  }
}
\`\`\`


## Creating an API Key
In order to create an API Key, you need to be logged in first. Locate the \`API Keys\` button under your name on the dashboard and click it. It will take you to another page where you can generate the keys. Click the \`Generate new keys\` button. This will generate a key for you.
>Note: You can only have a maximum of three (3) API keys. 


## Accessing your API Key
In order to access your API Key

## Organizations
### The Organization Schema
The Organization schema lists all the possible properties available in SSN Organization database. Below is an example of the current schema. 

> Note: More properties can be added later on.

\`\`\`javascript=
type Organization {
  id: ID
  description: String
  name: String
  tagline: String
  alternateName: String
  email: String
  url: String
  ein: String
  deductibility: String
  subsection: String
  assetAmount: String
  nteeType: String
  incomeAmount: String
  nteeSuffix: String
  filingRequirement: String
  classification: String
  latest990: String
  rulingDate: String
  nteeCode: String
  groupName: String
  deductibilityCode: String
  affiliation: String
  foundationStatus: String
  nteeClassification: String
  accountingPeriod: String
  exemptOrgStatus: String
  deductibilityDetail: String
  exemptOrgStatusCode: String
  subOpCategory: String
  opCategory: String
  nteeLetter: String
  programs: [Program]
  services: [Service]
  contacts: [Contact]
  locations: [Location]
  phones: [Phone]
  schedule: [Schedule]
}
\`\`\`

### Getting a list of organization
A list of organizations can be gotten by accessing this route. It returns an array of organizations. Pagination and sort capabilities can be utilized also.
\`\`\`javascript=
  _getOrganizations(appId: String!, apiKey: String!, from: Int, size: Int, sort: Sort): [Organization]!
\`\`\`

> N:B \`Sort\` corresponds to:
\`\`\`javascript=
enum Sort {
  ASC
  DESC
}
\`\`\`

Where \`ASC\` means \`Asscending\` and \`DESC\` means \`Descending\`.



**Example request:**
To make requests to the API to return a list of organizations, below is an example request:

\`\`\`javascript=
query {
  _getOrganizations(appId: "1234", apiKey: "EY45-RTJ4-54HJ-12KI") {
  ...on DeveloperError {
      message
  }
  ...on OrganizationList {
      organization {
        id
        description
        name
        tagline
        alternateName
        email
        url
      }
    }
  }
}

\`\`\`

**Sample Result:**
\`\`\`javascript=
{
  "data": {
    "_getOrganizations": {
      "organization": [
        {
          "id": "9034f2df-4256-4c8c-be25-beabef188a7b",
          "name": "10,000 Degrees"
        },
        {
          "id": "4a1c016c-1b45-4ced-8949-0d2c405733d1",
          "name": "100 Club of Arizona"
        },
        {
          "id": "20297671-13ad-4b27-8dae-db224e87534d",
          "name": "100 Club of Chicago"
        },
        {
          "id": "385f7eb3-2c28-4b96-8aa9-2776f7662ccb",
          "name": "1000 Friends of Florida"
        },
        {
          "id": "10f86b70-720d-45a2-9b5e-33a4d50894b0",
          "name": "1000 Friends of Oregon"
        },
        {
          "id": "18d582d0-75bf-4e57-aa5a-e7799da7b4d8",
          "name": "18Doors"
        },
        {
          "id": "50253ec8-4903-499f-a977-ecddc5b39266",
          "name": "24 Foundation"
        },
      ]
    }
  }
}
\`\`\`


### Getting a list of organization with pagination
Results gotten from the API can be paginated using the \`from\` and \`size\` keywords. \`from\` represents the \`offset\` and \`size\` represents the volume of data of be returned from the API.

**Example request:**
\`\`\`javascript=
query {
  _getOrganizations(appId: "1234", apiKey: "EY45-RTJ4-54HJ-12KI", from: 2, size: 3) {
    ...on DeveloperError {
      message
  }
  ...on OrganizationList {
      organization {
        id
        description
        name
        tagline
        alternateName
        email
        url
      }
    }
  }
}

\`\`\`

**Sample Result:**
\`\`\`javascript=
 {
  "data": {
    "_getOrganizations": {
      "organization": [
        {
          "id": "9034f2df-4256-4c8c-be25-beabef188a7b",
          "name": "10,000 Degrees"
        },
        {
          "id": "4a1c016c-1b45-4ced-8949-0d2c405733d1",
          "name": "100 Club of Arizona"
        },
        {
          "id": "20297671-13ad-4b27-8dae-db224e87534d",
          "name": "100 Club of Chicago"
        },
      ]
    }
  }
}
\`\`\`


### Getting a list of organization with sorting
Sorting can also be applied to some requests, we can either sort items in \`Ascending\` or \`Descending\` order.
In order to sort items returned from the API, we pass in the \`sort\` key parameter to the query.

**Example request:**
\`\`\`javascript=
query {
  _getOrganizations(appId: "1234", apiKey: "EY45-RTJ4-54HJ-12KI", from: 2, size: 3, sort: asc) {
    ...on DeveloperError {
      message
  }
  ...on OrganizationList {
      organization {
        id
        description
        name
        tagline
        alternateName
        email
        url
      }
    }
  }
}

\`\`\`

**Sample Result:**
\`\`\`javascript=
 {
  "data": {
    "_getOrganizations": {
      "organization": [
        {
          "id": "60ebcea8-e919-4c20-a5c9-1a8c870f5748",
          "name": "I Have a Dream Foundation"
        },
        {
          "id": "9034f2df-4256-4c8c-be25-beabef188a7b",
          "name": "10,000 Degrees"
        },
        {
          "id": "4a1c016c-1b45-4ced-8949-0d2c405733d1",
          "name": "100 Club of Arizona"
        },
        {
          "id": "20297671-13ad-4b27-8dae-db224e87534d",
          "name": "100 Club of Chicago"
        },
        {
          "id": "385f7eb3-2c28-4b96-8aa9-2776f7662ccb",
          "name": "1000 Friends of Florida"
        },
        {
          "id": "10f86b70-720d-45a2-9b5e-33a4d50894b0",
          "name": "1000 Friends of Oregon"
        }
      ]
    }
  }
}
\`\`\`


### Searching for organizations by text phrase
Organizations can also be searched for by passing in a search term and it returns a list of organizations that match the search query. The route can be accessed by making a request to it. Below, the route is listed:

\`\`\`javascript=
_searchOrganization(
    appId: String!
    apiKey: String!
    text: String!
    zipCode: String
    from: Int
    size: Int
    filter: [FilterOrg]
  ): [Organization]
\`\`\`

### Query Params

| Parameter | Meaning |
| --------- | ------- |
| text      | Search input, e.g \`food\` |
| zipCode   | Specified zip code Location |
| from      | The specified offset |
| size      | The number of items to return |
| filter    | The filters to apply |

### Filter Parameters

\`\`\`javascript=
input FilterOrg {
  field: OrgFields!
  value: String!
}
\`\`\`

\`OrgFields\` is represented by this enum:

\`\`\`javascript=
enum OrgFields {
  name
  email
  taxStatus
  taxId
  ein
  yearIncorporated
  legalStatus
  irsStatus
  affiliation
  nteeClassification
  nteeCode
  nteeSubClassification
}
\`\`\`

**Example request:**
\`\`\`javascript=
query {
 _searchOrganization(
    appId: "1234", 
    apiKey: "EY45-RTJ4-54HJ-12KI", 
    text: "education"
  ) {
    ...on DeveloperError {
      message
  }
  ...on OrganizationList {
      organization {
        id
        description
        name
        tagline
        alternateName
        email
        url
      }
    }
  }
}

\`\`\`

**Sample Result:**
\`\`\`javascript=
 {
  "data": {
    "_searchOrganization": {
      "organization": [
        {
          "id": "60ebcea8-e919-4c20-a5c9-1a8c870f5748",
          "name": "I Have a Dream Foundation",
          "description": "Founded in 1986 by businessman Eugene M. Lang, the "I Have A Dream Foundation" empowers children in low-income communities to achieve higher education and fulfill their leadership potential by providing them with guaranteed tuition support and equipping them with the skills, knowledge, and habits they need to gain entry to higher education and succeed in college and beyond. By helping our Dreamers gain access to college, we are putting our Dreamers on a different academic and life trajectory. We sponsor entire grade levels of 50-100 students in under-resourced public schools or housing developments and work with these Dreamers from early elementary school all the way through high school. Upon high school graduation, each Dreamer receives guaranteed tuition assistance for higher education."
        },
         {
          "id": "20297671-13ad-4b27-8dae-db224e87534d",
          "name": "100 Club of Chicago",
          "description": "The 100 Club of Chicago provides for the families of first responders who have lost their lives in the line of duty. Our membership based organization offers several forms of financial assistance, access to resources and moral support. All sworn federal, state, county and local first responders stationed in Cook, Lake and McHenry Counties are included. Through First Response Assistance, Education Assistance and our Gold Shield Partners, we are able to provide for our families."
        },
      ]
    }
  }
}
\`\`\`

### Getting an organization by ID
Organizations can be queried by their respective ID's by calling the query below.

\`\`\`javascript=
query {
  _getOrganizationsById(appId: String!, apiKey: String!, id: ID!): Organization
}
\`\`\`

**Example request:**
\`\`\`javascript=
query {
  _getOrganizationsById (appId: "1234", apiKey: "EY45-RTJ4-54HJ-12KI", id:"60ebcea8-e919-4c20-a5c9-1a8c870f5748") {
     ...on DeveloperError {
      message
  }
  ...on OrganizationById {
      organization {
        id
        description
        name
        tagline
        alternateName
        email
        url
      }
    }
  }
}
\`\`\`
**Sample Result:**
\`\`\`javascript=
 {
  "data": {
    "_getOrganizationsById": {
      "organization": {
        "id": "60ebcea8-e919-4c20-a5c9-1a8c870f5748",
        "name": "I Have a Dream" Foundation"
      }
    }
  }
}
\`\`\`


## Service
### The Service Schema
The Service schema lists all the possible properties available in SSN Service database. Below is an example of the current schema. 


\`\`\`javascript=
type Service {
  id: ID
  name: String
  status: String
  alternateName: String
  description: String
  url: String
  email: String
  interpretationServices: String
  applicationProcess: String
  waitTime: String
  fees: String
  accreditations: String
  licenses: String
  subOpCategory: String
  opCategory: String
  requiredDocuments: String
  paymentsAccepted: String
  eligibilities: String
  fundings: String
  contacts: [Contact]
  serviceAreas: String
  languages: String
  program: Program
  organization: Organization
  location: Location
  phones: [Phone]
  schedule: Schedule
}
\`\`\`

> Note: More properties can be added later on.


### Getting a list of services
A list of services can be gotten by calling this query. It returns an array of services available.
\`\`\`javascript=
query {
  _getServices(appId: String!, apiKey: String!, from: Int, size: Int, sort: Sort): ServiceListResponse!
}
\`\`\`

Below is an example request to the \`getService\` query

**Example request:**
\`\`\`javascript=
query {
  _getServices(appId: "1234", apiKey: "EY45-RTJ4-54HJ-12KI") {
  ...on DeveloperError {
      message
  }
  ...on ServiceList {
   service {
     id
     name
     status
     alternateName
    }
   }
  }
}
\`\`\`

**Sample Result:**
\`\`\`javascript=
{
  "data": {
    "_getServices": {
      "service": [
        {
          "id": "360631b0-c452-454d-8cfd-b8b7bf5b7292",
          "name": "#IDareYouToCare, Mon - Fri Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "71f18db7-539c-4780-9f1a-be8f3889b2a4",
          "name": "1-800-RUNAWAY",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "25560c8c-e91b-4567-a58d-4164a20cc1e6",
          "name": "1st Sat Mobile Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "fb031b9c-d3c2-4491-9651-4a760c773885",
          "name": "1st Wed Mobile Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "8d917fd9-18f0-4a6d-bb2a-7727208b53b8",
          "name": "1st and 3rd Sat Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "cd74aa56-b618-4c7e-8248-e836f0695838",
          "name": "1st, 3rd, and 5th Mon Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "ed904d26-734a-4e50-b30e-0a4c6de323c6",
          "name": "1st/2nd Tues and Wed Basic Needs",
          "status": "Open",
          "alternateName": ""
        },
      ]
    }
  }
}
\`\`\`


### Getting a list of services with pagination
Services can also be paginated with the use of \`from\` and \`size\`. You can specify the offset and size of data to retrieve respectively.

**Example request:**
\`\`\`javascript=
query {
  _getServices(appId: "1234", apiKey: "EY45-RTJ4-54HJ-12KI", from: 2, size: 4) {
  ...on DeveloperError {
      message
  }
  ...on ServiceList {
   service {
     id
     name
     status
     alternateName
    }
   }
  }
}
\`\`\`

**Sample Result:**
\`\`\`javascript=
{
  "data": {
    "_getServices": {
      "service": [
        {
          "id": "25560c8c-e91b-4567-a58d-4164a20cc1e6",
          "name": "1st Sat Mobile Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "fb031b9c-d3c2-4491-9651-4a760c773885",
          "name": "1st Wed Mobile Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "8d917fd9-18f0-4a6d-bb2a-7727208b53b8",
          "name": "1st and 3rd Sat Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
      ]
    }
  }
}
\`\`\`


### Getting a list of services with sorting
Services can also be sorted using various parameters. The \`Sort\` parameters are listed as follow:

\`\`\`javascript=
enum Sort {
  ASC
  DESC
}
\`\`\`

> We only support \`ASC\` and \`DESC\` for now.

**Example request:**
\`\`\`javascript=
query {
  _getServices(appId: "1234", apiKey: "EY45-RTJ4-54HJ-12KI", from: 2, size: 4, sort: ASC) {
  ...on DeveloperError {
      message
  }
  ...on ServiceList {
   service {
     id
     name
     status
     alternateName
    }
   }
  }
}
\`\`\`

**Sample Result:**
\`\`\`javascript=
{
  "data": {
    "_getServices": {
      "service": [
        {
          "id": "25560c8c-e91b-4567-a58d-4164a20cc1e6",
          "name": "1st Sat Mobile Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "fb031b9c-d3c2-4491-9651-4a760c773885",
          "name": "1st Wed Mobile Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
        {
          "id": "8d917fd9-18f0-4a6d-bb2a-7727208b53b8",
          "name": "1st and 3rd Sat Food Pantry",
          "status": "Open",
          "alternateName": ""
        },
      ]
    }
  }
}
\`\`\`



### Getting an service by ID
Services can be queried by their respective ID's by calling the query below.

\`\`\`javascript=
query {
   _getServiceById(appId: String!, apiKey: String!, serviceId: String!): SingleServiceResponse!
}
\`\`\`

**Example request:**
\`\`\`javascript=
query {
 getServiceById(appId: "1234", apiKey: "EY45-RTJ4-54HJ-12KI", serviceId: "6b8366bc-03d1-4212-b1a1-8409f5b91310") {
 ...on DeveloperError {
     message
 }
 ...on SingleService {
      id
      name
      status
      alternateName
      description
   }
  }
}
\`\`\`

**Sample Result:**
Here is an example of the query response:
\`\`\`javascript=
{
  "data": {
    "_getServiceById": {
      "service": {
        "id": "71f18db7-539c-4780-9f1a-be8f3889b2a4",
        "alternateName": "",
        "description": "Serves as a hotline that provides crisis intervention, information and referral, message relay, conflict mediation, conference calling, runaway education, and prevention materials. Youth and family members can call, text, or chat 24 hours a day to work through problems and find local help from social service agencies and organizations."
      }
    }
  }
}
\`\`\`


`;

export default markdown;
