import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { themeContext } from "../../../shared/store/contexts";
import { OutlineButton } from "../../atoms/Buttons";
import { H3, P } from "../../atoms/Texts";
import ExpandableDescription from "../../molecules/ExpandableDescription";

const OrganizationResultCardStyle = styled.div`
  background: ${(props) => props.bg};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

const OrganizationResultCard = ({ organization, zipcode }) => {
  const [isShowingMoreInfo, setIsShowingMoreInfo] = useState(false);

  const toggleShowMoreInfo = () => setIsShowingMoreInfo(!isShowingMoreInfo);

  const { theme } = useContext(themeContext);

  return (
    <OrganizationResultCardStyle bg={theme.background} className="mb-6 p-5 md:p-10">
      <H3 color={theme.purpleFont} className="mb-2" fontFamily="medium">
        {organization.name}
      </H3>
      <P color={theme.lightFont} className="mb-2">
        {organization.tagline}
      </P>
      {organization.locations?.[0]?.address && (
        <P color={theme.secondaryFont} className="mb-1">
          {`${
            organization.locations?.[0]?.address &&
            organization.locations?.[0]?.address !== "None"
              ? organization.locations?.[0]?.address + ","
              : ""
          }${
            organization.locations?.[0]?.city &&
            organization.locations?.[0]?.city !== "None"
              ? " " + organization.locations?.[0]?.city + ","
              : ""
          } ${
            organization.locations?.[0]?.stateOrProvince &&
            organization.locations?.[0]?.stateOrProvince !== "None"
              ? organization.locations?.[0]?.stateOrProvince + "."
              : ""
          } ${
            organization.locations?.[0]?.zipCode &&
            organization.locations?.[0]?.zipCode !== "None"
              ? organization.locations?.[0]?.zipCode + "."
              : ""
          } ${organization.locations?.[0]?.country}.`}
        </P>
      )}
      <div className="flex gap-2">
        {organization.locations?.[0]?.distance !== null &&
          organization.locations?.[0]?.distance !== undefined && (
            <>
              <div>
                <P fontSize="14px" color={theme.secondaryFont} className="mb-6">
                  {Number(organization.locations?.[0]?.distance) < 1 ? (
                    "Less than a mile from you"
                  ) : (
                    <>
                      {`${organization.locations?.[0]?.distance}`} mile(s) away from
                      you.
                    </>
                  )}
                </P>
              </div>
            </>
          )}
      </div>
      <ExpandableDescription
        text={organization?.description}
        length={250}
        text_color={theme.primaryFont}
      />
      <Link
        to={
          zipcode
            ? `/organization/details/${organization.id}/${zipcode}`
            : `/organization/details/${organization.id}`
        }
      >
        <OutlineButton
          color={theme.purpleFont}
          bg={theme.background}
          onClick={toggleShowMoreInfo}
          className="mt-4"
          smallW="100%"
        >
          Open organization
        </OutlineButton>
      </Link>
    </OrganizationResultCardStyle>
  );
};

export default OrganizationResultCard;
